//import { createForm, createFormField, formShape } from 'rc-form';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { saveCallFields, saveFields, saveCall } from './actions'
import { Divider, Form, Input, Row, Col, Typography, Card, Alert, Button, Skeleton, message, Modal } from 'antd';
import EmployeeSelect from '../../components/EmployeeSelect';
import CallTypeSelect from '../../components/CallTypeSelect';
import CustomerSearch from '../../components/CustomerSearch'
import { Base64, BASE_URL, FROG_AUTH } from '../../constants';

const { TextArea } = Input;
const { Title, Text } = Typography;

const apiurl = BASE_URL + 'frog_calls';

const formItemLayout = {
    /*labelCol: {
        xs: { span: 24 },
        xl: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        xl: { span: 14 },
    },*/
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const renderDuplicates = (props) => {
    let layout = [];
    if (props.error !== '' || props.error !== null) {
        layout.push(
            <Alert
                message="Unable to Save changes"
                description={props.error}
                type="error"
            />
        )
    }
    layout.push(
        <Row key="changeheader" gutter={16}>
            <Col xs={24} sm={12}>
                <Title level={2}>Your Record</Title>
            </Col>
            <Col xs={24} sm={12}>
                <Title level={2}>New Record</Title>
            </Col>
        </Row>
    );
    Object.keys(props.formState).forEach((key, index) => {
        if (props.formState[key] === props.newrecord[key]) {
            layout.push(
                <Row key={index} gutter={16}>
                    <Col xs={24} sm={12}>
                        <Text type="secondary">{props.formState[key]}</Text>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Text type="secondary">{props.newrecord[key]}</Text>
                    </Col>
                </Row>
            );
        } else {
            layout.push(
                <Row key={index} gutter={16}>
                    <Col xs={24} sm={12}>
                        <Text type="danger">{props.formState[key]}</Text>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Text type="danger">{props.newrecord[key]}</Text>
                    </Col>
                </Row>
            );
        }
    });
    return layout;
}

//const FrogCallForm = ({ visible, onSave, onCancel, callnumber, call_record }) => {
const FrogCallForm = (props) => {
    const idRef = useRef(null);
    const [form] = Form.useForm();

    const [visible, setVisible] = useState(props.visible);
    const [callnumber, setCallNumber] = useState(props.callnumber);
    const [onCancel, setCancel] = useState(props.onCancel);
    const [onSave, setSave] = useState(props.onSave);

    const [call_record, setCallRecord] = useState(null);
    useEffect(() => {
        setVisible(props.visible);
        setCancel(props.onCancel);
        setSave(props.onSave)
    }, []);


    useEffect(() => {
        setCallNumber(props.callnumber);
        
        if (props.callnumber !== null && props.callnumber !== '') {
            fetchCall(props.callnumber)
        }
    }, [props.callnumber]);

    const fetchCall = async (callnumber) => {

        const queryparams = '?callno=' + callnumber;
        await fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            // body: formData
        }).then(
            response => response.json(),
            // Do not use catch, because that will also catch
            // any errors in the dispatch and resulting render,
            // causing a loop of 'Unexpected batch number' errors.
            // https://github.com/facebook/react/issues/6895
            //error => dispatch({ type: 'EDIT_CALL_FAIL', payload: error })
        ).then(response => {
            ////console.log(response);
            if (response.ErrorMessage !== undefined && response.ErrorMessage !== '') {
                message.error(response.ErrorMessage);
                form.resetFields();
                idRef.current.focus({
                    cursor: 'all',
                });
            } else {
                console.log('call record result', response);
                setCallRecord(response);
                response.memo = response.memo.join('\n');
                form.setFieldsValue(response);
            }

        });
    }

    const saveCall = (formData) => {
        let newmemo = formData.memo.split("\n"); //.replace( /[\r\n]+/g, "\n")
        formData.memo = newmemo;

        let method = 'put';
        if (formData.id === '') { method = 'post'; }
        return fetch(apiurl, {
            method: method,
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            body: JSON.stringify(formData),
        })
            .then(
                response => response.json(),
                // Do not use catch, because that will also catch
                // any errors in the dispatch and resulting render,
                // causing a loop of 'Unexpected batch number' errors.
                // https://github.com/facebook/react/issues/6895
                // error => dispatch({ type: 'SAVE_CALL_FAIL', payload: error })
            )
            .then(response => {
                ////console.log(response);
                if (response.ErrorMessage !== undefined && response.ErrorMessage !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    //dispatch({ type: 'SAVE_CALL_FAIL', payload: response.ErrorMessage });
                } else if (response.newrecord !== undefined && response.newrecord !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    //dispatch({ type: 'SAVE_CALL_FAIL', payload: response});
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    //dispatch({ type: 'SAVE_CALL_SUCCESS', payload: response });
                    console.log('call record saved', response);
                    setCallRecord(response);
                    response.memo = response.memo.join('\n');
                    form.setFieldsValue(response);
                }

            })
    }

    const callNumberChanged = (cnvals,val) => {
        const newval = form.getFieldValue('id');
        if (newval !== callnumber) {
            setCallNumber(newval)
            fetchCall(newval)
        }
    }

    console.log('CALL NUMBER', callnumber);

    if (props.callnumber !== null && props.callnumber !== '' && call_record === null) {
        return (
            <div>
                <Skeleton active />
            </div>
        )
    }

    return (

        <Form
            form={form}
            layout="vertical"
            name="frog_call_form"
            onFinish={saveCall}
            //onValuesChange={callNumberChanged}
            //fields={call_record}
            //initialValues={call_record}
            labelAlign="left"

        >
            <Row gutter={8}>
                <Col xs={24} xl={12}>
                    <Row gutter={8}>
                        {/*callnumber !== '' && */}
                            <Col xs={12} sm={8}>
                                <Form.Item {...formItemLayout} label="Key" name="id" rules={[{ required: true, message: 'id is required!' }]}>
                                    <Input onBlur={callNumberChanged} ref={idRef} />
                                </Form.Item>
                            </Col>
                        
                        <Col xs={12} sm={8}>
                            <CallTypeSelect label="Type" name='type' rules={[{ required: true, message: 'Type is required!' }]} />
                        </Col>
                        <Col xs={12} sm={8}>
                            <Form.Item {...formItemLayout} label="Priority" name='priority' rules={[{ required: true, message: 'Priority is required!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={12} sm={8}>
                            <Form.Item {...formItemLayout} label="Who Called" name='who_called' rules={[{ required: true, message: 'Who Called is required!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={8}>
                            <EmployeeSelect label="Who Took" name='who_took' rules={[{ required: true, message: 'Who Took is required!' }]} />
                        </Col>
                        <Col xs={12} sm={8}>
                            <EmployeeSelect label="Assigned To" name='assigned_to' rules={[{ required: true, message: 'Assigned To is required!' }]} />
                        </Col>

                        <Col xs={24} sm={12}>
                            <Form.Item {...formItemLayout} label="Customer Number" name='customer_number' rules={[{ required: true, message: 'Who Called is required!' }]}>
                                {/*<Input />*/}
                                <CustomerSearch />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item {...formItemLayout} label="Customer Name" name='customer_name' >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} xl={12}>
                    <Row gutter={8}>
                        <Col xs={12} sm={8}>
                            <Form.Item {...formItemLayout} label="Followup" name='followup'>
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={8}>
                            <Form.Item {...formItemLayout} label="Backup Associate" name='backup_associate' >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={8}>
                            <Form.Item {...formItemLayout} label="Call Back" name='calback_phone'>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col xs={12} sm={6}>
                            <Form.Item {...formItemLayout} label="Date Entered" name='date_entered' >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Item {...formItemLayout} label="Time Entered" name='time_entered' >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Item {...formItemLayout} label="Last Date" name='date_changed' >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Item {...formItemLayout} label="Last Time" name='time_changed' >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col xs={12} >
                            <Form.Item {...formItemLayout} label="Completed" name='completed'>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={12} >
                            <Form.Item {...formItemLayout} label="Status" name='status' >
                                <Input />
                            </Form.Item>
                        </Col>

                    </Row>
                </Col>
            </Row>
            <Divider>FROG Only</Divider>
            <Row gutter={16}>

                <Col xs={12} >
                    <Form.Item {...formItemLayout} label="FROG Contact" name='contact' rules={[{ required: false }]}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={12} >
                    <Form.Item {...formItemLayout} label="Email" name='email' rules={[{ required: false, message: 'Email is required!' }]}>
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={12} sm={8}>
                    <Form.Item {...formItemLayout} label="Service" name='service' rules={[{ required: false, message: 'Who Took is required!' }]}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={8}>
                    <Form.Item {...formItemLayout} label="Through" name='through' rules={[{ required: false, message: 'Who Took is required!' }]}>
                        <Input disabled />
                    </Form.Item>
                </Col>

                <Col xs={12} md={8}>
                    <Form.Item {...formItemLayout} label="Reportable" name='reportable'>
                        <Input />
                    </Form.Item>
                </Col>

            </Row>

            <Divider>Memo</Divider>

            <Row gutter={16}>

                <Col xs={24} >
                    <Form.Item name='memo'>
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>

            </Row>
            <Form.Item >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>

        </Form>
    );


};

export default FrogCallForm;
/*


const mapStateToProps = store => ({
    callnumber: store.frogCall.callnumber,
    call_record: store.frogCall.call_record,
    fields: store.frogCall.fields,
});

const mapDispatchToProps = dispatch => {
    return {
        saveCallFields: (data) => {
            dispatch(saveCallFields(data));
        },
        saveFields: (data) => {
            dispatch(saveFields(data));
        },
        saveCall: (data) => {
            dispatch(saveCall(data));
        },
    }
};

export default connect(mapStateToProps)(FrogCallForm);
//export default FrogCallForm;
*/