import React, { useEffect, useState, useMemo } from 'react'
import { BASE_URL, FROG_AUTH,  Base64 } from '../constants'
import useFetch from 'react-fetch-hook'

const employeeAPI = BASE_URL + 'employee_file?key=ALL';

export const useEmployeeList = (props) => {
    const { isLoading, data, errors } = useFetch(employeeAPI);
    console.log('useemloyeelist',data);
    const elist = data;
    return [isLoading,data,errors];
}
/*    
    const [results, setResults] = useState([]);

    useEffect(() => {
        console.log('start employee fetch');
        fetch(employeeAPI, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                console.log('employees', response);
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                setResults(response);
    
            })
            .catch(error => {
                //throw(error);
                console.log('getcalls catch', error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
            });
    });
    

    return results;
}
*/