import React from 'react'
import { connect } from 'react-redux';
import { getSalesRecord } from './actions'

import { Input, Result, Spin, Card, Col, Row, List, Table, Avatar } from 'antd';

const { Search } = Input;

const imgbase = 'https://www.furnguy.com/images/';
const columns = [
    {
        title: '',
        width: 100,
        dataIndex: 'image',
        key: 'image',
        render: (text,record,index) => <Avatar shape="square" size={75} src={record.image} />,
    },
    {
        title: 'Qty',
        width: 50,
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Sku Number',
        dataIndex: 'sku',
        key: 'sku',
        width: 150,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 300,
    },
    {
        title: 'Loc',
        dataIndex: 'location',
        key: 'location',
        width: 100,
    },
    {
        title: 'Retail',
        dataIndex: 'retail',
        key: 'retail',
        width: 75,
    },
    {
        title: 'Dept',
        dataIndex: 'dept',
        key: 'dept',
        width: 100,
    },
    {
        title: 'Action',
        key: 'operation',
        width: 75,
        fixed: 'right',
        render: () => <a>action</a>,
    },
];



class SalesUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: null,
        }
        //this.handleChange = this.handleChange(this);
    }

    handleChange = (value) => {
        //console.log(`selected ${value}`);
    }

    render() {
        const tabledata = [];
        const saorec = this.props.saorec;
        //console.log('sales entry',saorec);
        for (let i = 0; i < saorec.sku_number.length; i++) {
            tabledata.push({
                key: i,
                qty: saorec.qty[i],
                sku: saorec.sku_number[i],
                description: saorec.mfg_stock_number[i],
                image: imgbase + saorec.image[i],
                dept: saorec.dept[i],
                location: saorec.location[i],
                retail: saorec.multiretail[i]
            });
        }
        if (this.props.adding) {
            return (
                <div>
                    <Spin />
                </div>
            )
        } else if (this.props.error !== null) {
            return (
                <div>
                    <Result
                        status="warning"
                        title={this.props.error + '. Please try again.'}

                    />
                </div>
            );
        } else {
            return (
                <div>
                    <Search placeholder="Invoice#" onSearch={value => this.props.getSalesRecord(value)} enterButton />
                    <Table columns={columns} dataSource={tabledata} scroll={{ x: 1500, y: 300 }} />

                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    saorec: state.salesUpdate.saorec,
    error: state.salesUpdate.error,
    adding: state.salesUpdate.adding,
});

const mapDispatchToProps = dispatch => {
    return {
        getSalesRecord: (invoiceNumber) => {
            dispatch(getSalesRecord(invoiceNumber));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesUpdate);