
import { message } from 'antd';
import { apikey, BASE_URL, FROG_AUTH } from './../../constants';
const apiurl = BASE_URL + 'recap_performance';

// Action creators store.dispatch actions
export const addCart = (item, qty) => {
    if (item.hasOwnProperty('inventoryrec')) {
        return (dispatch, getState) => {
            let saorec = getState().salesEntry.saorec;
            saorec.sku_number.push(item.sku);
            saorec.qty.push(qty);
            saorec.mfg_stock_number.push(item.inventoryrec.description.join(' '));
            saorec.image.push(item.inventoryrec.thumbnails[0]);
            saorec.location.push('OVR');
            saorec.dept.push(item.inventoryrec.department);
            saorec.retail.push(item.inventoryrec.retail);
            //console.log(saorec);

            dispatch({ type: 'ADD_CART', payload: saorec });

            message.success(item.sku + ' added to cart.');
        }
    } else {
        return (dispatch, getState) => {
            let saorec = getState().salesEntry.saorec;
            saorec.sku_number.push(item.sku);
            saorec.qty.push(qty);
            saorec.mfg_stock_number.push(item.description);
            saorec.image.push(item.thumbnail);
            saorec.location.push('OVR');
            saorec.dept.push(item.department);
            saorec.retail.push(item.retail);
            //console.log(saorec);

            dispatch({ type: 'ADD_CART', payload: saorec });

            message.success(item.sku + ' added to cart.');
        }
    }

}

export const addCartServer = (searchkey) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS' });
        var formData = new FormData();
        formData.append('searchkey', searchkey);
        formData.append('apikey', apikey);
        fetch(apiurl + 'inet_website_product_lookup', {
            method: 'POST',
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
    };
};