import React, { useEffect, useState } from 'react'
import { BASE_URL, FROG_AUTH, Base64 } from './../../constants'
import useSWR from 'swr';
import { connect } from 'react-redux'
import { ResponsivePie, ResponsivePieCanvas } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import {
    Result,
    Tabs,
    Row,
    Col,
    Typography,
    Card,
    Skeleton,
    Divider,
    Radio,
    Form
} from 'antd';

const { TabPane } = Tabs;
const { Title } = Typography;

const viewOptions = [
    { label: 'Today', value: 'today' },
    { label: 'This Week', value: 'week' },
    { label: 'This Month', value: 'month' },
    { label: 'Last 30 Days', value: '30days' },
];

const formItemLayout = {
    /*labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
        md: { span: 8 },
    },
    */
    wrapperCol: {
        xs: { span: 12 },
    },
};

const TodayAllStores = props => {


    return (

        <Row gutter={[{ xs: 2, md: 4, xl: 8 }, { xs: 2, md: 4, xl: 8 }]} wrap={true}>
            <Col xs={24} xxl={12} >
                <Card title="Retail by Store" className='fullblock retail-by-store' bodyStyle={{ padding: 5 }}>
                    <div style={{ height: 550 }}>
                        <ResponsiveBar
                            data={props.storeData}
                            keys={["retail"]}
                            indexBy="store"
                            layout="horizontal"
                            margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            //indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'paired' }}
                            colorBy="index"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{
                                tickSize: 0,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Stores",
                                legendPosition: "middle",
                                legendOffset: -40
                            }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Dollars",
                                legendPosition: "middle",
                                legendOffset: 40
                            }}
                        />
                    </div>
                </Card>
            </Col>
            <Col xs={24} xxl={12} >
                <Card title="GM by Store" className='fullblock gm-by-store' bodyStyle={{ padding: 5 }}>
                    <div style={{ height: 550 }}>
                        <ResponsiveBar
                            data={props.storeData}
                            keys={["margin"]}
                            indexBy="store"
                            layout="horizontal"
                            margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            //indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'paired' }}
                            colorBy="index"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{
                                tickSize: 0,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Stores",
                                legendPosition: "middle",
                                legendOffset: -40
                            }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Margin",
                                legendPosition: "middle",
                                legendOffset: 40
                            }}
                        />
                    </div>
                </Card>
            </Col>
            <Col xs={24} xxl={12} >
                <Card title="Deposit by Store" className='fullblock deposit-by-store' bodyStyle={{ padding: 5 }}>
                    <div style={{ height: 550 }}>
                        <ResponsiveBar
                            data={props.storeData}
                            keys={["deposit", "balance"]}
                            indexBy="store"
                            groupMode="stacked"
                            layout="vertical"
                            margin={{ top: 10, right: 50, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            //indexScale={{ type: 'band', round: true }}
                            colors={["#006400", "#8b0000"]}
                            //colorBy="index"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{
                                tickSize: 0,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Dollars",
                                legendPosition: "middle",
                                legendOffset: -50
                            }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Stores",
                                legendPosition: "middle",
                                legendOffset: 40
                            }}
                        />
                    </div>
                </Card>
            </Col>
            <Col xs={24} xxl={12} >
                <Card title="Profit by Store (Retail)" className='fullblock profit-by-store' bodyStyle={{ padding: 5 }}>
                    <div style={{ height: 550 }}>
                        <ResponsiveBar
                            data={props.storeData}
                            keys={["retailprofit"]}
                            indexBy="store"
                            layout="horizontal"
                            margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            //indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'paired' }}
                            colorBy="index"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{
                                tickSize: 0,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Stores",
                                legendPosition: "middle",
                                legendOffset: -40
                            }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Profit",
                                legendPosition: "middle",
                                legendOffset: 40
                            }}
                        />
                    </div>
                </Card>
            </Col>
        </Row>
    );
}

export default TodayAllStores;

