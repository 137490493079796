import React, {useState, useEffect, useMemo, useCallback } from 'react'
import { BASE_URL, FROG_AUTH,  Base64 } from '../constants'
import { connect } from 'react-redux'
import { useEmployeeList } from '../hooks/useEmployeeList'
import useSWR from 'swr';
import {
    Form,
    Select,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { Option } = Select;

const employeeAPI = BASE_URL + 'employee_file?key=ALL';

const fetchEmployees = async () => {
    console.log('start employee fetch');
    return await fetch(employeeAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            console.log('employees',response);
            return response.json();
        }).then(response => {
            ////console.log(response.errors);
            return response;

        })
        .catch(error => {
            //throw(error);
            console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}

const EmployeeSelect = props => {
    const [profileState, setProfileState] = useState(props);
    //const [data, setData] = useState([]);
    const { data: employees } = useSWR(employeeAPI, fetchEmployees);
    //const { data: employees } = useMemo(() => useSWR(employeeAPI, fetchEmployees),[]);
    /*
    useEffect(() => {
        
        
        setData(props.employeeList);
        //if (data.length === 0) {fetchEmployees();}
        console.log(props);
    }, [data])
    */
    console.log('employeeselect',employees);

    return (
        <Form.Item label={profileState.label} name={profileState.name} rules={profileState.rules}>
            <Select
                //mode={this.state.mode}
                style={{ width: '100%' }}
                placeholder="Please select"
                ///defaultValue={[]}
                //onChange={this.handleChange}
            >
                {
                    employees && (
                        employees.map(employee => <Option key={employee.code} value={employee.code}>{employee.name}</Option>)
                    )
                /*props.employeeList && (
                    props.employeeList.map(item => <Option key={item.code} value={item.code}>{item.name}</Option>)
                )*/}
            </Select>
        </Form.Item>
    )
}

const mapStateToProps = store => ({
    employeeList: store.setupState.employeeList,
});
/*
const mapDispatchToProps = dispatch => {
    return {
        reportTar: (data) => {
            dispatch(reportTar(data));
        },
    }
};
*/
export default connect(mapStateToProps)(EmployeeSelect);
//export default EmployeeSelect;

/*
class DefineFileSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '',
            includeAll: false,
            prefix: '',
            suffix: '',
            contains: '',
            options: [],
        }
    }
    
    componentDidMount() {
        //console.log('select props',this.props);
        if (this.props.hasOwnProperty('multiple')) {
            this.setState({ mode: 'multiple' });
        }
        
        let prefix = '';
        let suffix = '';
        let contains = '';
        if (this.props.hasOwnProperty('prefix')) {
            prefix = this.props.prefix;
        }
        if (this.props.hasOwnProperty('suffix')) {
            suffix = this.props.suffix;
        }
        if (this.props.hasOwnProperty('prefix')) {
            contains = this.props.contains;
        }
        this.setState({ 
            prefix: prefix,
            suffix: suffix,
            contains: contains
        });
        const queryparams = '?prefix=' +prefix+ '&suffix=' +suffix+ '&contains=' +contains;
        fetch(BASE_URL + 'define_file' + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    //dispatch({ type: 'FETCH_CALLS_FAIL', payload: response.errors });
                } else {
                    if (this.props.hasOwnProperty('includeall')) {
                        response.push('ALL');
                    }
                    this.setState({ options: response });
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('getcalls catch', error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
            });
    }


    handleChange = (value) => {
        //console.log(`selected ${value}`);
        this.setState({selectedLocations: value});
    }
    render() {
        const children = [];
        const options = this.state.options;
        for (let i = 0; i < options.length; i++) {
            children.push(<Option key={options[i]} value={options[i]}>{options[i]}</Option>);
        }
        return (
            <Form.Item label={this.props.label} name={this.props.name} rules={this.props.rules}>
            <Select
                mode={this.state.mode}
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={[]}
                onChange={this.handleChange}
            >
                {children}
            </Select>
            </Form.Item>
        );
    }
}
export default DefineFileSelect;

*/