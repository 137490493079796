import React, { Component, useState, useEffect } from 'react'
import { contentDiv, BASE_URL, FROG_AUTH,  Base64 } from '../../../constants'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import '@ant-design/compatible/assets/index.css';
import {
    Popconfirm,
    Skeleton,
} from 'antd';


const ScanAPI = BASE_URL + 'rf_move';

const postScan = async (scan) => {
    return await fetch(ScanAPI + '?scan=' + scan, {
        method: 'put',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}

const RfMenu = (props) => {
    const [data, setData] = useState('No Result')
    const [scanList, setScanList] = useState([])

    useEffect(() => {

    }, []);

    const handleScan = (data) => {
        console.log(data);
        const scan = data.text;
        if (!scanList.includes(scan)) {
            scanList.push(scan);
            //postScan(scan);
        }
        setData(data.text)
    };
    const handleError = (err) => {
        console.error(err)
    };

    return (
        <div style={contentDiv}>
            <BarcodeScannerComponent
                width={300}
                height={100}
                onUpdate={(err, result) => {
                    if (result) handleScan(result)
                    else handleError(err)
                }}
            />
            <p>{data}</p>
        </div>
    );

}

export default RfMenu