

const numberWithCommas = (num) => {
    if (typeof num === "string") {
        num = parseFloat(num).toFixed(2);
    } else {
        num = num.toFixed(2);
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default numberWithCommas;