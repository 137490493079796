import Socket from "./Socket";

import { statusChanged, STATUS_CHANGED } from "../actions";
//import * as UI from "../../../constants/UI";
import {
    connectionChanged,
    CONNECT_SOCKET,
    DISCONNECT_SOCKET
} from "../actions";
import {
    clientUpdateReceived,
    messageReceived,
    recipientChanged,
    messageSent,
    SEND_MESSAGE
} from "../../message/actions";
import {
    employeeListChanged, FROG_LOGOUT
} from '../../setup/actions';
import {
    FROG_LOGIN_SUCCESS
} from '../../setup/actions';
import {
    newCall
} from '../../../sales/frog_calls/actions';

const socketMiddleware = store => {

    // The socket's connection state changed
    const onConnectionChange = isConnected => {
        store.dispatch(connectionChanged(isConnected));
        store.dispatch(statusChanged(isConnected ? 'Connected' : 'Disconnected'));
    };

    // There has been a socket error
    const onSocketError = (status) => store.dispatch(statusChanged(status, true));

    // The client has received a message
    const onSetupEmployeeList = elist => store.dispatch(employeeListChanged(elist));

    // The client has received a message
    const onIncomingMessage = message => {
        /*const messageState = store.getState().messageState;
        const fromObj = messageState.users.filter(userobj => userobj.user === message.from);
        if (fromObj.length > 0) {
            message.from = fromObj[0].id;
        }
        console.log('onIncomingMessage',fromObj);
        */
        store.dispatch(messageReceived(message));
    };

    // New user connected
    const onNewUser = userList => {
        //console.log('new user',userList);
    //};

    // The server has updated us with a list of all users currently on the system
    //const onUpdateClient = message => {

        const messageState = store.getState().messageState;
        const account = JSON.parse(localStorage.getItem('account'));
        //console.log('message state',account);
        // Remove this user from the list
        const otherUsers = userList.filter(userobj => userobj.user !== account.username);
        //console.log('other users',otherUsers);

        // Has our recipient disconnected?
        const recipientLost = false; //messageState.recipient !== UI.NO_RECIPIENT && !(message.list.find(user => user === messageState.recipient));

        // Has our previously disconnected recipient reconnected?
        const recipientFound = !!messageState.lostRecipient && !!userList.find(user => user === messageState.lostRecipient);

        const dispatchUpdate = () => {
            store.dispatch(clientUpdateReceived(otherUsers, recipientLost));
        };

        if (recipientLost && !messageState.recipientLost) { // recipient just now disconnected
            //store.dispatch(statusChanged(`${messageState.recipient} ${UI.RECIPIENT_LOST}`, true));
            dispatchUpdate();
        } else if (recipientFound) { // previously lost recipient just reconnected
            //store.dispatch(statusChanged(`${messageState.lostRecipient} ${UI.RECIPIENT_FOUND}`));
            dispatchUpdate();
            store.dispatch(recipientChanged(messageState.lostRecipient));
        } else {
            dispatchUpdate();
        }
        
    };

    const onNewCall = callObj => {
        store.dispatch(newCall(callObj));

    };

    const onUpdateClient = message => {

    };

    const socket = new Socket(
        onConnectionChange,
        onSocketError,
        onSetupEmployeeList,
        onIncomingMessage,
        onUpdateClient,
        onNewUser,
        onNewCall,
        //onIdentify,
    );

    // Return the handler that will be called for each action dispatched
    return next => action => {

        const messageState = store.getState().messageState;
        const socketState = store.getState().socketState;
        const setupState = store.getState().setupState;
        //console.log('socket actions '+action.type, messageState);
        switch (action.type) {

            case CONNECT_SOCKET:
                socket.connect(messageState.user, socketState.port);
                break;

            case FROG_LOGIN_SUCCESS:
                socket.connect(setupState.userame, socketState.port);
                break;

            case FROG_LOGOUT:
            case DISCONNECT_SOCKET:
                socket.disconnect();
                break;

            case SEND_MESSAGE:
               // console.log('SEND_MESSAGE',action);
                
                socket.sendIm({
                    'from': setupState.username,
                    'to': messageState.recipient,
                    'text': action.message,
                    'forwarded': false
                });
                
                store.dispatch(messageSent());
                break;
            case STATUS_CHANGED:
                //console.log('STATUS_CHANAGED', setupState);
                socket.sendIdent(setupState.username);
                break;
            case 'LOAD_EMPLOYEES':
                socket.loadEmployees('');
                break;

            default:
                break;
        }

        return next(action)
    };
};

export default socketMiddleware;