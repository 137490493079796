import React, { useContext, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Table, Input, InputNumber, Button, Popconfirm, Form, Avatar } from 'antd';
import { searchProduct } from './../../find/manufacturer_lookup/actions'

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    inputType,
    record,
    handleSave,
    props,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    /*
        useEffect(() => {
            setSearchResults(props.results);
        }, [props]);
    */
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async e => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            console.log(record, Object.keys(values));
            let key = Object.keys(values)[0];
            let value = Object.values(values)[0];
            if (key === 'sku') {
                let search = {
                    "searchkey": value,
                    "inStockOnly": false,
                    "includeGroups": true,
                    "statusCodes": ["ALL"]
                };
                props.dispatch(searchProduct(search))
                    .then(() => { console.log('SEARCH', props.results) });
            } else {
                handleSave({ ...record, ...values });

            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    const inputNode = inputType === 'number' ? <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} /> : <Input ref={inputRef} onPressEnter={save} onBlur={save} />;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {inputNode}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
    }
    console.log(props);
    return <td {...restProps}>{childNode}</td>;
};

const imgbase = 'https://www.furnguy.com/images/';

const SkuTable = (props) => {
    const [dataSource, setDataSource] = useState([])
    const [count, setCount] = useState(0)

    const tablecolumns = [
        {
            title: '',
            width: 100,
            dataIndex: 'image',
            key: 'image',
            render: (text, record, index) => <Avatar shape="square" size={75} src={record.image} />,
        },
        {
            title: 'Qty',
            width: 75,
            dataIndex: 'qty',
            key: 'qty',
            editable: 'true',
        },
        {
            title: 'Sku Number',
            dataIndex: 'sku',
            key: 'sku',
            width: 200,
            editable: 'true',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 350,
            editable: 'true',
        },
        {
            title: 'Loc',
            dataIndex: 'location',
            key: 'location',
            width: 75,
        },
        {
            title: 'Retail',
            dataIndex: 'retail',
            key: 'retail',
            width: 125,
            editable: true,
        },
        {
            title: 'Dept',
            dataIndex: 'dept',
            key: 'dept',
            width: 75,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (text, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null,
        },
    ];


    useEffect(() => {
        console.log('useEffect', props.saorec);
        if (props.saorec !== undefined) {
            const tabledata = [];
            const saorec = props.saorec;
            //console.log('sales entry', saorec);
            for (let i = 0; i < saorec.sku_number.length; i++) {
                tabledata.push({
                    key: i,
                    qty: saorec.qty[i],
                    sku: saorec.sku_number[i],
                    description: saorec.mfg_stock_number[i],
                    image: imgbase + saorec.image[i],
                    dept: saorec.dept[i],
                    location: saorec.location[i],
                    retail: saorec.retail[i]
                });
            }

            setDataSource(tabledata);

        }
    }, [props.saorec]);

    const handleDelete = key => {
        const thisdataSource = [...dataSource];
        setDataSource(thisdataSource.filter(item => item.key !== key))
    };

    const handleAdd = () => {
        const newData = {
            key: count,
            name: `Edward King ${count}`,
            age: 32,
            address: `London, Park Lane no. ${count}`,
        };
        setDataSource([...dataSource, newData])
        setCount(count + 1)
    };

    const handleSave = row => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData)
    };



    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = tablecolumns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'qty' ? 'number' : 'text',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                props: props,
                handleSave: handleSave,
            }),
        };
    });
    console.log('skutable', props.results);
    return (
        <div>
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
            />
            <Button
                onClick={handleAdd}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                Add a row
                </Button>
        </div>
    );

}

//ReactDOM.render(<SkuTable />, mountNode);

//export default SkuTable;

const mapStateToProps = state => ({
    saorec: state.salesEntry.saorec,
    results: state.mfgl.results,
    error: state.mfgl.error,
    fetching: state.mfgl.fetching,
});

const mapDispatchToProps = dispatch => {
    return {
        searchProduct: (searchkey) => {
            dispatch(searchProduct(searchkey));
        }
    }
};

export default connect(mapStateToProps)(SkuTable);

