
import { Base64, BASE_URL, FROG_AUTH } from './../../constants';

import {
    message
} from 'antd';

const apiurl = BASE_URL + 'employee_file';

export const SAVE_FAVORITES = 'employee/save-favorites';
export const SAVE_FAVORITES_SUCCESS = 'employee/save-favorites-success';
export const SAVE_FAVORITES_FAIL = 'employee/save-favorites-fail';
export const SAVE_MFGL_COLUMNS = 'employee/save-mfgl-columns';
export const SAVE_MFGL_COLUMNS_SUCCESS = 'employee/save-mfgl-columns-success';
export const SAVE_MFGL_COLUMNS_FAIL = 'employee/save-mfgl-columns-fail';

// Action creators store.dispatch actions
export const saveFavorites = (favorites) => {
    return (dispatch, getState) => {
        dispatch({ type: SAVE_FAVORITES, payload: '' });
        
        const key = 'JASON'; //getState().setupState.employee;
        const type = 'favorites';

        let queryparams = '?type=' +type+ '&key=' +key;
        console.log(queryparams);
        return fetch(apiurl + queryparams, {
            method: 'put',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            body: JSON.stringify(favorites)
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: SAVE_FAVORITES_FAIL, payload: response.errors });
                    message.error('Could not add to favorites.');
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    //let results = {};
                   // results.results = response;
                    //results.last_updated = new Date(); //dayjs().format('MM/DD/YY, h:mm:ss a');
                    dispatch({ type: SAVE_FAVORITES_SUCCESS, payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('recapbydept catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: SAVE_FAVORITES_FAIL, payload: error });
                message.error('Could not add to favorites.');
            });
    };
};

export const saveMfglColumns = (columns) => {
    const account = JSON.parse(localStorage.getItem('account'))
    return (dispatch, getState) => {
        dispatch({ type: SAVE_MFGL_COLUMNS, payload: '' });
        
        const key = account.username; //getState().setupState.employee;
        const type = 'mfglcolumns';

        let queryparams = '?type=' +type+ '&key=' +key;
        console.log(queryparams);
        return fetch(apiurl + queryparams, {
            method: 'put',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            body: JSON.stringify(columns)
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.ErrorMessage !== undefined && response.ErrorMessage !== '') {
                    dispatch({ type: SAVE_MFGL_COLUMNS_FAIL, payload: response.ErrorMessage });
                    message.error('Selected columns NOT saved.');
                } else {
                    dispatch({ type: SAVE_MFGL_COLUMNS_SUCCESS, payload: response });
                }

            })
            .catch(error => {
                dispatch({ type: SAVE_MFGL_COLUMNS_FAIL, payload: error });
                message.error('Selected columns NOT saved.');
            });
    };
};

export const recapByDept = (variables) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_DEPT' });
        //var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        //let headers = new Headers();
        //headers.append({"Authorization": 'Basic '+Base64.btoa(FROG_AUTH)});
        let queryparams = '?report=dept&startmonth=01&endmonth=02&startyear=20&endyear=20';
        fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
           // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_DEPT_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_DEPT_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('recapbydept catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_DEPT_FAIL', payload: error });
            });
    };
};