import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DashboardLayout from './DashboardLayout'
import UnauthorizedLayout from './layout/UnauthorizedLayout'
import { Spin, Layout, Row, Col } from 'antd'
import { verifyLogin, updateUser } from './websocket/setup/actions'

const App = (props) => {

    const [verifyingLogin,setVerifyingLogin] = useState(true)
    useEffect(() => {
        // confirm setup
        let setuprec = localStorage.getItem('setup');
        // confirm account login
        console.log('appuse',localStorage.getItem('account') );
        if (props.isLoggedIn === null && localStorage.getItem('account') !== null) {
            props.dispatch(verifyLogin()).then((response) => {
                setVerifyingLogin(false)
                /*
                const loginInfo = response.value.data;
                if (loginInfo.errors === '') {
                    props.dispatch(updateUser(loginInfo.employee_code, loginInfo.token));
                }
                */
            });
        } else {
            setVerifyingLogin(false)
        }
    }, []);
    //console.log(props.isLoggedIn);
    if (verifyingLogin) {
        return (
            <Layout style={{ height: '900px' }}>
                <Row justify="space-around" align="middle">
                    <Col style={{ height: '600px' }} />
                    <Col >
                        <Spin
                            size="large"
                            tip="Verifying login, please wait..."
                            />
                    </Col>
                    <Col style={{ height: '600px' }} />
                </Row>
            </Layout>
        );
    }

    return (
        <div>
        {props.isLoggedIn !== null ?
            <DashboardLayout />
        :
            <UnauthorizedLayout />
        }
        </div>
    );
    
}

const mapStateToProps = store => ({
    isLoggedIn: store.setupState.username,
});

export default connect(mapStateToProps)(App);