import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './../Home'
import Login from './../Login'
import SalesEntry from './../sales/sales_entry'
import SalesUpdate from './../sales/sales_update'
import RPBB from './../sales/rpbb'
import RPBD from './../sales/rpbd'
import RPBI from './../sales/rpbi'
import MFGL from './../find/manufacturer_lookup'
import Reservations from '../find/manufacturer_lookup/Reservations'
import CustomerEntry from './../customer'
import CallLookup from './../sales/frog_calls/CallLookup'
import FrogCall from './../sales/frog_calls/FrogCall'
import Tar from './../sales/report_tar'
import { ReadyForPickupSetup } from '../setup/ready_for_pickup'
import CustomerLookup from '../find/customer_lookup';
import ReturnToVendor from '../service/return_to_vendor'
import MarketEmailSetup from '../setup/market_email'
import RfMenu from '../inventory/serial_barcode/rf_menu'
import FROGCalendar from '../utilities/calendar'

const Router = () => (
    <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/login' component={Login} />
        <Route path='/sales/sales_entry' component={SalesEntry} />
        <Route path='/sales/sales_update' component={SalesUpdate} />
        <Route path='/sales/reports/recap_performance/recap_performance_by_brand' component={RPBB} />
        <Route path='/sales/reports/recap_performance/recap_performance_by_dept' component={RPBD} />
        <Route path='/sales/reports/recap_performance/recap_performance_by_item' component={RPBI} />
        <Route path='/sales/reports/transaction_report' component={Tar} />
        <Route path='/service/return_to_vendor' component={ReturnToVendor} />
        <Route path='/customer' component={CustomerEntry} />
        <Route path='/sales/follow/frog_calls_lookup' component={CallLookup} />
        <Route path='/sales/follow/frog_calls' component={FrogCall} />
        <Route path='/inventory/serial_barcode/rf_menu' component={RfMenu} />
        <Route path='/find/customer_lookup' component={CustomerLookup} />
        <Route path='/find/manufacturer_lookup' component={MFGL} />
        <Route path='/find/reservations' component={Reservations} />
        <Route path='/utilities/calendar' component={FROGCalendar} />
        <Route path='/setup/ready_for_pickup' component={ReadyForPickupSetup} />
        <Route path='/setup/market_email' component={MarketEmailSetup} />
        
    </Switch>
)
export default Router