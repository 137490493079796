import { combineReducers } from 'redux';
//import socketReducer from './socketReducer';
import socketReducer from '../websocket/socket/reducer';
import setupReducer from '../websocket/setup/reducer';
import messageReducer from '../websocket/message/reducer';
import salesEntryReducer from './../sales/sales_entry/reducer';
import salesUpdateReducer from './../sales/sales_update/reducer';
import mfglReducer from './../find/manufacturer_lookup/reducer';
import recapPerformanceByBrand from './../sales/rpbb/reducer';
import recapPerformanceByDept from './../sales/rpbd/reducer';
import recapPerformanceByItem from './../sales/rpbi/reducer';
import customer from './../customer/reducer';
import frogCall from './../sales/frog_calls/reducer';
import tar from './../sales/report_tar/reducer';
import customerLookup from './../find/customer_lookup/reducer';
import frogCalendar from '../utilities/calendar/reducer';

export default combineReducers({
    salesEntry: salesEntryReducer,
    salesUpdate: salesUpdateReducer,
    mfgl: mfglReducer,
    recapPerformanceByBrand: recapPerformanceByBrand,
    recapPerformanceByDept: recapPerformanceByDept,
    recapPerformanceByItem: recapPerformanceByItem,
    customer: customer,
    frogCall: frogCall,
    tar: tar,
    customerLookup: customerLookup,
    socketState: socketReducer,
    messageState: messageReducer,
    setupState: setupReducer,
    frogCalendar: frogCalendar,
});