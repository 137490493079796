//import { createForm, createFormField, formShape } from 'rc-form';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { saveCallFields, saveFields, saveCall } from './actions'
import { Form, Input, Row, Col, Typography, Card, Alert, Button, Modal } from 'antd';
import FrogCallForm from './FrogCallForm';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const FrogCallModal = (props) => {
    const [visible,setVisible] = useState(props.visible);
    const [callnumber,setCallNumber] = useState(props.callnumber);
    //const [onModalCancel,setModalCancel] = useState(props.onModalCancel);
    //const [onSave,setSave] = useState(props.onSave);
    //const [call_record, setCallRecord] = useState(props.call_record);
    

    useEffect(() => {
        console.log('useEffect',props);
        setVisible(props.visible);
        setCallNumber(props.callnumber);
        //setCancel(props.onCancel);
        //setSave(props.onSave);
        //setCallRecord(props.call_record)
        
    }, [props]);

    const onCancel = () => {
        setVisible(false)
        props.onModalCancel(false)
    };
    
    //console.log(props)
    console.log('CALL NUMBER',callnumber);
    //console.log('MODAL record',call_record);
    return (
        <Modal
            visible={visible}
            title="Frog Call"
            cancelText="Cancel"
            onCancel={onCancel}
            width="80%"
            /*okText="Save"
            
            width="80%"
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onSave(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}*/
        >
            <FrogCallForm callnumber={callnumber} />
            
        </Modal>
    );


};

export default FrogCallModal;
/*


const mapStateToProps = store => ({
    callnumber: store.frogCall.callnumber,
    call_record: store.frogCall.call_record,
    fields: store.frogCall.fields,
});

const mapDispatchToProps = dispatch => {
    return {
        saveCallFields: (data) => {
            dispatch(saveCallFields(data));
        },
        saveFields: (data) => {
            dispatch(saveFields(data));
        },
        saveCall: (data) => {
            dispatch(saveCall(data));
        },
    }
};

export default connect(mapStateToProps)(FrogCallForm);
//export default FrogCallForm;
*/