import React from 'react'
import { connect } from 'react-redux'
//import io from 'socket.io-client';
//import { SOCKET_URL } from './../../constants';
import '@ant-design/compatible/assets/index.css';
import {
    Input,
    Tooltip,
    Select,
    Row,
    Col,
    Checkbox,
    Form,
    Button,
    AutoComplete,
    DatePicker,
    Steps,
    Radio,
    Descriptions,
    Table,
    Typography,
    Modal,
} from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';

import { buttonPing } from '../actions/socketActions';
// ACTIONS
import { abandonChat, sendMessage, userChanged, recipientChanged } from '../websocket/message/actions';
import { connectSocket, disconnectSocket } from '../websocket/socket/actions';//'../../store/socket/actions';
import { loadEmployees } from '../websocket/setup/actions';
import MessageHistory from './MessageHistory';
import InstantMessage from '../components/InstantMessage'

const { TextArea } = Input;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Step } = Steps;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

//const SOCKET_URL = 'http://localhost:1234';
//const socket = io(SOCKET_URL);

class Messenger extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        mode: ['month', 'month'],
        monthvalue: [],
        typeflag: 'written',
        stores: [],
        editMode: true,
    };

    componentDidMount() {

    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };



    handlePanelChange = (value, mode) => {
        this.setState({
            monthvalue: value,
            mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
        });
    };

    handleChange = value => {
        this.setState({ montvalue: value });
    };

    handleButtonClick = () => {
        this.props.connectSocket();
        //console.log('ping click',this.props.socket);
        //this.props.socket.emit('addmessage','test');
        //this.props.dispatch(buttonPing('test'));
    };

    handleEmployeeClick = () => {
        this.props.loadEmployees();
    };

    handleUserChange = event => this.props.userChanged(event.target.value);
    handleRecipientChange = value => this.props.recipientChanged(value);

    onChange = e => {
        //console.log(`radio checked:${e.target.value}`);
    };
    onFinish = value => {
        this.props.sendMessage(value.memo);

    };



    render() {
        console.log('messenger',this.props.users);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 10 },
                md: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
                md: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        return (
            <>
                <MessageHistory />
                <Input name="userInput" placeholder="Enter your name" onChange={this.handleUserChange} disabled={this.props.connected} />
                <Select
                    name="selectRecipient"
                    onChange={this.handleRecipientChange}
                    style={{minWidth: 100}}
                >
                    <Option disabled value="" key="-1">None</Option>
                    {this.props.users.map((user, index) =>
                        <Option key={index} value={user.id}>{user.user}</Option>)}
                </Select>
                     {/*   <InstantMessage to={user.id} />*/}
                
                <Form {...formItemLayout} onFinish={this.onFinish} >
                    <Form.Item name='memo'>
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            Send
                        </Button>
                    </Form.Item>
                </Form >
                
                <Button
                    type="primary"
                    onClick={() => this.handleButtonClick()}
                >
                    Connect web socket
                </Button>
                <Button
                    type="primary"
                    onClick={() => this.handleEmployeeClick()}
                >
                    Load Employee test
                </Button>
            </>
        );
    }
}

// Map required state into props
const mapStateToProps = (state) => ({
    connected: state.socketState.connected,
    port: state.socketState.port,
    user: state.messageState.user,
    recipient: state.messageState.recipient,
    users: state.messageState.users
});

// Map dispatch function into props
const mapDispatchToProps = (dispatch) => ({
    disconnectSocket: () => dispatch(disconnectSocket()),
    connectSocket: () => dispatch(connectSocket()),
    abandonChat: () => dispatch(abandonChat()),
    sendMessage: message => dispatch(sendMessage(message)),
    userChanged: user => dispatch(userChanged(user)),
    recipientChanged: recipient => dispatch(recipientChanged(recipient)),
    loadEmployees: () => dispatch(loadEmployees())
});

// Export props-mapped HOC
export default connect(mapStateToProps, mapDispatchToProps)(Messenger);