import React from 'react'
import { connect } from 'react-redux'
import { contentDiv } from './../../constants'
import FavoritesIcon from './../../components/FavoritesIcon'
import StoreSelect from './../../components/StoreSelect'
import { CSVLink } from 'react-csv'
import Pivot from './../../components/webdatarocks.react'
import ExportToCSV from './../../components/ExportToCSV'
import  StaticPDF  from './../../pdf/examples/static1'
import { recapByBrand } from './actions'
import { recapByItem } from '../rpbi/actions'
import '@ant-design/compatible/assets/index.css';
import {
    Input,
    Tooltip,
    Select,
    Row,
    Col,
    Checkbox,
    Form,
    Button,
    AutoComplete,
    DatePicker,
    Steps,
    Radio,
    Descriptions,
    Table,
    Typography,
    Modal,
    Skeleton
} from 'antd';
import {
    EditOutlined,
    StarOutlined,
    StarFilled,
} from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer'

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Step } = Steps;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


class RPBBForm extends React.Component {

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        mode: ['month', 'month'],
        monthvalue: [],
        typeflag: 'written',
        stores: [],
        editMode: true,
    };

    componentDidMount() {

    }



    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };



    handlePanelChange = (value, mode) => {
        this.setState({
            monthvalue: value,
            mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
        });
    };

    handleChange = value => {
        this.setState({ montvalue: value });
    };

    onChange = e => {
        //console.log(`radio checked:${e.target.value}`);
    };

    render() {

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 10 },
                md: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
                md: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        return (
            <Form {...formItemLayout} onFinish={this.props.onFinish} >
                <Form.Item label="Report Range" name="monthrange" rules={[{ type: 'array', required: true, message: 'Start and End values required!' }]}>
                    <RangePicker
                        picker="month"
                        placeholder={['Start month', 'End month']}
                        format="MM/YY"
                        //mode={mode}
                        onChange={this.handleChange}
                    //onPanelChange={this.handlePanelChange}
                    />
                </Form.Item>

                <Form.Item label="Type" name="type" defaultValue="written">
                    <Radio.Group>
                        <Radio.Button value="written">Written</Radio.Button>
                        <Radio.Button value="delivered">Delivered</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <StoreSelect
                    label="Select Store(s)"
                    name="stores"
                    multiple
                    includeall
                    rules={[{ required: true, message: 'Please select stores!', type: 'array' }]}
                />

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Continue
                </Button>
                </Form.Item>
            </Form >
        );
    }
}

const columns = [
    {
        title: 'Brand',
        width: 35,
        dataIndex: 'vendor_code',
        key: 'vendor_code',
        fixed: 'left',
        sorter: (a, b) => a.vendor_code < b.vendor_code,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Retail',
        dataIndex: 'retail',
        key: 'retail',
        width: 50,
        align: 'right',
        sorter: (a, b) => a.retail - b.retail,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: '%TOT Retail',
        dataIndex: 'percent_of_retail',
        key: 'percent_of_retail',
        width: 50,
        align: 'right',
        sorter: (a, b) => a.percent_of_retail - b.percent_of_retail,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        width: 50,
        align: 'right',
        sorter: (a, b) => a.cost - b.cost,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Qty',
        width: 50,
        dataIndex: 'qty',
        key: 'qty',
        align: 'right',
        sorter: (a, b) => parseInt(a.qty) - parseInt(b.qty),
        sortDirections: ['ascend', 'descend'],
    },

    {
        title: 'Avg Inventory',
        dataIndex: 'avg_inventory',
        key: 'avg_inventory',
        width: 100,
        align: 'right',
        sorter: (a, b) => a.avg_inventory - b.avg_inventory,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: '%TOT Inventory',
        dataIndex: 'percent_of_inventory',
        key: 'percent_of_inventory',
        width: 100,
        align: 'right',
        sorter: (a, b) => a.percent_of_inventory - b.percent_of_inventory,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Margin',
        dataIndex: 'margin',
        key: 'margin',
        width: 50,
        align: 'right',
        sorter: (a, b) => a.margin - b.margin,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Gmroi',
        dataIndex: 'gmroi',
        key: 'gmroi',
        width: 50,
        align: 'right',
        sorter: (a, b) => a.gmroi - b.gmroi,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Turns',
        dataIndex: 'turns',
        key: 'turns',
        width: 50,
        align: 'right',
        sorter: (a, b) => a.turns - b.turns,
        sortDirections: ['ascend', 'descend'],
    },
];

class RPBB extends React.Component {
    myRef = null;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        mode: ['month', 'month'],
        monthvalue: [],
        typeflag: 'w',
        stores: [],
        editMode: true,
        dataSource: {},
        modalVisible: false,
        modalTitle: '',
        confirmLoading: false
    };

    componentDidMount() {
        if (this.props.results.length === 0) {
            this.setState({ modalVisible: true });
        }
    }

    reportComplete = () => {
        console.log(">>>>>", this.myRef.webdatarocks.getReport());
    }

    onFinish = values => {

        //this.props.recapByBrand(values);
        this.props.dispatch(recapByBrand(values));
        this.setState({
            modalVisible: false,
            confirmLoading: false,
        });
    };


    showModal = () => {
        this.setState({
            modalVisible: true,
        });
    };

    handleModalOk = () => {
        this.setState({
            modalText: 'The modal will be closed after two seconds',
            confirmLoading: true,
        });

    };

    handleModalCancel = () => {
        //console.log('Clicked cancel button');
        this.setState({
            modalVisible: false,
        });
    };

    rowClick = (record, index) => {

        let values = {
            daterange: [],
            type: this.props.type,
            stores: this.props.stores
        };
        values.daterange.push(this.props.monthrange[0].clone().startOf('month'));
        values.daterange.push(this.props.monthrange[1].clone().endOf('month'));

        this.props.dispatch(recapByItem(values))
            .then(() => {
                this.props.history.push('/sales/rpbi');
            });

    };

    render() {
        const { autoCompleteResult, mode, monthvalue, editMode } = this.state;
        const { results, fetching, fetched } = this.props;
        ////console.log(this.props.monthrange);

        const tabledata = [];
        for (let i = 0; i < results.length; i++) {
            tabledata.push({
                key: i,
                vendor_code: results[i].vendor_code,
                retail: results[i].retail,
                percent_of_retail: results[i].percent_of_retail,
                cost: results[i].cost,
                qty: results[i].qty,
                avg_inventory: results[i].avg_inventory,
                percent_of_inventory: results[i].percent_of_inventory,
                margin: results[i].margin,
                gmroi: results[i].gmroi,
                turns: results[i].turns
            });
        }
        const csvHeaders = [];
        for (let i = 0; i < columns.length; i++) {
            let hobj = {
                label: columns[i].title,
                key: columns[i].key
            };
            csvHeaders.push(hobj);
        }

        return (
            <div style={contentDiv}>

                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.modalVisible}
                    // onOk={this.handleModalOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleModalCancel}
                    width="90%"
                    footer={null}
                >
                    <RPBBForm onFinish={this.onFinish} />
                </Modal>

                <Row type="flex">
                    <Col xs={24}>

                        <Descriptions
                            title={<div><FavoritesIcon name="Recap Peformance by Brand" path="/sales/rpbb" /> Recap Peformance by Brand <EditOutlined onClick={() => this.setState({ modalVisible: true })} /></div>}
                            //bordered
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Report Range">
                                {this.props.monthrange.length == 2 &&
                                    <div>{this.props.monthrange[0].format('MM/YY') + ' - ' + this.props.monthrange[1].format('MM/YY')}</div>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Type">{this.props.type.toString()}</Descriptions.Item>
                            <Descriptions.Item label="Stores">{this.props.stores.join(',')}</Descriptions.Item>
                            <Descriptions.Item label="Last Updated">
                                {this.props.last_updated !== null &&
                                    <div>{this.props.last_updated.toString()}</div>
                                }
                            </Descriptions.Item>
                        </Descriptions>

                        <Row>
                            {/*
                            <Col xs={24}>
                                {tabledata.length > 0 &&
                                    <Pivot
                                        ref={(elem) => {
                                            this.myRef = elem
                                        }}
                                        beforetoolbarcreated={(toolbar) => {
                                            let tabs = toolbar.getTabs();
                                            toolbar.getTabs = function() {
                                                delete tabs[0];
                                                delete tabs[1];
                                                delete tabs[2];
                                                return tabs;
                                            }
                                        }}
                                        toolbar={true}
                                        report={{
                                            dataSource: {
                                                data: tabledata
                                            }
                                        }}
                                        reportcomplete={() => {
                                            this.reportComplete();
                                        }}
                                    />

                                }
                            </Col>
*/}

                            <Col xs={24}>
                                {tabledata.length > 0 ?
                                    <Table
                                        columns={columns}
                                        dataSource={tabledata}
                                        loading={this.props.fetching}
                                        scroll={{ x: 1500, y: 300 }}
                                        footer={() => this.renderTableFooter(tabledata, csvHeaders)}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { this.rowClick(record, rowIndex) }, // click row
                                            };
                                        }}
                                    />
                                    :
                                    <Skeleton />
                                }
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
    renderTableFooter = (tabledata, csvHeaders) => {
        if (tabledata.length > 0) {
            return (<>
                <ExportToCSV csvData={tabledata} fileName="rpbb.csv" headers={csvHeaders} />
                {/*<Button type="primary">PDF</Button>
                <PDFDownloadLink document={<StaticPDF />} fileName="somename.pdf">
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
            </PDFDownloadLink>*/}
            </>);
        } else if (this.props.fetching) {
            //return (<CSVLink data={tabledata} headers={csvHeaders} filename='rpbb.csv'>Export</CSVLink>);
            return (<Button type="primary" loading>Loading</Button>);
        } else {
            return (<div />);
        }
    };
}


const mapStateToProps = store => ({
    results: store.recapPerformanceByBrand.results,
    fetching: store.recapPerformanceByBrand.fetching,
    fetched: store.recapPerformanceByBrand.fetched,
    monthrange: store.recapPerformanceByBrand.monthrange,
    stores: store.recapPerformanceByBrand.stores,
    type: store.recapPerformanceByBrand.type,
    last_updated: store.recapPerformanceByBrand.last_updated,
});

const mapDispatchToProps = dispatch => {
    return {
        recapByBrand: (data) => {
            dispatch(recapByBrand(data));
        },
    }
};

export default connect(mapStateToProps)(RPBB)//, mapDispatchToProps)(RPBB)