import React from 'react'
import { contentDiv } from './../../constants'
import DefineFileSelect from './../../components/DefineFileSelect'
import '@ant-design/compatible/assets/index.css';
import {
    Input,
    Tooltip,
    Select,
    Row,
    Col,
    Checkbox,
    Form,
    Button,
    AutoComplete,
    DatePicker,
    Steps,
    Radio,
    Descriptions,
    Table,
    Typography,
    Modal,
} from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Step } = Steps;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


export class ReadyForPickupSetup extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        mode: ['month', 'month'],
        monthvalue: [],
        typeflag: 'written',
        stores: [],
        editMode: true,
    };

    componentDidMount() {

    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };



    handlePanelChange = (value, mode) => {
        this.setState({
            monthvalue: value,
            mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
        });
    };

    handleChange = value => {
        this.setState({ montvalue: value });
    };

    onChange = e => {
        //console.log(`radio checked:${e.target.value}`);
    };

    render() {

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 10 },
                md: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
                md: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        return (
            <Form {...formItemLayout} onFinish={this.props.onFinish} >
                <Form.Item label="Type" name="type" defaultValue="written">
                    <Radio.Group>
                        <Radio.Button value="written">Written</Radio.Button>
                        <Radio.Button value="delivered">Delivered</Radio.Button>
                    </Radio.Group>
                </Form.Item>


                <DefineFileSelect
                    label="Select Email Template"
                    name="etemplate"
                    prefix="MARKET."
                />

                <DefineFileSelect
                    label="Select SMS Template"
                    name="stemplate"
                    prefix="SMS."
                />

                <DefineFileSelect
                    label="Select Voice Template"
                    name="vtemplate"
                    prefix="CALL."
                />

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Continue
                </Button>
                </Form.Item>
            </Form >
        );
    }
}

