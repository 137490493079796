import * as Protocol from '../../../constants/Protocol.js';
import io from 'socket.io-client';
import { SOCKET_URL, SOCKET_PORT } from '../../../constants';

// Socket manager
export default class Socket {

    constructor(onChange, onSocketError, onSetupEmployees, onIncomingMessage, onUpdateClient, onNewUser, onNewCall) {
        this.onChange = onChange;
        this.onSocketError = onSocketError;
        this.onSetupEmployees = onSetupEmployees;
        this.onIncomingMessage = onIncomingMessage;
        this.onUpdateClient = onUpdateClient;
        this.onNewUser = onNewUser;
        this.onNewCall = onNewCall;
        this.socket = null;
        this.user = null;
        this.port = SOCKET_PORT;
    }

    // User clicked connect button
    connect = (user, port) => {

        this.user = user;
        this.port = port;

        // Connect
        const host = SOCKET_URL;
        this.socket = io.connect(host);

        // Set listeners
        this.socket.on(Protocol.CONNECT, this.onConnected);
        this.socket.on(Protocol.DISCONNECT, this.onDisconnected);
        this.socket.on(Protocol.CONNECT_ERR, this.onError);
        this.socket.on(Protocol.RECONNECT_ERR, this.onError);
         
    };

    // Received connect event from socket
    onConnected = () => {
        //this.sendIdent();
        this.socket.on(Protocol.IM, this.onMessage);
        this.socket.on(Protocol.UPDATE_CLIENT, this.onUpdateClient);
        this.socket.on('load_employees',this.onSetupEmployees);
        this.socket.on('message',this.onIncomingMessage);
        this.socket.on('new user',this.onNewUser);
        this.socket.on('new call',this.onNewCall);
        this.onChange(true);
    };

    // Received disconnect event from socket
    onDisconnected = () => this.onChange(false);

    // Send an identification message to the server
    sendIdent = (user) => this.socket.emit(Protocol.IDENT, user);

    // Send a message over the socket
    sendIm = message => this.socket.emit(Protocol.IM, message);
    //sendIm = message => this.socket.emit('load_setup', message);

    loadEmployees = () => this.socket.emit('load_setup','');

   // onIncomingMessage = (message) => {console.log('onMessage',message)};
    // Close the socket
    disconnect = () => this.socket.close();

    // Received error from socket
    onError = message  => {
        this.onSocketError(message);
        this.disconnect();
    };

}
