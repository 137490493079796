const frogCalendar = (state = {
    calendar: [],
    fetching: false,
    fetched: false,
    error: null,
}, action) => {
    //console.log(action.type,action.payload);
    switch (action.type) {
        case 'FETCH_MONTH':
            return { 
                ...state, 
                fetching: true,
                calendar: [] 
            }
        case 'FETCH_MONTH_FAIL':
            return { ...state, fetching: false, error: action.payload, calendar: [] }
        case 'FETCH_MONTH_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                calendar: action.payload.results,
            }
       

        default:
            return state
    }
}

export default frogCalendar