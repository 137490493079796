import { createForm, createFormField, formShape } from 'rc-form';
import React from 'react'
import { connect } from 'react-redux'
import { getCustomer } from './actions'
import StateSelect from './../components/StateSelect'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Skeleton, Card } from 'antd';

/*
const CustomerEntryForm = Form.create({
    name: 'global_state',
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        return {
            username: Form.createFormField({
                ...props.username,
                value: props.username.value,
            }),
        };
    },
    onValuesChange(_, values) {
        //console.log(values);
    },
})(props => {
    const { getFieldDecorator } = props.form;
    return (
        <Form layout="inline">
            <Form.Item label="Username">
                {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Username is required!' }],
                })(<Input />)}
            </Form.Item>
        </Form>
    );
});
*/
class MyForm extends React.Component {
    static propTypes = {
        form: formShape,
    }

    onPressEnter = (field) => {
        
        const cusno = this.props.formState.customer_number.value;
        //console.log('enter',cusno);
        this.props.getCustomer(cusno);
    };

    renderField = (fieldName, getFieldDecorator) => {
        let cusfields = this.props.formState.cusrec;
        const fieldDef = cusfields[fieldName];
        //console.log('filter', fieldDef);
        if (fieldDef !== undefined && fieldDef.editmode !== 'hidden') {
            let input = <Input allowClear />;
            let fieldRules = [];
            if (fieldDef.editmode === 'required') {
                fieldRules.push({ required: true, message: fieldDef.name + ' is required!' });
            }
            if (fieldDef.editmode === 'display') {
                input = <Input disabled />;
            }
            return (
                <Form.Item label={fieldDef.name}>
                    {getFieldDecorator(fieldName, {
                        rules: [fieldRules],
                    })(input)}
                </Form.Item>
            );
        } else {
            return (<div />);
        }
    };

    render() {
        ////console.log('myform', this.props.formState.cusrec);
        const { getFieldProps, getFieldError } = this.props.form;
        const { getFieldDecorator } = this.props.form;
        const errors = getFieldError('email');
        return (
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item label="Phone">
                            {getFieldDecorator('customer_number', {
                                rules: [{ required: true, message: 'Username is required!' }],
                            })(<Input onPressEnter={this.onPressEnter} />)}
                        </Form.Item>

                    </Col>
                    <Col xs={24} lg={12}>
                        <Card title="Billing Info" bordered={false}>

                            {this.renderField('name', getFieldDecorator)}
                            {this.renderField('add1', getFieldDecorator)}
                            <Row>
                                <Col xs={24} lg={10}>
                                    {this.renderField('city', getFieldDecorator)}
                                </Col>
                                <Col xs={24} lg={8}>
                                    <StateSelect form={this.props.form} />
                                </Col>
                                <Col xs={24} lg={6}>
                                    {this.renderField('zip', getFieldDecorator)}
                                </Col>
                            </Row>

                            {this.renderField('email', getFieldDecorator)}
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card title="Shipping Info" bordered={false}>
                            {
                                (this.renderField('del_to', getFieldDecorator), this.renderField('del_address', getFieldDecorator))
                            }
                            <Row>
                                <Col xs={24} md={10}>
                                    {this.renderField('del_city', getFieldDecorator)}
                                </Col>
                                <Col xs={24} md={8}>
                                    <StateSelect form={this.props.form} />
                                </Col>
                                <Col xs={24} md={6}>
                                    {this.renderField('del_zip', getFieldDecorator)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} >
                                    {this.renderField('lot_name', getFieldDecorator)}
                                </Col>
                                <Col xs={16} >
                                    {this.renderField('subdivision', getFieldDecorator)}
                                </Col>
                            </Row>
                            {this.renderField('spouse_phone', getFieldDecorator)}
                            {this.renderField('xstreet', getFieldDecorator)}
                            
                        </Card>
                    </Col>
                    <Col xs={24}>
                        <Card title="Additional Info" bordered={false}>
                            {this.renderField('account_number', getFieldDecorator)}
                            {this.renderField('special_instructions', getFieldDecorator)}
                            {this.renderField('last_slm', getFieldDecorator)}
                            {this.renderField('map_code', getFieldDecorator)}
                            {this.renderField('acct_type', getFieldDecorator)}
                        </Card>
                    </Col>
                </Row>
            </Form>
        );


    }
}

const CustomerEntryForm = connect((state) => {
    return {
        formState: {
            cusrec: state.customer.cusrec,
            customer_number: state.customer.customer_number,
            name: state.customer.name,
            add1: state.customer.add1,
            city: state.customer.city,
            state: state.customer.state,
            zip: state.customer.zip,
            del_to: state.customer.del_to,
            del_address: state.customer.del_address,
            del_city: state.customer.del_city,
            del_state: state.customer.del_state,
            del_zip: state.customer.del_zip,
            lot_name: state.customer.lot_name,
            subdivision: state.customer.subdivision,
            salutation: state.customer.salutation,
            spouse_phone: state.customer.spouse_phone,
            special_instructions: state.customer.special_instructions,
            send_no_mail: state.customer.send_no_mail,
            send_no_email: state.customer.send_no_email,
            bus_phone: state.customer.bus_phone,
            email: state.customer.email,
            ssn: state.customer.ssn,
            account_number: state.customer.account_number,
            price: state.customer.price,
            last_slm: state.customer.last_slm,
            credit_limit: state.customer.credit_limit,
            bui1_tax_id: state.customer.bui1_tax_id,
            xstreet: state.customer.xstreet,
            cus_comment: state.customer.cus_comment,
            map_code: state.customer.map_code,
            modem_type: state.customer.modem_type,
            acct_type: state.customer.acct_type,
            password: state.customer.password,
            last_sale_date: state.customer.last_sale_date,
        },
    };
})(createForm({
    mapPropsToFields(props) {
        //console.log('mapPropsToFields', props);
        return {
            email: createFormField(props.formState.email),
            customer_number: createFormField(props.formState.customer_number),
            name: createFormField(props.formState.name),
            add1: createFormField(props.formState.add1),
            city: createFormField(props.formState.city),
            state: createFormField(props.formState.state),
            zip: createFormField(props.formState.zip),
            del_to: createFormField(props.formState.del_to),
            del_address: createFormField(props.formState.del_address),
            del_city: createFormField(props.formState.del_city),
            del_state: createFormField(props.formState.del_state),
            del_zip: createFormField(props.formState.del_zip),
            lot_name: createFormField(props.formState.lot_name),
            subdivision: createFormField(props.formState.subdivision),
            salutation: createFormField(props.formState.salutation),
            spouse_phone: createFormField(props.formState.spouse_phone),
            special_instructions: createFormField(props.formState.special_instructions),
            send_no_email: createFormField(props.formState.send_no_email),
            send_no_mail: createFormField(props.formState.send_no_mail),
            bus_phone: createFormField(props.formState.bus_phone),
            email: createFormField(props.formState.email),
            ssn: createFormField(props.formState.ssn),
            account_number: createFormField(props.formState.account_number),
            price: createFormField(props.formState.price),
            last_slm: createFormField(props.formState.last_slm),
            credit_limit: createFormField(props.formState.credit_limit),
            bui1_tax_id: createFormField(props.formState.bui1_tax_id),
            xstreet: createFormField(props.formState.xstreet),
            cus_comment: createFormField(props.formState.cus_comment),
            map_code: createFormField(props.formState.map_code),
            modem_type: createFormField(props.formState.modem_type),
            acct_type: createFormField(props.formState.acct_type),
            password: createFormField(props.formState.password),
            last_sale_date: createFormField(props.formState.last_sale_date),

        };
    },
    onFieldsChange(props, fields) {
        ////console.log('onFieldsChange', fields);
        props.dispatch({
            type: 'SAVE_FIELDS',
            payload: fields,
        });
    },
})(MyForm));



class CustomerEntry extends React.Component {
    state = {
        fields: {
            email: {
                value: 'benjycui',
            },
        },
    };

    componentDidMount() {
        this.props.getCustomer('');
    }

    handleFormChange = changedFields => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    render() {
        const { fields } = this.state;
        if (this.props.fetching === true) {
            return (
                <div>
                    <Skeleton active />
                </div>
            );
        } else {
            return (
                <div>
                    <CustomerEntryForm {...fields} onChange={this.handleFormChange} getCustomer={this.props.getCustomer} />
                </div>
            );
        }
    }
}

const mapStateToProps = store => ({
    cusrec: store.customer.cusrec,
    fetching: store.customer.fetching,
    fetched: store.customer.fetched
});

const mapDispatchToProps = dispatch => {
    return {
        getCustomer: (cusno) => {
            dispatch(getCustomer(cusno));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEntry)
//export default CustomerEntry;