
import { Base64, BASE_URL, FROG_AUTH } from './../../constants';
const apiurl = BASE_URL + 'recap_performance';

var dayjs = require('dayjs');

// Action creators store.dispatch actions

export const recapByItem = (values) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_ITEM', payload: values });
        
        const startdate = values.daterange[0].format('MM-DD-YY');
        const enddate = values.daterange[1].format('MM-DD-YY');
        const type = values.type;
        console.log(values);

        let queryparams = '?report=item';
        queryparams += '&type=' +type;
        queryparams += '&startdate=' +startdate;
        queryparams += '&enddate='+enddate;
        if (values.allitems === undefined) {
            queryparams += '&allitems=0';
        } else {
            queryparams += '&allitems=1';
        }
        for (let i=0; i<values.departments.length; i++) {
            queryparams += '&department='+values.departments[i];
        }
        for (let i=0; i<values.stores.length; i++) {
            queryparams += '&location='+values.stores[i];
        }
        for (let i=0; i<values.status.length; i++) {
            queryparams += '&status='+values.status[i];
        }
        for (let i=0; i<values.collections.length; i++) {
            queryparams += '&collection='+values.collections[i];
        }
        return fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
           // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_ITEM_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    console.log(response);
                    let results = {};
                    results.results = response;
                    results.last_updated = dayjs().format('MM/DD/YY  h:mm:ss');
                    dispatch({ type: 'FETCH_ITEM_SUCCESS', payload: results });
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('recapbyitem catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_ITEM_FAIL', payload: error });
            });
    };
};