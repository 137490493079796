import React, { useEffect, useState } from 'react'
import {
    Form,
    Select,
} from 'antd';

const { Option } = Select;

let salesRecord = [];
let locationRecord = [];

const StoreSelect = (props) => {
    const [mode, setMode] = useState('');
    const [includeAll, setIncludeAll] = useState(false);
    const [options, setOptions] = useState(JSON.parse(localStorage.getItem('sales_record')));
    const [selectedLocations, setSelected] = useState([]);
    const [defaultValue,setDefaultValue] = useState('');

    useEffect(() => {
        let newoptions = [...options];
        if (props.hasOwnProperty('multiple')) {
            setMode('multiple');
        }
        if (props.hasOwnProperty('location')) {
            newoptions = JSON.parse(localStorage.getItem('location_record'))
        }
        
        if (newoptions.length > 1 && props.hasOwnProperty('includeall')) {
            console.log(newoptions,newoptions.length);
            setIncludeAll(true);
            //options.push('ALL');
            newoptions.unshift('ALL');
            setDefaultValue('ALL');
        }
        setOptions(newoptions);
    }, [])

    const handleChange = (value) => {
        //console.log(`selected ${value}`);
        setSelected(value);
    }
    let children = [];
    for (let i = 0; i < options.length; i++) {
        children.push(<Option key={options[i]} value={options[i]}>{options[i]}</Option>);
    }
    //console.log('def',defaultValue);
    return (
        <Form.Item label={props.label} name={props.name} rules={props.rules}>
            <Select
                mode={mode}
                style={{ width: '100%' }}
                placeholder="Please select"
                //defaultValue={defaultValue}
                //value={selectedLocations}
                onChange={handleChange}
            >
                {children}
            </Select>
        </Form.Item>
    );

}

export default StoreSelect;