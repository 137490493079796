import React, { useState, useEffect } from 'react'
import useSWR from 'swr';
import { BASE_URL, FROG_AUTH,  Base64 } from '../constants'
import {
    Form,
    Select,
} from 'antd';

const { Option } = Select;


const statusAPI = BASE_URL + 'status_type?key=ALL';

const fetchStatusTypes = async () => {
    console.log('start employee fetch');
    return await fetch(statusAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}

const StatusSelect = (props) => {
    const [mode, setMode] = useState(props.mode);
    const [label, setLabel] = useState(props.label);
    const [name, setName] = useState(props.name);
    const [rules, setRules] = useState(props.rules);
    const [includeAll, setIncludeAll] = useState(props.includeall);
    //const [data, setData] = useState([]);
    const { data: statusTypes } = useSWR(statusAPI, fetchStatusTypes);

    if (includeAll && statusTypes !== undefined) {
        statusTypes.unshift({ "code": "ALL", "name": "ALL" });
    }

    return (
        <Form.Item label={label} name={name} rules={rules}>
            <Select
                mode={mode}
                style={{ width: '100%' }}
                placeholder="Please select"
            //defaultValue={[]}
            //onChange={this.handleChange}
            >
                {
                    statusTypes && (
                        statusTypes.map(status => <Option key={status.code} value={status.code}>{status.name}</Option>)
                    )
                }
            </Select>
        </Form.Item>
    );
}


export default StatusSelect;

/*
let statuses = ["CU","DI","DV","RL"];

class StatusSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '',
            includeAll: false,
            selectedStatuses: [],
        }
    }

    componentDidMount() {
        //console.log('select props',this.props);
        if (this.props.hasOwnProperty('multiple')) {
            this.setState({ mode: 'multiple' });
        }
        if (this.props.hasOwnProperty('includeall')) {
            this.setState({ includeAll: true });
            statuses.push('ALL');
        }
    }


    handleChange = (value) => {
        //console.log(`selected ${value}`);
        this.setState({selectedStatuses: value});
    }
    render() {
        const children = [];

        for (let i = 0; i < statuses.length; i++) {
            children.push(<Option key={statuses[i]} value={statuses[i]}>{statuses[i]}</Option>);
        }
        return (
            <Form.Item label={this.props.label} name={this.props.name} rules={this.props.rules}>
            <Select
                mode={this.state.mode}
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={[]}
                onChange={this.handleChange}
            >
                {children}
            </Select>
            </Form.Item>
        );
    }
}
export default StatusSelect;
*/