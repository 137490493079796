import React, { useState } from 'react'
import { BASE_URL, FROG_AUTH,  Base64 } from '../../constants'
import useSWR from 'swr';
import { ResponsivePie, ResponsivePieCanvas } from '@nivo/pie'
import {
    Row,
    Col
} from 'antd';
import { EditOutlined } from '@ant-design/icons';

const dashboardAPI = BASE_URL + 'dashboard?type=top10slp';

const fetchSales = async () => {
    //console.log('start employee fetch');
    return await fetch(dashboardAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}


const onPieClick = (data) => {
    console.log(data);
}

const TopTenSlp = props => {
    const [profileState, setProfileState] = useState(props);
    //const [data, setData] = useState([]);
    const { data: salesData } = useSWR(dashboardAPI, fetchSales);

    /*
    useEffect(() => {
        
        
        setData(props.employeeList);
        //if (data.length === 0) {fetchEmployees();}
        console.log(props);
    }, [data])
    *
   onPieClick = (params) => {
    console.log('onPieClick',params);
};*/

    let pieData = [];
    if (salesData !== undefined) {
        for (let i = 0; i < salesData.topten.length; i++) {
            let dollars = salesData.topten[i].value;
            if (dollars === '') { dollars = 0; }
            dollars = parseFloat(dollars);
            if (dollars > 0) {
                let store = {
                    "id": salesData.topten[i].id,
                    "value": dollars
                };
                pieData.push(store);
            }
        }
    }

    let lyData = [];
    if (salesData !== undefined) {
        for (let i = 0; i < salesData.lyten.length; i++) {
            let dollars = salesData.lyten[i].value;
            if (dollars === '') { dollars = 0; }
            dollars = parseFloat(dollars);
            if (dollars > 0) {
                let store = {
                    "id": salesData.lyten[i].id,
                    "value": dollars
                };
                lyData.push(store);
            }
        }
    }
    //console.log(pieData);


    return (
        <Row>
            {salesData !== undefined &&
            <>
                <Col xs={12}>This Year</Col>
                <Col xs={12}>{salesData.mtd}</Col>
                <Col xs={12}>Last Year</Col>
                <Col xs={12}>{salesData.lymtd}</Col>
                </>
            }
            <Col xs={12}>
                <div style={{ height: 400 }}>
                    {pieData.length > 0 ?
                        <ResponsivePie
                            data={pieData}
                            margin={{ top: 5, right: 10, bottom: 10, left: 10 }}
                            innerRadius={0}
                            padAngle={0.7}
                            cornerRadius={3}
                            colors={{ scheme: 'nivo' }}
                            enableRadialLabels={false}
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextXOffset={6}
                            radialLabelsTextColor="#333333"
                            radialLabelsLinkOffset={0}
                            radialLabelsLinkDiagonalLength={16}
                            radialLabelsLinkHorizontalLength={24}
                            radialLabelsLinkStrokeWidth={1}
                            radialLabelsLinkColor={{ from: 'color' }}
                            slicesLabelsSkipAngle={10}
                            slicesLabelsTextColor="#333333"
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            onClick={(data) => onPieClick(data)}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'ST1'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'ST2'
                                    },
                                    id: 'dots'
                                }
                            ]}
                        />
                        :
                        <div>loading</div>
                    }

                </div>
            </Col>
            <Col xs={12}>
                <div style={{ height: 400 }}>
                    {pieData.length > 0 ?
                        <ResponsivePie
                            data={lyData}
                            margin={{ top: 5, right: 10, bottom: 10, left: 10 }}
                            innerRadius={0}
                            padAngle={0.7}
                            cornerRadius={3}
                            colors={{ scheme: 'nivo' }}
                            enableRadialLabels={false}
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextXOffset={6}
                            radialLabelsTextColor="#333333"
                            radialLabelsLinkOffset={0}
                            radialLabelsLinkDiagonalLength={16}
                            radialLabelsLinkHorizontalLength={24}
                            radialLabelsLinkStrokeWidth={1}
                            radialLabelsLinkColor={{ from: 'color' }}
                            slicesLabelsSkipAngle={10}
                            slicesLabelsTextColor="#333333"
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            onClick={(data) => onPieClick(data)}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'ST1'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'ST2'
                                    },
                                    id: 'dots'
                                }
                            ]}
                        />
                        :
                        <div>loading</div>
                    }

                </div>
            </Col>
        </Row>
    )
}


export default TopTenSlp;
