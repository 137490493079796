const mfgl = (state = {
    results: [],
    fetching: false,
    fetched: false,
    error: null,
    searchkey: '',
    statusCodes: [],
    includeGroups: false,
    inStockOnly: false,
    last_updated: null
}, action) => {
    ////console.log(action.type, action.payload);
    switch (action.type) {
        case 'FETCH_RESULTS':
            return { ...state, 
                fetching: true,
                fetched: false,
                error: null,
                searchkey: action.payload.searchkey,
                statusCodes: action.payload.statusCodes,
                includeGroups: action.payload.includeGroups,
                inStockOnly: action.payload.inStockOnly,
            }
        case 'FETCH_RESULTS_FAIL':
            return { ...state, 
                fetching: false, 
                error: action.payload 
            }
        case 'FETCH_RESULTS_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                results: action.payload, 
                last_updated: action.payload.last_updated,
                error: null,
            }

        default:
            return state
    }
}

export default mfgl