// ACTIONS
import {
    EMPLOYEE_LIST_CHANGED,
    STATUS_LIST_CHANGED,
    DEPT_LIST_CHANGED,
    FROG_LOGIN,
    FROG_LOGIN_SUCCESS,
    FROG_LOGIN_FAIL,
    FROG_LOGOUT
} from '../actions';

import { SAVE_FAVORITES_SUCCESS, SAVE_MFGL_COLUMNS_SUCCESS } from '../../../setup/employee/actions'


// CONSTANTS

// Initial state
const INITIAL_STATE = {
    isLoggedIn: false,
    employeeList: [],
    statusList: [],
    deptList: [],
    username: null,//'JASON',
    employeeTasks: [],
    employeeFavorites: [],
    mfglColumns: [],
    salesRecord: [],
    locationRecord: [],
    loginToken: null,
    employeeName: null,
    fetchingLogin: false,
    errorMessage: null,
};

// Message Reducer
function setupReducer(state = INITIAL_STATE, action) {
    
    //console.log('SETUP REDUCER', action);
    switch (action.type) {
        case EMPLOYEE_LIST_CHANGED:
            return { 
                ...state, 
                employeeList: action.employeeList.employees
            }

        case DEPT_LIST_CHANGED:
            return { 
                ...state, 
                deptList: action.deptList
            }

        case STATUS_LIST_CHANGED:
            return { 
                ...state, 
                statusList: action.statusList
            }

        case FROG_LOGIN:
            return { 
                ...state, 
                fetchingLogin: true,
                errorMessage: null
            }

        case FROG_LOGIN_SUCCESS:
            return { 
                ...state, 
                fetchingLogin: false,
                username: action.payload.employee_code,
                employeeTasks: action.payload.tasks,
                employeeFavorites: action.payload.favorites,
                mfglColumns: action.payload.mfgl_columns,
                loginToken: action.payload.token,
                employeeName: action.payload.name,
                salesRecord: action.payload.sales_record,
                locationRecord: action.payload.location_record
            }

        case FROG_LOGIN_FAIL:
            return { 
                ...state, 
                fetchingLogin: false,
                errorMessage: action.payload
            }

        case FROG_LOGOUT:
            return { 
                ...state, 
                isLoggedIn: false,
                username: null,
                employeeTasks: [],
                loginToken: null,
                employeeName: null
            }
        case SAVE_FAVORITES_SUCCESS:
            return { 
                ...state, 
                employeeFavorites: action.payload.favorites
            }
            case SAVE_MFGL_COLUMNS_SUCCESS:
                return { 
                    ...state, 
                    mfglColumns: action.payload.mfgl_columns
                }

        default:
            return state
    }
}

export default setupReducer;