import React, { useState, useEffect } from 'react'
import RealtimeSales from './../components/dashboard/RealtimeSales'
import TopTenSlp from './../components/dashboard/TopTenSlp'
import DailyFigures from './../components/dashboard/DailyFigures'
import TodaysDetails from '../components/dashboard/TodaysDetails'
import {
    Input,
    Tooltip,
    Select,
    Row,
    Col,
    Card,
    Checkbox,
    Form,
    Button,
    AutoComplete,
    DatePicker,
    Steps,
    Radio,
    Descriptions,
    Table,
    Typography,
    Modal,
} from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';




//class ManagementDashboard extends React.Component {
const ManagementDashboard = (props) => {
    const [editMode, setEditMode] = useState(true);
    const [loadingRealtime, setloadingRealtime] = useState(true);
    const [loadingTopTen, setloadingTopTen] = useState(true);

    const setRealtime = () => {
        setloadingRealtime(false);
    }

    return (
        <>
            <TodaysDetails />
            {/*
            <div style={{ height: 400 }}>
                <TopTenSlp />
            </div>
            <div style={{ height: 400 }}>
                <DailyFigures />
            </div>
            */}
        </>
    );

    return (
        <>
            <Row gutter={16} >
                <Col xs={24} > {/*style={{ height: 400, border: "1px solid black" }}>*/}
                    {/*<RealtimeSales action={setRealtime} />*/}
                    <TodaysDetails />
                </Col>
            </Row>
            <Row gutter={16} >
                <Col xs={24} md={12} >
                    <Card bordered={true} title="Top 10 Salespeople">
                        <div style={{ height: 400 }}>
                            <TopTenSlp />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={12} >
                    <Card bordered={true} title="Sales Last 30 Days">
                        <div style={{ height: 400 }}>
                            <DailyFigures />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ManagementDashboard;
