import React, { useState, useEffect } from 'react'
import { BASE_URL, FROG_AUTH,  Base64 } from '../../constants'
import useSWR from 'swr';
import { ResponsiveContainer, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Bar } from 'recharts'
import { PropTypes } from 'prop-types';
import {
    Skeleton
} from 'antd';
//import { EditOutlined } from '@ant-design/icons';

const dashboardAPI = BASE_URL + 'dashboard?type=dailyfigures';



const BarGraph = props => {
    const [title, setTitle] = useState(props.title);
    const [data, setData] = useState(props.data);
    //const [data, setData] = useState([]);
  //  const { data: salesData } = useSWR(dashboardAPI, fetchSales);

    
    useEffect(() => {
        
        
        setData(props.data);
        //if (data.length === 0) {fetchEmployees();}
        //console.log(props);
    }, [props]);
    
    let graphData = [];
    if (data.length > 0) {
        // logic to pre-build time array
        for (let i = 6; i <= 22; i++) {
            let hour = '';
            if (i < 12) {
                hour = i;
                hour = hour.toString().padStart(2,'0') + 'AM';
            } else if (i == 12) {
                hour = i;
                hour = hour.toString() + 'PM';
            } else {
                hour = i - 12;
                hour = hour.toString().padStart(2,'0') + 'PM';
            }
            let obj = {
                "name":hour,
                "qty":0,
                "retail":0
            }
            graphData.push(obj);
        }
        
        for (let i = 0; i < data.length; i++) {
            let qty = parseInt(data[i].qty);
            let retail = parseFloat(data[i].retail);
            let time = data[i].time;
            let hour = time.substring(0,2) + time.substring(5,7);
            //console.log('Hour - ',hour);
            let find = graphData.findIndex(x => x.name === hour);
            if (find === -1) {
                let obj = {
                    "name":hour,
                    "qty":qty,
                    "retail":retail
                }
                graphData.push(obj);
            } else {
                graphData[find].qty += qty;
                let r = graphData[find].retail;
                r += retail;
                graphData[find].retail = r;//.toFixed(2);
            }
        }
        graphData.sort((a,b) => {
            return a.toString().localeCompare(b); //a.name < b.name;
        });
        //console.log('graphdata',graphData);
        /*
        let thisline = {
            "id": "Last 30 Days",
            "color": "hsl(45, 70%, 50%)",
            "data": []
        };
        
        graphData.push(thisline);
        */
    }



    return (
        <ResponsiveContainer width="100%" height={400}>
            {graphData.length > 0 ?
                <BarChart data={graphData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/*<Bar dataKey="qty" fill="#82ca9d" />*/}
                    <Bar dataKey="retail" fill="#8884d8" />
                </BarChart>

                :
                <div >
                    <Skeleton active />
                </div>
            }

        </ResponsiveContainer>
    )
}

BarGraph.propeTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
};

export default BarGraph;
