import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom'
//import { abandonChat, sendMessage, userChanged, recipientChanged } from '../websocket/message/actions';
import { MessageContext } from '../hooks/messageContext'
import {
    Form,
    Input,
    Button,
    Result,
    AutoComplete,
    Comment,
    List,
    Card
} from 'antd';

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
        md: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
        md: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};



const InstantMessage = (props) => {
   // const ctxThread = useContext(MessageContext);
    const [mode, setMode] = useState(props.mode);
    const [label, setLabel] = useState(props.label);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [comments, setComments] = useState([])
    const account = JSON.parse(localStorage.getItem('account'))

    useEffect(() => {

        const newList = props.employeeList.map(eobj => ({ value: eobj.user }));
        console.log(newList);
        setOptions(newList);
    }, [props.employeeList]);

    useEffect(() => {
        //console.log('props threads changed',props.threads);
        let newthread = [...comments,...props.threads];
        setComments(props.threads);
    }, [props.threads]);

    const onFinish = value => {
        //let commentlist = [...comments];
        const comment = [{
            author: account.username,
            message: value.memo
        }];
        //commentlist.push(comment);
        //console.log('comment list',commentlist);
        
        props.sendMessage(value.memo);
        setComments([...comments,...comment]);
    };

    const onSelect = (data) => {
        console.log('onSelect', data);
    };
    const onChange = (data) => {
        setValue(data);
    };
    const onBlur = (data) => {
        console.log('on blur');
        const recipient = props.employeeList.filter(eobj => eobj.user === value).map(eobj => eobj.id);
        props.recipientChanged(recipient[0])
    };

    console.log('instant message', props.employeeList);
    if (props.employeeList.length === 0) {
        return (
            <Result title="No other active users found at this time." />
        )
    }
   
    console.log('comments',comments);
    return (
        <>
            <Card size="small" title="Message" bordered={false}>
            {comments.length > 0 ?
                <List
                    className="comment-list"
                    //header={`${data.length} replies`}
                    itemLayout="horizontal"
                    style={{maxHeight:"200px",overflowY:"scroll"}}
                    dataSource={comments}
                    renderItem={item => (
                        <li>
                            <Comment
                                //actions={item.actions}
                                //author={item.author}
                                //avatar={item.avatar}
                                //content={item.message}
                                content={item}
                                //datetime={item.datetime}
                            />
                        </li>
                    )}
                />
            :
                <AutoComplete
                    options={options}
                    style={{ width: 200 }}
                    //onSelect={onSelect}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="To"
                />
            }
            <Form onFinish={onFinish} >

                <Form.Item name='memo'>
                    <TextArea rows={4} />
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit">
                        Send
                </Button>
                </Form.Item>
            </Form >
            </Card>
        </>
    );
}

export default InstantMessage;

/*
const mapStateToProps = store => ({
    testList: store.setupState.employeeList,
    testThread: store.messageState.threads,
});
const mapDispatchToProps = (dispatch) => ({
    //sendMessage: message => dispatch(sendMessage(message)),
});

// Export props-mapped HOC
export default connect(mapStateToProps, mapDispatchToProps)(InstantMessage);

*/