// ACTIONS
import {
    USER_CHANGED,
    RECIPIENT_CHANGED,
    OUTGOING_MESSAGE_CHANGED,
    MESSAGE_RECEIVED,
    CLIENT_UPDATE_RECEIVED,
    ABANDON_CHAT,
    MESSAGE_SENT,
    SEND_MESSAGE,
    NEW_USER
} from '../actions';


// CONSTANTS
//import { UI } from '../../../constants';
const UI = {
    NO_RECIPIENT: false
}
// Initial state
const INITIAL_STATE = {
    user: undefined,
    recipient: '',
    unread: 0,
    outgoingMessage: '',
    recipientLost: false,
    lostRecipient: null,
    threads: [], //{},
    users: []
};

// Message Reducer
function messageReducer(state = INITIAL_STATE, action) {
    let reduced;
    //console.log('message reducer',action.type);
    switch (action.type) {
        case USER_CHANGED:
            reduced = Object.assign({},
                state, { user: action.user }
            );
            break;
        case NEW_USER:
            reduced = Object.assign({},
                state,
                { users: action.userList }
            );
            break;
        case RECIPIENT_CHANGED:
            reduced = Object.assign({},
                state,
                { recipient: action.recipient },
                (action.recipient === UI.NO_RECIPIENT) ? { outgoingMessage: '' } : {}
            );
            break;

        case OUTGOING_MESSAGE_CHANGED:
            reduced = Object.assign({},
                state,
                { outgoingMessage: action.text }
            );
            break;
        case SEND_MESSAGE:
            console.log('send_message', action);
            //let thread = [...state.threads,...[action.message]];
            reduced = Object.assign({},
                state,
                {
                    threads: [...state.threads, ...[action.message]],
                    outgoingMessage: action.text
                }
            );
            break;

        case MESSAGE_RECEIVED:
            // Find the thread this message belongs to
            const isSentEcho = (action.message.from === state.user);
            const recipient = action.message.from;
            //1/22/21 temporary const recipient = isSentEcho ? action.message.to : action.message.from;
            if (!isSentEcho) {
                // Rendered InstantMessage components need a key, we'll use array index
                //const messageKey = (!!state.threads[recipient]) ? state.threads[recipient].length : action.message.from;
                //const keyedMessage = Object.assign({}, action.message.text, { key: messageKey });

                //console.log(messageKey,keyedMessage);
                // Add the keyed message to a clone of the appropriate thread
                /*
                const thread = (state.threads[recipient])
                    ? state.threads[recipient].concat([keyedMessage])
                    : [keyedMessage];
                */
                /*let thisthread = state.threads.filter(thread => thread.key === recipient);
                if (thisthread.length === 0) {
                    thisthread.push({
                        key: recipient,
                        threads: action.message.text,
                    })
                }*/
                /*
                if (!!state.threads[recipient]) {
                    newthread[recipient] = [action.message.text];
                } else {
                    newthread[recipient] = [...state.threads[recipient],...[action.message.text]];
                }
                console.log(recipient,newthread);
                */

                let thisthread = [...state.threads,...[action.message.text]];
                //console.log('keyedMessage',keyedMessage);
                // Reduce
                reduced = Object.assign({}, state, {
                    recipient: recipient,
                    unread: state.unread + 1,
                    threads: thisthread,
                    //threads: Object.assign({}, state.threads, {
                    //    [recipient]: thread
                    //})
                });
            }
            break;

        case CLIENT_UPDATE_RECEIVED:
            reduced = Object.assign({},
                state, { users: action.otherUsers, recipientLost: action.recipientLost },
                (action.recipientLost)
                    ? { recipient: UI.NO_RECIPIENT, lostRecipient: state.recipient }
                    : {},
                (!action.recipientLost && !!state.lostRecipient)
                    ? { recipient: state.lostRecipient }
                    : {}
            );
            break;

        case MESSAGE_SENT:
            reduced = Object.assign({},
                state,
                {
                    outgoingMessage: ''
                }
            );
            break;

        case ABANDON_CHAT:
            reduced = Object.assign({},
                state,
                { users: [], recipient: UI.NO_RECIPIENT, outgoingMessage: '' }
            );
            break;

        default:
            reduced = state;
    }
    return reduced;
}

export default messageReducer;