import React from 'react'
import { CSVLink } from 'react-csv'
import { Button } from 'antd';

const ExportToCSV = ({csvData, fileName, headers}) => {
    if (headers === undefined) { headers = '';}
    
    return (
        <Button type="primary">
            {headers === '' ?
                <CSVLink data={csvData} filename={fileName}>Export</CSVLink>
            :
                <CSVLink data={csvData} headers={headers} filename={fileName}>Export</CSVLink>
            }
        </Button>
    )
}

export default ExportToCSV;