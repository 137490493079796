import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import LoginForm from '../Login'
import SetupForm from '../Setup'
import { Layout, Row, Col } from 'antd';

const { Header, Footer, Sider, Content } = Layout;

const UnauthorizedLayout = ({ children }) => {
    const [setuprec,setSetup] = useState(null);
    
    useEffect(() => {
        setSetup(localStorage.getItem('setup'));
    }, []);
    
    const onReset = () => {
        localStorage.removeItem('setup');
        localStorage.removeItem('account');
        localStorage.removeItem('sales_record');
        localStorage.removeItem('location_record');
        setSetup(null);
    };

    return (
        <Layout style={{ height: '900px' }}>
            <Row justify="space-around" align="middle">
                <Col style={{ height: '600px' }} />
                <Col >
                    {setuprec === null ?
                        <SetupForm setSetup={setSetup} />
                        :
                        <LoginForm onReset={onReset} />
                    }
                </Col>
                <Col style={{ height: '600px' }} />
            </Row>
        </Layout>
    );
}

UnauthorizedLayout.propTypes = {
    children: PropTypes.func,
}

export default UnauthorizedLayout