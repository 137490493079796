
import { Base64, BASE_URL, FROG_AUTH } from './../constants';
const apiurl = BASE_URL + 'customer';
const apikey = 'AD8C0721626A48AE8C82434CA71F4835';

// Action creators store.dispatch actions
export const saveFields = (cusobj) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SAVE_FIELDS', payload: cusobj });
    };
};

export const getCustomer = (cusno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_CUSTOMER' });
        //var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        let queryparams = '?customernumber='+cusno
        fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
           // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_CUSTOMER_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_CUSTOMER_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('getcustomer catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_CUSTOMER_FAIL', payload: error });
            });
    };
};

export const createCustomer = (cusno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS' });
        var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        fetch(apiurl + 'inet_website_product_lookup', {
            method: 'GET',
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors != '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
    };
};

export const updateCustomer = (cusno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS' });
        var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        fetch(apiurl + 'inet_website_product_lookup', {
            method: 'GET',
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors != '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
    };
};

export const deleteCustomer = (cusno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS' });
        var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        fetch(apiurl + 'inet_website_product_lookup', {
            method: 'GET',
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors != '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
    };
};