import React, { useState } from 'react'
import { BASE_URL, FROG_AUTH, Base64 } from './../../constants'
import useSWR from 'swr';
import { ResponsivePie, ResponsivePieCanvas } from '@nivo/pie'
import {
    Result,
    Tabs,
    Row,
    Col
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import FrogHeatMap from '../maps/FrogHeatMap'
import { Pie } from '@ant-design/charts';

const { TabPane } = Tabs;

const dashboardAPI = BASE_URL + 'dashboard?type=realtimesales';

const fetchSales = async () => {
    //console.log('start employee fetch');
    return await fetch(dashboardAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}


const onPieClick = (data) => {
    console.log(data);
}

const RealtimeSales = props => {
    //const [action] = useState(props);
    //const [data, setData] = useState([]);
    const [geoflag, setGeoFlag] = useState(false);
    const { data: salesData } = useSWR(dashboardAPI, fetchSales);

    /*
    useEffect(() => {
        
        
        setData(props.employeeList);
        //if (data.length === 0) {fetchEmployees();}
        console.log(props);
    }, [data])
    *
   onPieClick = (params) => {
    console.log('onPieClick',params);
};*/

    let pieData = [];
    if (salesData !== undefined) {

        for (let i = 0; i < salesData.length; i++) {
            let dollars = salesData[i].sales_dollars;
            if (dollars === '') { dollars = 0; }
            dollars = parseFloat(dollars);
            if (dollars > 0) {
                let store = {
                    "id": salesData[i].store,
                    "value": dollars,
                    "geoData": salesData[i].sale_geo_codes.map(({ lat, lng }) => ({ lat: parseFloat(lat), lng: parseFloat(lng) }))//salesData[i].sale_geo_codes
                };
                pieData.push(store);
            }
        }
    }
    //console.log(pieData);
    if (pieData.length === 0) {
        return (
            <>
                <Result title="No sales today." />
            </>
        )
    }
    /*
    if (geoflag) {
        return (
            <div style={{ height: 400 }}>
                <FrogHeatMap center={pieData[0].geoData[0]} zoom={11} positions={pieData[0].geoData} />
            </div>
        )
    }
    */
    console.log('piedata', pieData);
    const demodata = [
        {
          type: '分类一',
          value: 27,
        },
        {
          type: '分类二',
          value: 25,
        },
        {
          type: '分类三',
          value: 18,
        },
        {
          type: '分类四',
          value: 15,
        },
        {
          type: '分类五',
          value: 10,
        },
        {
          type: '其他',
          value: 5,
        },
      ];
    const config = {
        appendPadding: 10,
        demodata,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };
    return (
        <Row gutter={8} style={{ height: 600 }} >
            <Col xs={24} md={12} style={{ height: 600 }}>
                <Pie {...config} />
                {/*
                <ResponsivePie
                    data={pieData}
                    margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
                    innerRadius={0.25}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{ scheme: 'red_yellow_green' }}
                    enableRadialLabels={false}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor={{ from: 'color' }}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#333333"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    onClick={(data) => onPieClick(data)}

                />
                */}
            </Col>
            <Col xs={24} md={12} style={{ height: 600 }}>
                <div style={{ height: 400 }}>
                    <FrogHeatMap center={pieData[0].geoData[0]} zoom={11} positions={pieData[0].geoData} />
                </div>
            </Col>
        </Row>
    )
    /*
    return (
        <Tabs defaultActiveKey="1" >
            <TabPane tab="Graph" key="1">
                <div style={{ height: 400 }}>
                    <ResponsivePie
                        data={pieData}
                        margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
                        innerRadius={0.25}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{ scheme: 'red_yellow_green' }}
                        enableRadialLabels={false}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={0}
                        radialLabelsLinkDiagonalLength={16}
                        radialLabelsLinkHorizontalLength={24}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{ from: 'color' }}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor="#333333"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        onClick={(data) => onPieClick(data)}

                    />


                </div>
            </TabPane>
            <TabPane tab="Heat Map" key="2">
                <div style={{ height: 400 }}>
                    <FrogHeatMap center={pieData[0].geoData[0]} zoom={11} positions={pieData[0].geoData} />
                </div>
            </TabPane>
        </Tabs>

    )
    */
}


export default RealtimeSales;
