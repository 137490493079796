import React from 'react'
import logoLg from '../assets/FROG_Logo_Full-sm.png';
import logoSm from '../assets/FROG_Brandmark-lg.png';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const FrogLogo = props => {
    const screens = useBreakpoint();
    return (
        <div >
            {screens.xs === true ?
            <img src={logoSm} alt="F.R.O.G. Kiss" style={{ maxHeight: 40, marginTop: 0, marginLeft: 10 }} />
            :
            <img src={logoLg} alt="F.R.O.G. Kiss" style={{ maxHeight: 40, marginTop: 0, marginLeft: 10 }} />
            }
            
        </div>
    );
}

export default FrogLogo;