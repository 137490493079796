import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { frogLogin, updateUser } from './websocket/setup/actions'
import logo from './assets/FROG_Logo_Full-lg.png';
import { Form, Input, Button, Card, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const { Title } = Typography;

const SetupForm = (props) => {
    const [cvcode, setCvcode] = useState('');
    const [password, setPassword] = useState('');
    const [usernameStatus, setUserStatus] = useState('');
    const [usernameHelp, setUserHelp] = useState(null);
    const [passwordStatus, setPasswordStatus] = useState('');
    const [passwordHelp, setPasswordHelp] = useState(null);
    const [setupComplete, setSetupComplete] = useState(false);

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    useEffect(() => {

    }, []);

    const onFinish = async ({cvcode, password}) => {
        
        const setupUrl = 'https://www.furnguy.com/frogtestweb/oecgi3.exe/inet_frog_support_login?cvcode=' + cvcode + '&user_name=' + cvcode + '&user_pw=' + password + '&restsetup=1';
        console.log(setupUrl);
        
        fetch(setupUrl, {
            method: 'post',
            
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    
                } else {
                    localStorage.setItem('setup',JSON.stringify(response.restauth));
                    props.setSetup(JSON.stringify(response.restauth));
                }

            })
            .catch(error => {
                console.log(error);
            });
        /*
        props.dispatch(frogLogin(values))
            .then(() => {
                if (props.errorMessage !== null) {
                    if (typeof (props.errorMessage) == "object") {
                        if (props.errorMessage.password !== undefined) {
                            setState({
                                passwordStatus: 'error',
                                passwordHelp: props.errorMessage.password
                            });
                        } else {
                            setState({
                                usernameStatus: 'error',
                                usernameHelp: props.errorMessage.username
                            });
                        }
                    } else {

                    }
                } else {
                    console.log('call updateuser', values);
                    props.dispatch(updateUser(values));
                }
            });
            */
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    /*
    const fetchSetup = (cvcode, password) => {
        dispatch(fetchSetupStarted());
        //console.log('current state:', getState());
        const setupUrl = 'https://www.furnguy.com/frogtestweb/oecgi3.exe/inet_frog_support_login';
        axios.get(setupUrl, {
            params: {
                cvcode: cvcode,
                user_name: cvcode,
                user_pw: password
            }
        })
            .then(response => {
                if (response.status == 200) {
                    //console.log(response.data.payload);
                    if (response.data.errors) {
                        dispatch(fetchSetupFailed(response.data.errors));
                    } else {
                        //console.log(response)
                        if (resetFlag === true) {
                            dispatch(resetApp());
                            setResetFlag(false);
                            setCvcode("");
                            setPassword("");
                            setIsReset(true);
                            //props.navigation.navigate('Loading');
                        } else {
                            AsyncStorage.setItem('apiUrl', response.data.apiurl);
                            dispatch(fetchSetupSuccess(response.data.apiurl));
                            props.navigation.navigate('Login');
                        }
                    }
                } else {

                }
            })
            .catch(error => {
                throw (error);
            });

    };  
*/
    return (
        <Card
            cover={<img alt="Keep it simple system" src={logo} style={{ maxWidth: '400px', marginTop: 20, marginLeft: 20, marginRight: 20 }} />}
            style={{ boxShadow: '0 0 5px #001529' }}
        >
            <Title level={3} style={{ textAlign: "center" }}>Dashboard Setup</Title>
            <br />
            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Company Code"
                    name="cvcode"
                    validateStatus={usernameStatus}
                    help={usernameHelp}
                    rules={[
                        {
                            required: true,
                            message: "Username is required!",
                        },
                    ]}
                >
                    <Input allowClear prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Company Code" style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    validateStatus={passwordStatus}
                    help={passwordHelp}
                    rules={[
                        {
                            required: true,
                            message: "Password is required!",
                        },
                    ]}
                >
                    <Input.Password allowClear prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    {props.fetchingLogin ?
                        <Button type="primary" htmlType="submit" loading>
                            Login
                        </Button>
                        :
                        <Button type="primary" htmlType="submit" >
                            Login
                        </Button>
                    }
                </Form.Item>
            </Form>
        </Card>
    );

}

export default SetupForm;
