const salesEntry = (state = {
    saorec: {
        customer_number: null,
        store_number: null,
        sale_type: 1,
        sale_date: new Date(),
        sku_number: [],
        mfg_stock_number: [],
        qty: [],
        image: [],
        location: [],
        dept: [],
        retail: []
    },
    adding: false,
    added: false,
    error: null
}, action) => {
    ////console.log(action.type,action.payload);
    switch (action.type) {
        case 'ADD_CART':
            return { ...state, added: true, saorec: action.payload }
        case 'ADD_CART_FAIL':
            return { ...state, adding: false, error: action.payload.data }
        case 'ADD_CART_SUCCESS':
            return {
                ...state,
                adding: false,
                added: true,
                menu: action.payload.data.menus
            }

        default:
            return state
    }
}

export default salesEntry