import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { List } from 'antd';

// CONSTANTS
//import { Styles, UI } from '../../constants';

// COMPONENTS

// The message history list
class MessageHistory extends Component {
    constructor(props) {
        super(props);
        this.messagesEnd = React.createRef();
    }

    //scrollToBottom = () => ReactDOM.findDOMNode(this.messagesEnd.current).scrollIntoView({ behavior: 'smooth' });

    componentDidUpdate() {
        //const messages = this.getMessages();
        //if (this.props.connected && messages.length) this.scrollToBottom();
    }

    getMessages = () => {
        return (this.props.threads[this.props.recipient]) ? this.props.threads[this.props.recipient] : [];
    };

    render() {
        const messages = this.getMessages();
        console.log('messages',this.props.threads[this.props.recipient]);
        return (this.props.connected && this.props.recipient !== null)
            ? <List
            itemLayout="horizontal"
            dataSource={messages}
            renderItem={message => (
              <List.Item>
                <List.Item.Meta
                  //avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                  //title={<span>{item.from}</span>}
                  description={message.text}//{item.text.memo}
                />
              </List.Item>
            )}
          />
            : null;
    }
}

// Map required state into props
const mapStateToProps = (state) => ({
    connected: state.socketState.connected,
    user: state.messageState.user,
    threads: state.messageState.threads,
    recipient: state.messageState.recipient,
    unread: state.messageState.unread,
});

// Export props-mapped HOC
export default connect(mapStateToProps)(MessageHistory);
