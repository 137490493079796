
import { Base64, BASE_URL, FROG_AUTH } from './../../constants';
//const apiurl = BASE_URL + 'tar';


// Action creators store.dispatch actions
export const reportTar = (values) => {
    const setuprec = localStorage.getItem('setup');
    return (dispatch, getState) => {
        if (values.type === undefined) { values.type = 'written' }
        dispatch({ type: 'FETCH_TAR', payload: values });

        if (setuprec === null) {
            dispatch({ type: 'FETCH_TAR_FAIL', payload: 'Invalid setup' });
        } else {
            //var formData = new FormData();
            //formData.append('searchkey', searchkey);
            //formData.append('apikey', apikey);
            //let headers = new Headers();
            //headers.append({"Authorization": 'Basic '+Base64.btoa(FROG_AUTH)});
            const startdate = values.daterange[0].format('MM-DD-YY');
            const enddate = values.daterange[1].format('MM-DD-YY');
            const type = values.type;

            let setupobj = JSON.parse(setuprec);
            let baseurl = setupobj.url;
            let frogauth = setupobj.username + ':' + setupobj.password;
            let apiurl = baseurl + '/tar';
            let queryparams = '?type=' + type + '&startdate=' + startdate + '&enddate=' + enddate + '&forcebyline=1';
            return fetch(apiurl + queryparams, {
                method: 'get',
                headers: new Headers({
                    "Authorization": "Basic " + Base64.btoa(frogauth)
                }),
                // body: formData
            }).then(
                response => {
                    // if the response is a JSON object
                    return response.json();
                }).then(response => {
                    //console.log(response);
                    if (response.errors !== undefined && response.errors !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        dispatch({ type: 'FETCH_TAR_FAIL', payload: response.errors });
                    } else {
                        //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                        //dispatch(fetchTruckSuccess(response));
                        dispatch({ type: 'FETCH_TAR_SUCCESS', payload: response });
                    }

                })
                .catch(error => {
                    //throw(error);
                    //console.log('recapbydept catch',error);
                    ////console.log(error);
                    //dispatch(fetchTruckFailed(error));
                    dispatch({ type: 'FETCH_TAR_FAIL', payload: error });
                });
        }
    };
};

