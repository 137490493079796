const frogCall = (state = {
    calls: [],
    fetching: false,
    fetched: false,
    error: null,
    callnumber: '',
    call_record: {
        id: '',
        date_entered: '',
        time_entered: '',
        customer_number: '',
        who_called: '',
        type: '',
        assigned_to: '',
        date_changed: '',
        time_changed: '',
        summary: '',
        memo: [],
        who_took: '',
        priority: '',
        cv_code: '',
        completed: '',
        estimate: '',
        followup: '',
        instructions: '',
        email: '',
        reportable: '',
        menu: '',
        callback_phone: '',
        attachments: [],
    },
    fields: [],
    newrecord: null,
    employee: '',
    type: '',
    lookup_updated: null,
}, action) => {
    //console.log(action.type,action.payload);
    switch (action.type) {
        case 'SAVE_CALL_FIELDS':
            return { ...state, fields: action.payload }
            //return { ...state, call_record: action.payload }
        case 'SET_CALL_NUMBER':
            return { 
                ...state, 
                callnumber: action.payload 
            }
        case 'ADD_NEW_CALL':
            return { 
                ...state, 
                calls: action.payload 
            }
        case 'FETCH_CALLS':
            return { 
                ...state, 
                fetching: true,
                employee: action.payload.employee,
                type: action.payload.type 
            }
        case 'FETCH_CALLS_FAIL':
            return { ...state, fetching: false, error: action.payload }
        case 'FETCH_CALLS_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                calls: action.payload.results,
                lookup_updated: action.payload.last_updated
            }
        case 'EDIT_CALL':
            return { ...state, fetching: true, newrecord: null }
        case 'EDIT_CALL_FAIL':
            return { ...state, fetching: false, error: action.payload }
        case 'EDIT_CALL_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                call_record: action.payload
            }
        case 'SAVE_CALL':
            return { 
                ...state, 
                fetching: true,
                newrecord: null
            }
        case 'SAVE_CALL_FAIL':
            return { 
                ...state, 
                fetching: false, 
                error: action.payload.message,
                newrecord: action.payload.newrecord,
            }
        case 'SAVE_CALL_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                newrecord: null,
                call_record: action.payload
            }

        default:
            return state
    }
}

export default frogCall