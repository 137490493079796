
import { Base64, BASE_URL, FROG_AUTH } from '../../constants';
const apiurl = BASE_URL + 'frog_calls';
const apikey = 'AD8C0721626A48AE8C82434CA71F4835';

var dayjs = require('dayjs');

// Synchronus actions
export const setCallNumber = (callnumber) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_CALL_NUMBER', payload: callnumber });
    };
};
export const saveFields = (fields) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SAVE_CALL_FIELDS', payload: fields });
    };
};
export const saveCallFields = (field) => {
    return (dispatch, getState) => {
        //let newrecord = {};
        let newrecord = Object.assign(getState().frogCall.call_record, {});
        let objkey = Object.keys(field)[0]
        newrecord[objkey] = field[objkey].value;
        ////console.log('savefields '+objkey,field[objkey].value);
        //dispatch({ type: 'SAVE_CALL_FIELDS', payload: newrecord });
    };
};
export const newCall = (callObj) => {
    return (dispatch, getState) => {
        let callList = Object.assign(getState().frogCall.calls, []);
        callList.push(callObj);
        dispatch({ type: 'ADD_NEW_CALL', payload: callList });
    };
};


// Asynchronus actions
export const getCalls = (params) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_CALLS', payload: params });
        let whotook = '';
        let assignedto = '';
        if (params.type === 'assigned') {
            assignedto = params.employee;
        } else {
            whotook = params.employee;
        }
        let queryparams = '?whotook=' + whotook + '&assignedto=' + assignedto + '&callno=&cusno=';
        return fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_CALLS_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    let results = {};
                    results.results = response;
                    results.last_updated = dayjs().format('MM/DD/YY  h:mm:ss');
                    dispatch({ type: 'FETCH_CALLS_SUCCESS', payload: results});
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('getcalls catch', error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
            });
    };
};

export const editCall = (callno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'EDIT_CALL' });
        if (callno === '') {
            dispatch({ type: 'EDIT_CALL_FAIL', payload: 'Call Number Required!' });
            return Promise.resolve()
        } else {
            let queryparams = '?callno=' + callno;
            return fetch(apiurl + queryparams, {
                method: 'get',
                headers: new Headers({
                    "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
                }),
                // body: formData
            })
                .then(
                    response => response.json(),
                    // Do not use catch, because that will also catch
                    // any errors in the dispatch and resulting render,
                    // causing a loop of 'Unexpected batch number' errors.
                    // https://github.com/facebook/react/issues/6895
                    error => dispatch({ type: 'EDIT_CALL_FAIL', payload: error })
                )
                .then(response => {
                    ////console.log(response);
                    if (response.errors !== undefined && response.errors !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        dispatch({ type: 'EDIT_CALL_FAIL', payload: response.errors });
                    } else {
                        //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                        //dispatch(fetchTruckSuccess(response));
                        dispatch({ type: 'EDIT_CALL_SUCCESS', payload: response });
                    }

                })
        };
    };
}

export const saveCall = (formData) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SAVE_CALL' });

        let newmemo = formData.memo.split("\n"); //.replace( /[\r\n]+/g, "\n")
        formData.memo = newmemo;

        //let formData = getState().frogCall.call_record;
        //if (formData.id === '') {
        //    dispatch({ type: 'SAVE_CALL_FAIL', payload: 'Call Number Required!' });
        //    return Promise.resolve()
        //} else {
            let method = 'put';
            if (formData.id === '') { method = 'post'; }
            return fetch(apiurl, {
                method: method,
                headers: new Headers({
                    "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
                }),
                body: JSON.stringify(formData),
            })
                .then(
                    response => response.json(),
                    // Do not use catch, because that will also catch
                    // any errors in the dispatch and resulting render,
                    // causing a loop of 'Unexpected batch number' errors.
                    // https://github.com/facebook/react/issues/6895
                    error => dispatch({ type: 'SAVE_CALL_FAIL', payload: error })
                )
                .then(response => {
                    ////console.log(response);
                    if (response.ErrorMessage !== undefined && response.ErrorMessage !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        dispatch({ type: 'SAVE_CALL_FAIL', payload: response.ErrorMessage });
                    } else if (response.newrecord !== undefined && response.newrecord !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        dispatch({ type: 'SAVE_CALL_FAIL', payload: response});
                    } else {
                        //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                        //dispatch(fetchTruckSuccess(response));
                        dispatch({ type: 'SAVE_CALL_SUCCESS', payload: response });
                    }

                })
        //};
    };
}
/*
async function fetchCall(dispatch,callno) {
    try {
        const callrec = await fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
        // body: formData
        });
 
    } catch (error) {
 
    }
}
*/
export const createCustomer = (cusno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS' });
        var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        fetch(apiurl + 'inet_website_product_lookup', {
            method: 'GET',
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
    };
};

export const updateCustomer = (cusno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS' });
        var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        fetch(apiurl + 'inet_website_product_lookup', {
            method: 'GET',
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
    };
};

export const deleteCustomer = (cusno) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS' });
        var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        fetch(apiurl + 'inet_website_product_lookup', {
            method: 'GET',
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
    };
};