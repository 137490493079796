const salesUpdate = (state = {
    saorec: {
        customer_number: null,
        store_number: null,
        sale_type: 1,
        sale_date: new Date(),
        sku_number: [],
        mfg_stock_number: [],
        qty: [],
        image: [],
        location: [],
        dept: [],
        retail: []
    },
    adding: false,
    added: false,
    error: null
}, action) => {
    ////console.log(action.type,action.payload);
    switch (action.type) {
        case 'FETCH_INVOICE':
            return { ...state, added: true }
        case 'FETCH_INVOICE_FAIL':
            return { ...state, adding: false, error: action.payload }
        case 'FETCH_INVOICE_SUCCESS':
            return {
                ...state,
                adding: false,
                added: true,
                saorec: action.payload.sales_orders
            }

        default:
            return state
    }
}

export default salesUpdate