import React from 'react'
import {
    Form,
    Select,
} from 'antd';

const { Option } = Select;

let collections = ["Coll1","Jason","Lisa","Test","Help"];

class CollectionSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '',
            includeAll: false,
            selectedCollections: [],
        }
    }
    
    componentDidMount() {
        //console.log('select props',this.props);
        if (this.props.hasOwnProperty('multiple')) {
            this.setState({ mode: 'multiple' });
        }
        if (this.props.hasOwnProperty('includeall')) {
            this.setState({ includeAll: true });
            collections.unshift('ALL');
        }
    }


    handleChange = (value) => {
        //console.log(`selected ${value}`);
        this.setState({selectedCollections: value});
    }
    render() {
        const children = [];
      
        for (let i = 0; i < collections.length; i++) {
            children.push(<Option key={collections[i]} value={collections[i]}>{collections[i]}</Option>);
        }
        return (
            <Form.Item label={this.props.label} name={this.props.name} rules={this.props.rules}>
            <Select
                mode={this.state.mode}
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={[]}
                onChange={this.handleChange}
            >
                {children}
            </Select>
            </Form.Item>
        );
    }
}
export default CollectionSelect;