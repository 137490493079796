
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import dayjs from 'dayjs'
import { BASE_URL, FROG_AUTH,  Base64 } from '../constants'
import {
    Avatar,
    Select,
    Row,
    Col,
    Badge,
    Popover,
    Menu,
    Dropdown,
    List,
    Button,
    Grid,
    notification
} from 'antd';
import {
    BellOutlined, EditOutlined, UserOutlined, StarOutlined,
    StarFilled, PoweroffOutlined, MessageOutlined
} from '@ant-design/icons';
import FrogLogo from './FrogLogo'
import { setCallNumber } from '../sales/frog_calls/actions';
import { frogLogout, FROG_LOGOUT } from '../websocket/setup/actions';
import { abandonChat, sendMessage, userChanged, recipientChanged } from '../websocket/message/actions';
import InstantMessage from '../components/InstantMessage';
import { MessageContext } from '../hooks/messageContext'

const { Option } = Select;
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;



const data = [
    'Racing car sprays burning fuel into crowd.',
    'Japanese princess to wed commoner.',
    'Australian walks 100km after outback crash.',
    'Man charged over missing wedding girl.',
    'Los Angeles battles huge wildfires.',
];


const hide = () => {
    this.setState({
        visible: false,
    });
};

const handleVisibleChange = visible => {
    this.setState({ visible });
};

const handleEmployeeVisibleChange = employeeVisible => {
    this.setState({ employeeVisible });
};

const edit = (key, props) => {
    //let history = useHistory();
    console.log('edit call', key);
    props.setCallNumber(key);
    //props.dispatch(setCallNumber(key));
    //        .then(() => {
    props.history.push('/call');
    //      });
};

const onLogoffClick = (props) => {
    props.frogLogout();
    //props.dispatch({ type: FROG_LOGOUT })
};


const renderFavoritesMenu = (employeeFavorites) => {
    return (
        <Menu>
            {employeeFavorites.map(item =>
                <Menu.Item key={item.name}>
                    <NavLink
                        to={item.path}
                    >
                        <span className="nav-text">{item.name}</span>
                    </NavLink>
                </Menu.Item>
            )}

        </Menu>
    )
}

const renderPopover = (newcalls, props) => {
    return (
        <List
            dataSource={newcalls}
            pagination={true}
            size="small"
            renderItem={item => (
                <List.Item
                    actions={[<a onClick={() => edit(item.id, props)}>
                        <EditOutlined />
                    </a>]}
                >
                    {item.memo}
                </List.Item>
            )}
        />
    );
}



const renderEmployeePopover = (props) => {
    return (
        <List>
            <List.Item>
                <Button type="primary" danger block onClick={() => onLogoffClick(props)} icon={<PoweroffOutlined />}>
                    Logoff
                </Button>
            </List.Item>
        </List>
    );
}

const GlobalHeaderRight = props => {
    const [api, contextHolder] = notification.useNotification();
    const [visible, setVisible] = useState(false);
    const [employeeVisible, setEmployeeVisible] = useState(false);
    const [frogcalls, setFrogCalls] = useState(props.calls);

    useEffect(() => {


        setFrogCalls(props.calls);
        //if (data.length === 0) {fetchEmployees();}
        console.log('HEADERPROPS', props);
    }, [props.calls]);

/*
    const openMessages = (threads) => {
        console.log('open messages', threads);
        
        const args = {
            message: 'Message',
            description: <InstantMessage employeeList={props.employeeList} sendMessage={props.sendMessage} recipientChanged={props.recipientChanged} threads={threads} />,
            duration: 0,
        };
        notification.open(args);
        
        
    };
    */
    const openMessages = (threads) => {
        //console.log('open messages',threads);
        api.open({
            //message: 'Send Message',
            description: <MessageContext.Consumer>{({ employeeList, threads }) => <InstantMessage employeeList={employeeList} sendMessage={props.sendMessage} recipientChanged={props.recipientChanged} threads={threads} />}</MessageContext.Consumer>,
            duration: 0,
        });
    };

    let filtcalls = props.calls.filter(call => call.date_entered == dayjs('MM-DD-YY'));
    //console.log('thread header', props.threads);
    return (

        <Row justify="end">
            <MessageContext.Provider value={{ employeeList: props.employeeList, threads: props.threads }}>{contextHolder}</MessageContext.Provider>
            <Col xs={12} md={19}>
                <FrogLogo />
            </Col>
            <Col xs={6} md={2}></Col>
            <Col xs={3} md={1}>
                {props.employeeFavorites.length > 0 ?
                    <Dropdown overlay={() => renderFavoritesMenu(props.employeeFavorites)}>
                        <StarFilled style={{ fontSize: 20, color: '#52c41a' }} />
                    </Dropdown>
                    :
                    <StarOutlined style={{ fontSize: 20, color: 'red' }} />
                }

            </Col>
            
            <Col xs={3} md={2}>
                <Popover
                    content={() => renderEmployeePopover(props)}
                    title={props.employeeName}
                    trigger="click"
                    placement="bottom"
                    visible={employeeVisible}
                    onVisibleChange={(employeeVisible) => setEmployeeVisible(employeeVisible)}
                >
                    <Avatar
                        style={{ backgroundColor: '#87d068' }}
                        icon={<UserOutlined />}
                        size="default"
                    />
                </Popover>
            </Col>
        </Row>
    );

};

const mapStateToProps = store => {
    return {
        calls: store.frogCall.calls,
        employeeName: store.setupState.employeeName,
        employeeFavorites: store.setupState.employeeFavorites,
        unread: store.messageState.unread,
        employeeList: store.messageState.users,
        threads: store.messageState.threads,
    }
};

const mapDispatchToProps = (dispatch) => ({
    recipientChanged: recipient => dispatch(recipientChanged(recipient)),
    sendMessage: message => dispatch(sendMessage(message)),
    frogLogout: () => dispatch(frogLogout()),
    setCallNumber: key => dispatch(setCallNumber(key)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalHeaderRight));
