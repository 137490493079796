
import { BASE_URL, FROG_AUTH,  Base64 } from '../../constants'
import { searchInventory } from '../../actions/skuActions'

var dayjs = require('dayjs');

const apiurl = 'https://www.furnguy.com/frogtestweb/oecgi3.exe/';
const apikey = 'AD8C0721626A48AE8C82434CA71F4835';
const skuAPI = BASE_URL + 'mfgl?type=mfgl';

// Action creators store.dispatch actions
export const searchProduct = (values) => {
    let searchkey = values.searchkey;
    let instockonly = values.inStockOnly;
    let includeGroups = values.includeGroups;
    let statusCodes = values.statusCodes;
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_RESULTS', payload: values });
        var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        //return fetch(apiurl + 'inet_website_product_lookup', {
        /*
        let response = searchInventory(values);
        if (response.errors === undefined) {
            dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });

        } else {
            dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
        }
        */
        let url = skuAPI + '&searchkey=' + searchkey + '&instockonly=' + instockonly;
        return fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            //method: 'POST', 
            //body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                ////console.log(response.errors);
                if (response.errors !== undefined) {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_RESULTS_FAIL', payload: response.errors.system });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    response.last_updated = dayjs().format('MM/DD/YY  h:mm:ss'); 
                    dispatch({ type: 'FETCH_RESULTS_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                ////console.log('fetchtruck catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_RESULTS_FAIL', payload: error });
            });
            
    };
};