import { Base64, BASE_URL, FROG_AUTH } from '../../../constants';

// Message related actions
export const EMPLOYEE_LIST_CHANGED = 'setup/employee-list-changed';
export const STATUS_LIST_CHANGED = 'setup/status-list-changed';
export const DEPT_LIST_CHANGED = 'setup/dept-list-changed';
export const FROG_LOGIN = 'setup/frog-login';
export const FROG_LOGIN_SUCCESS = 'setup/frog-login-success';
export const FROG_LOGIN_FAIL = 'setup/frog-login-fail';
export const FROG_LOGOUT = 'setup/frog-logout';

// Testing employee changes
export const loadEmployees = (user, port) => {
    return {
        type: 'LOAD_EMPLOYEES'
    };
};

export const employeeListChanged = employeeList => {
    return {
        type: EMPLOYEE_LIST_CHANGED,
        employeeList: employeeList
    };
};

export const depgListChanged = deptList => {
    return {
        type: DEPT_LIST_CHANGED,
        deptList: deptList
    };
};

export const statusListChanged = statusList => {
    return {
        type: STATUS_LIST_CHANGED,
        statusList: statusList
    };
};

export const frogLogout = () => {
    localStorage.removeItem('account');
    localStorage.removeItem('sales_record');
    localStorage.removeItem('location_record');
    return {
        type: FROG_LOGOUT
    };
};

// Asynchronus actions
export const frogLogin = (params) => {
    const setuprec = localStorage.getItem('setup');
    return (dispatch, getState) => {
        dispatch({ type: FROG_LOGIN, payload: params });

        if (setuprec === null) {
            dispatch({ type: FROG_LOGIN_FAIL, payload: "Setup record NOT found." });
        } else {
            let setupobj = JSON.parse(setuprec);
            let baseurl = setupobj.url;
            let frogauth = setupobj.username + ':' + setupobj.password;
            let apiurl = baseurl + '/frog_login'; // frog login api
            // console.log(frogauth,apiurl);
            let queryparams = '?username=' + params.username + '&password=' + params.password;
            return fetch(apiurl + queryparams, {
                method: 'post',
                headers: new Headers(
                    {
                        "Authorization": "Basic " + Base64.btoa(frogauth)
                        //"Authorization": "Basic " + Base64.btoa(FROG_AUTH)
                    }),
                // body: formData
            }).then(
                response => {
                    // if the response is a JSON object
                    return response.json();
                }).then(response => {
                    ////console.log(response);
                    if (response.ErrorMessage !== undefined && response.ErrorMessage !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        dispatch({ type: FROG_LOGIN_FAIL, payload: response.ErrorMessage });
                    } else {
                        localStorage.setItem('sales_record',JSON.stringify(response.sales_record));
                        localStorage.setItem('location_record',JSON.stringify(response.location_record));
                        dispatch({ type: FROG_LOGIN_SUCCESS, payload: response });
                    }

                })
                .catch(error => {
                    //throw(error);
                    //console.log('getcalls catch', error);
                    ////console.log(error);
                    //dispatch(fetchTruckFailed(error));
                    dispatch({ type: FROG_LOGIN_FAIL, payload: error });
                });
        }
    };
};

export const verifyLogin = () => {
    const setuprec = localStorage.getItem('setup');
    const account = JSON.parse(localStorage.getItem('account'))

    return (dispatch, getState) => {
        dispatch({ type: FROG_LOGIN, payload: account });
        if (setuprec === null) {
            dispatch({ type: FROG_LOGIN_FAIL, payload: "Setup record NOT found." });
        } else {
            let setupobj = JSON.parse(setuprec);
            let baseurl = setupobj.url;
            let frogauth = setupobj.username + ':' + setupobj.password;
            let apiurl = baseurl + '/frog_login'; // frog login api
            let queryparams = '?username=' + account.username + '&password=' + account.password;
            return fetch(apiurl + queryparams, {
                method: 'post',
                headers: new Headers(
                    {
                        "Authorization": "Basic " + Base64.btoa(frogauth)
                        //"Authorization": "Basic " + Base64.btoa(FROG_AUTH)
                    }),
                // body: formData
            }).then(
                response => {
                    // if the response is a JSON object
                    return response.json();
                }).then(response => {
                    ////console.log(response);
                    if (response.ErrorMessage !== undefined && response.ErrorMessage !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        dispatch({ type: FROG_LOGIN_FAIL, payload: response.ErrorMessage });
                        localStorage.removeItem('account');
                        localStorage.removeItem('sales_record');
                        localStorage.removeItem('location_record');
                    } else {
                        localStorage.setItem('sales_record',JSON.stringify(response.sales_record));
                        localStorage.setItem('location_record',JSON.stringify(response.location_record));
                        dispatch({ type: FROG_LOGIN_SUCCESS, payload: response });
                    }
                })
                .catch(error => {
                    dispatch({ type: FROG_LOGIN_FAIL, payload: error });
                    localStorage.removeItem('account')
                    localStorage.removeItem('sales_record');
                    localStorage.removeItem('location_record');
                });
        }
    };
}

export const updateUser = (account) => {
    localStorage.setItem('account', JSON.stringify(account))
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_USER',
            payload: JSON.parse(localStorage.getItem('account'))
        });
    }
}
