import { 
    ADD_USER, 
    ADD_USER_FAILURE,
    ADD_USER_SUCCESS,
    EDIT_USER,
    EDIT_USER_FAILURE,
    EDIT_USER_SUCCESS,
    FETCH_USERS, 
    FETCH_USERS_FAILURE, 
    FETCH_USERS_SUCCESS, 
    FETCH_USER_BY_ID, 
    FETCH_USER_BY_ID_FAILURE, 
    FETCH_USER_BY_ID_SUCCESS 
} from './types';

import { BASE_URL, FROG_AUTH,  Base64 } from '../constants'

const apiurl = 'https://www.furnguy.com/frogtestweb/oecgi3.exe/';
const apikey = 'AD8C0721626A48AE8C82434CA71F4835';
const skuAPI = BASE_URL + 'mfgl?type=mfgl';

//const apiUrl = 'http://192.168.1.149/rest/users';
//const apiUrl = BASE_URL + '/api';


export const searchInventory = async (values) => {
    let searchkey = values.searchkey;
    let instockonly = values.inStockOnly;
    let includeGroups = values.includeGroups;
    let statusCodes = values.statusCodes;
    let url = skuAPI + '&searchkey=' + searchkey + '&instockonly=' + instockonly;
    return fetch(url, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        //method: 'POST', 
        //body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        }).then(response => {
            ////console.log(response.errors);
            return response;

        })
        .catch(error => {
            //throw(error);
            ////console.log('fetchtruck catch',error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            let resp = {
                "errors":error
            };
            return resp;
        });
    
};