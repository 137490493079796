import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { getMonthData } from './actions'
import { Calendar, Modal, Descriptions, Skeleton, Input } from 'antd';
import Draggable from 'react-draggable';
import { contentDiv } from './../../constants';
import FavoritesIcon from './../../components/FavoritesIcon';
import '@ant-design/compatible/assets/index.css';

const { TextArea } = Input;

var dayContext = React.createContext(null);

const EditDay = (props) => {

}

const FROGCalendar = (props) => {
    const [data, setData] = useState([]);
    const [wdate, setDate] = useState(dayjs().format('MM-DD-YY'));
    const [loading, setLoading] = useState(true);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const [visible, setVisible] = useState(false);
    const [dragdisabled, setDragDisabled] = useState(true);
    const [editdate, setEditDate] = useState('');

    useEffect(() => {
        console.log(wdate);
        (async () => {
            let days = await getMonthData(wdate);
            //console.log('days', days);
            setData(days);
            setLoading(false);
        })()
    }, [wdate]);
    /*
        useEffect(() => {
           console.log('wdate',wdate);
        }, [wdate]);*/

    

    let draggleRef = React.createRef();

    const EditData = (value) => {
        let today = data.filter(dobj => { return dobj.date === editdate });
        //console.log('datecellrender',today);
        let listData = [];
        if (today.length > 0) {
            listData = today[0].data; //getListData(value);
        }

        if (editdate === '') {
            return (
                <div>loading</div>
            )
        }
        listData.join("\n");
        return (
            <>
                <TextArea value={listData} />
            </>
        )
    };

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = e => {
        console.log(e);
        setVisible(false);
    };

    const handleCancel = e => {
        console.log(e);
        setVisible(false);
    };

    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setBounds({
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y),
            });
    };

    const onSelect = value => {
        setEditDate(value.format('MM-DD-YYYY'));
        showModal();
    };

    const monthCellRender = (value) => {
        const num = '';//getMonthData2(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };

    const dateCellRender = (value) => {
        let today = data.filter(dobj => { return dobj.date == value.format('MM-DD-YYYY') });
        //console.log('datecellrender',today);
        let listData = [];
        if (today.length > 0) {
            listData = today[0].data; //getListData(value);
        }
        //console.log(listData);
        let keyval = today.date;
        return (
            <div>
                {listData.map((item, i) => (
                    <div key={keyval + '-' + i}>
                        {item}
                        <br />
                    </div>
                ))}
            </div>
        );
        /*
        return (
            <ul className="events">
                {listData.map((item,i) => (
                    <li key={keyval +'-'+ i}>
                        <Badge  text={item} />
                    </li>
                ))}
            </ul>
        );
        */
    }

    const onPanelChange = (value, mode) => {
        setEditDate('');
        if (mode === 'month') {
            //console.log('panel change', value.format('MM-DD-YY'));
            setDate(value.format('MM-DD-YY'));
        }
    };


    if (loading) {
        return (
            <div style={contentDiv}>
                <Skeleton />
            </div>
        );
    } else {
        return (
            <div style={contentDiv}>
                <Descriptions
                    title={<div><FavoritesIcon name="Calendar" path="/utilities/calendar" /> Calendar</div>}
                />

                <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} onSelect={onSelect} onPanelChange={onPanelChange} />

                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() => {
                                if (dragdisabled) {
                                    setDragDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                setDragDisabled(true);
                            }}
                            // fix eslintjsx-a11y/mouse-events-have-key-events
                            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                            onFocus={() => { }}
                            onBlur={() => { }}
                        // end
                        >
                            Edit {editdate}
                        </div>
                    }
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    modalRender={modal => (
                        <Draggable
                            disabled={dragdisabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}
                        >
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                >
                    <EditData />
                </Modal>
            </div>
        );
    }
    /*
    return (
        <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} onSelect={onSelect} onPanelChange={onPanelChange} />
        
    );
    */
}

const mapStateToProps = store => ({
    calendar: store.frogCalendar.calendar,
    fetching: store.frogCalendar.fetching,
    fetched: store.frogCalendar.fetched,
});
export default connect(mapStateToProps)(FROGCalendar)