import React, { useState, useEffect } from 'react'
import useSWR from 'swr';
import { BASE_URL, FROG_AUTH,  Base64 } from '../constants'
import {
    Form,
    Select,
} from 'antd';

const { Option } = Select;


const CallTypeAPI = BASE_URL + 'call_type?key=ALL';

const fetchCallTypes = async () => {
    return await fetch(CallTypeAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}

const CallTypeSelect = (props) => {
    const [mode, setMode] = useState(props.mode);
    const [label, setLabel] = useState(props.label);
    const [name, setName] = useState(props.name);
    const [rules, setRules] = useState(props.rules);
    const [includeAll, setIncludeAll] = useState(props.includeall);
    //const [data, setData] = useState([]);
    const { data: CallTypes } = useSWR(CallTypeAPI, fetchCallTypes);

    if (includeAll && CallTypes !== undefined) {
        CallTypes.unshift({ "code": "ALL", "name": "ALL", "questions": [] });
    }

    return (
        <Form.Item label={label} name={name} rules={rules}>
            <Select
                mode={mode}
                style={{ width: '100%' }}
                placeholder="Please select"
            //defaultValue={[]}
            //onChange={this.handleChange}
            >
                {
                    CallTypes && (
                        CallTypes.map(Call => <Option key={Call.code} value={Call.code}>{Call.name}</Option>)
                    )
                }
            </Select>
        </Form.Item>
    );
}


export default CallTypeSelect;

/*
let Calles = ["CU","DI","DV","RL"];

class CallSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '',
            includeAll: false,
            selectedCalles: [],
        }
    }

    componentDidMount() {
        //console.log('select props',this.props);
        if (this.props.hasOwnProperty('multiple')) {
            this.setState({ mode: 'multiple' });
        }
        if (this.props.hasOwnProperty('includeall')) {
            this.setState({ includeAll: true });
            Calles.push('ALL');
        }
    }


    handleChange = (value) => {
        //console.log(`selected ${value}`);
        this.setState({selectedCalles: value});
    }
    render() {
        const children = [];

        for (let i = 0; i < Calles.length; i++) {
            children.push(<Option key={Calles[i]} value={Calles[i]}>{Calles[i]}</Option>);
        }
        return (
            <Form.Item label={this.props.label} name={this.props.name} rules={this.props.rules}>
            <Select
                mode={this.state.mode}
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={[]}
                onChange={this.handleChange}
            >
                {children}
            </Select>
            </Form.Item>
        );
    }
}
export default CallSelect;
*/