import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
//import { CSVLink } from 'react-csv'
import EmployeeSelect from '../../components/EmployeeSelect';
import ExportToCSV from './../../components/ExportToCSV'
import FavoritesIcon from './../../components/FavoritesIcon'
import { contentDiv } from './../../constants'
import { getCalls, editCall, setCallNumber, saveCall } from './actions'
import Highlighter from 'react-highlight-words'
import { EditOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Input,
    Row,
    Col,
    Button,
    Radio,
    Descriptions,
    Table,
    Modal,
    Form,
    Space
} from 'antd';
import FrogCallModal from './FrogCallModal';

const CallLookupForm = (props) => {
    const [confirmDirty, setConfirmDirty] = useState(false)
    const [mode, setMode] = useState(['month', 'month'])
    const [monthvalue, setMonthValue] = useState([])


    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };


    const handlePanelChange = (value, mode) => {
        setMonthValue(value);
        setMode([mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]);
    };

    const handleChange = value => {
        setMonthValue(value);
    };

    const onChange = e => {
        //console.log(`radio checked:${e.target.value}`);
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
            md: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    return (
        <Form {...formItemLayout} onFinish={props.onFinish}>

            <Form.Item label="Type" name="type" defaultValue="assigned">
                <Radio.Group>
                    <Radio.Button value="assigned">Assigned To</Radio.Button>
                    <Radio.Button value="took">Who Took</Radio.Button>
                </Radio.Group>
            </Form.Item>
{/*
            <EmployeeSelect label="Employee Code:" name="employee" rules={[{ required: true, message: 'Employee Code is required!' }]} />
            */}
            <Form.Item label="Employee Code:" name="employee" rules={[{ required: true, message: 'Employee Code is required!' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="Customer Number:" name="customer_number" >
                <Input />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                    Continue
                </Button>
            </Form.Item>

        </Form>
    );

}

const CallLookup = (props) => {
    const searchInput = useRef(null);
    const [confirmDirty, setConfirmDirty] = useState(false)
    const [mode, setMode] = useState(['month', 'month'])
    const [monthvalue, setMonthValue] = useState([])
    const [editMode, setEditMode] = useState(true)
    const [lookupmodalTitle, setLookupmodalTitle] = useState('Enter Search Variables')
    const [lookupmodalVisible, setLookupmodalVisible] = useState(false)
    const [editCallmodalTitle, setEditCallmodalTitle] = useState('Edit Call')
    const [editCallmodalVisible, setEditCallmodalVisible] = useState(false)
    const [lookupconfirmLoading, setLookupConfirmLoading] = useState(false)
    const [callnumber, setCallNumber] = useState(null)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loadingCalls, setLoadingCalls] = useState(false)
    const [searchedColumn,setSearchedColumn] = useState('')
    const [searchText,setSearchText] = useState('')

    useEffect(() => {
        if (props.calls.length == 0) {
            setLookupmodalVisible(true);
        }
    }, []);

    useEffect(() => {
        if (editCallmodalVisible === false) {
            onRefresh();
        }
    }, [editCallmodalVisible]);

    useEffect(() => {
        //if (props.calls.length > 0) {
        setLoadingCalls(false);
        // }
    }, [props.calls]);

    //console.log(prevProps.call_record, props.call_record);
    /*
    if (props.selectedSubreddit !== prevProps.selectedSubreddit) {
        const { dispatch, selectedSubreddit } = props
        dispatch(fetchPostsIfNeeded(selectedSubreddit))
    }*/


    const onFinish = values => {
        console.log('on finish', values);
        setLoadingCalls(true);
        props.dispatch(getCalls(values));
        setLookupmodalVisible(false);
        setLookupConfirmLoading(false)
    };

    const onRefresh = () => {
        if (props.last_updated !== null) {
            const values = {
                employee: props.employee,
                type: props.type
            };
            setLoadingCalls(true);
            props.dispatch(getCalls(values));
        }
    };

    const handleSubmit = e => {
        e.preventDefault();

        //props.form.validateFieldsAndScroll((err, values) => {
        props.form.validateFields((err, values) => {
            if (!err) {
                /*
                //console.log('Received values of form: ', values);
                const rangeValue = values['monthrange'];
                const rangevals = {
                    ...values,
                    'monthrange': [rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD')],
                }
                //console.log(rangevals);
                //props.recapByBrand(values);
                ////console.log(props.updated);
                //props.next();
                //props.form.resetFields();
                */
                ////console.log('submit values',values);
                props.dispatch(getCalls(values));

            }
        });

        // let values = '';
        //props.getCalls(values);
        // if (props.calls.length > 0) {
        //   setEditMode(false);
        //}
    };

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };




    const handleChange = value => {
        setMonthValue(value);
    };

    const onChange = e => {
        //console.log(`radio checked:${e.target.value}`);
    };

    const edit = async key => {
        //props.calls[key].id);
        let callno = props.calls[key].id;
        console.log(key, callno);
        setEditCallmodalTitle('Edit Call ' + callno)
        setCallNumber(callno)

        //showModal();

        //props.dispatch(setCallNumber(callno));
        ////console.log('before', props.call_record);
        //props.editCall(callno);
        /*
        props.dispatch(editCall(callno))
            .then(() => {
                setEditCallmodalTitle('Edit Call ' + callno)
                showModal();
            });
            */

        setEditCallmodalTitle('Edit Call ' + callno)
        showModal();
    };

    const showModal = () => {
        setEditCallmodalVisible(true)
    };

    const handleModalOk = () => {
        /*
        setState({
            modalText: 'The modal will be closed after two seconds',
            confirmLoading: true,
        });
        /*
                props.dispatch(saveCall())
                    .then(() => {
                        if (props.newrecord === null) {
                            setState({
                                editCallmodalVisible: false,
                                confirmLoading: false,
                            });
                        } else {
                            setState({
                                confirmLoading: false,
                            });
                        }
                    });
        
                /*
                setState({
                    modalText: 'The modal will be closed after two seconds',
                    confirmLoading: true,
                });
                setTimeout(() => {
                    setState({
                        modalVisible: false,
                        confirmLoading: false,
                    });
                }, 2000);
                */
    };

    const handleModalCancel = () => {
        //console.log('Clicked cancel button');
        setLookupmodalVisible(false)
    };

    const handleCallModalCancel = () => {
        setEditCallmodalVisible(false)
    };

    const renderTableFooter = (tabledata, csvHeaders) => {
        if (tabledata.length > 0) {
            return (<ExportToCSV csvData={tabledata} fileName="CallLookup.csv" headers={csvHeaders} />);
        } else if (props.fetching) {
            //return (<CSVLink data={tabledata} headers={csvHeaders} filename='CallLookup.csv'>Export</CSVLink>);
            return (<Button type="primary" loading>Loading</Button>);
        } else {
            return (<div />);
        }
    };


    //const { fields, autoCompleteResult, mode, monthvalue, editMode } = state;
    const { calls, fetching, fetched } = props;
    //const { fields } = state;

    const getColumnSearchProps = dataIndex => ({
        
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
              </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
        
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: '',
            key: 'operation',
            align: 'center',
            fixed: 'left',
            width: 10,
            render: (text, record) => {
                return (
                    <a onClick={() => edit(record.key)}>
                        <EditOutlined />
                    </a>
                );
            }
        },
        {
            title: 'Call #',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
        },
        {
            title: 'Priority',
            width: 10,
            dataIndex: 'priority',
            key: 'priority',
            sorter: (a, b) => a.priority - b.priority,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Entered',
            dataIndex: 'date_entered',
            key: 'date_entered',
            width: 15,
            sorter: (a, b) => dayjs(a.date_entered).unix() - dayjs(b.date_entered).unix(),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Changed',
            dataIndex: 'date_changed',
            key: 'date_changed',
            width: 15,
            sorter: (a, b) => dayjs(a.date_changed).unix() - dayjs(b.date_changed).unix(),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Time',
            dataIndex: 'time_changed',
            key: 'time_changed',
            width: 12,
            sorter: (a, b) => a.time_changed - b.time_changed,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 30,
            sorter: (a, b) => a.customer_name - b.customer_name,
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('customer_name')
        },
        {
            title: 'Assigned',
            dataIndex: 'assigned_to',
            key: 'assigned_to',
            width: 15,
            sorter: (a, b) => a.assigned_to - b.assigned_to,
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('assigned_to')
        },
        {
            title: 'Took',
            dataIndex: 'who_took',
            key: 'who_took',
            width: 12,
            sorter: (a, b) => a.who_took - b.who_took,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Completed',
            dataIndex: 'date_completed',
            key: 'date_completed',
            width: 15,
        },


        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            width: 50,
            ...getColumnSearchProps('summary')
        },

    ];
    ////console.log(calls);
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
            md: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const tabledata = [];

    for (let i = 0; i < calls.length; i++) {
        tabledata.push({ ...{ key: i }, ...calls[i] })
        /*
        tabledata.push({
            key: i,
            id: calls[i].id,
            date_entered: calls[i].date_entered,
            date_changed: calls[i].date_changed,
            date_completed: calls[i].date_completed,
            priority: calls[i].priority,
            summary: calls[i].summary
        });
        */
    }

    const csvHeaders = [];
    for (let i = 0; i < columns.length; i++) {
        let hobj = {
            label: columns[i].title,
            key: columns[i].key
        };
        csvHeaders.push(hobj);
    }
    console.log('lookupmodalvisible', lookupmodalVisible);
    return (
        <div style={contentDiv}>

            <Modal
                title={lookupmodalTitle}
                visible={lookupmodalVisible}
                // onOk={handleModalOk}
                confirmLoading={confirmLoading}
                onCancel={handleModalCancel}
                //width="90%"
                footer={null}
            >
                <CallLookupForm onFinish={onFinish} />
            </Modal>
            <Row type="flex">
                <Col xs={24}>
                    <Descriptions
                        title={<div><FavoritesIcon name="Call Lookup" path="/sales/call_lookup" /> Call Lookup <EditOutlined onClick={() => setLookupmodalVisible(true)} /></div>}
                        //bordered
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >

                        <Descriptions.Item label="Type">{props.type}</Descriptions.Item>
                        <Descriptions.Item label="Employee">{props.employee}</Descriptions.Item>
                        <Descriptions.Item label="Last Updated">
                            {props.last_updated !== null &&
                                <div>
                                    {props.last_updated.toString()}
                                    &nbsp;&nbsp;<Button shape="circle" size="small" icon={<SyncOutlined />} onClick={() => onRefresh()} />
                                </div>
                            }
                        </Descriptions.Item>
                    </Descriptions>

                    <Row>
                        <Col>
                            <Table
                                columns={columns}
                                dataSource={tabledata}
                                loading={loadingCalls}
                                scroll={{ y: 600 }}
                                footer={() => renderTableFooter(tabledata, csvHeaders)}
                            />
                        </Col>
                    </Row>
                    {/*
                    <Modal
                        title={editCallmodalTitle}
                        visible={editCallmodalVisible}
                        // onOk={handleModalOk}
                        confirmLoading={confirmLoading}
                        //onCancel={handleModalCancel}
                        width="90%"
                        footer={[
                            <Button key="back" onClick={handleCallModalCancel}>
                                Close
                                </Button>,
                            <Button key="submit" type="primary" loading={confirmLoading} onClick={handleModalOk}>
                                Save
                                </Button>,
                        ]}
                    >
                        <FrogCallModal callnumber={callnumber} {...fields} onChange={handleFormChange} />
                    </Modal>
                    */}
                    <FrogCallModal visible={editCallmodalVisible} onSave={handleModalOk} onModalCancel={handleCallModalCancel} callnumber={callnumber} />
                </Col>
            </Row >
        </div>
    );



}

//const CallLookup = Form.create({ name: 'CallLookup' })(CallLookupForm);

const mapStateToProps = store => ({
    calls: store.frogCall.calls,
    fetching: store.frogCall.fetching,
    fetched: store.frogCall.fetched,
    call_record: store.frogCall.call_record,
    newrecord: store.frogCall.newrecord,
    error: store.frogCall.error,
    employee: store.frogCall.employee,
    type: store.frogCall.type,
    last_updated: store.frogCall.lookup_updated,
});
/*
const mapDispatchToProps = dispatch => {
    return {
        getCalls: (params) => {
            dispatch(getCalls(params));
        },
        //editCall: (callno) => {
        //    dispatch(editCall(callno));
        //},
        setCallNumber: (callno) => {
            dispatch(setCallNumber(callno));
        },
    }
};
*/
//export default connect(mapStateToProps, mapDispatchToProps)(CallLookup)
export default connect(mapStateToProps)(CallLookup)