

import { Base64, BASE_URL, FROG_AUTH } from './../../constants';
//const apiurl = BASE_URL + 'recap_performance';
var dayjs = require('dayjs');

// Action creators store.dispatch actions
export const recapByBrand = (values) => {
    const setuprec = localStorage.getItem('setup');
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_BRAND', payload: values });
        if (setuprec === null) {
            dispatch({ type: 'FETCH_BRAND_FAIL', payload: 'Invalid setup' });
        } else {
            const startmonth = values.monthrange[0].format('MM');
            const startyear = values.monthrange[0].format('YY');
            const endmonth = values.monthrange[1].format('MM');
            const endyear = values.monthrange[1].format('YY');
            const type = values.type;
            //console.log(startmonth, startyear);

            let setupobj = JSON.parse(setuprec);
            let baseurl = setupobj.url;
            let frogauth = setupobj.username + ':' + setupobj.password;
            let apiurl = baseurl + '/recap_performance';
            let queryparams = '?report=brand&startmonth=' + startmonth + '&endmonth=' + endmonth + '&startyear=' + startyear + '&endyear=' + endyear + '&type=' + type;
            return fetch(apiurl + queryparams, {
                method: 'get',
                headers: new Headers({
                    "Authorization": "Basic " + Base64.btoa(frogauth)
                }),
                // body: formData
            }).then(
                response => {
                    // if the response is a JSON object
                    return response.json();
                }).then(response => {
                    //console.log(response);
                    if (response.errors !== undefined && response.errors !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        dispatch({ type: 'FETCH_BRAND_FAIL', payload: response.errors });
                    } else {
                        //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                        //dispatch(fetchTruckSuccess(response));
                        let results = {};
                        results.results = response;
                        results.last_updated = dayjs().format('MM/DD/YY  h:mm:ss'); //new Date(); //dayjs('MM/DD/YY, h:mm:ss a');
                        dispatch({ type: 'FETCH_BRAND_SUCCESS', payload: results });
                    }

                })
                .catch(error => {
                    //throw(error);
                    //console.log('recapbydept catch',error);
                    ////console.log(error);
                    //dispatch(fetchTruckFailed(error));
                    dispatch({ type: 'FETCH_BRAND_FAIL', payload: error });
                });
        }
    };
};
/*
export const recapByDept = (variables) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_DEPT' });
        //var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        //let headers = new Headers();
        //headers.append({"Authorization": 'Basic '+Base64.btoa(FROG_AUTH)});
        let queryparams = '?report=dept&startmonth=01&endmonth=02&startyear=20&endyear=20';
        fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_DEPT_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_DEPT_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('recapbydept catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_DEPT_FAIL', payload: error });
            });
    };
};
*/