import React, {useState, useEffect } from 'react'

import {
    Card,
    Table,
    Tag,
    Button
} from 'antd';



const SkuAvailTable = (props) => {
    const [skudata, setData] = useState(props.data);
    const [sku, setSku] = useState(props.sku);
    const [title, setTitle] = useState(props.title);

    const tableOnly = (props.tableOnly !== undefined) ? props.tableOnly : false;

    useEffect(() => {
        setData(props.data);
        setSku(props.sku);
        setTitle(props.title);
    }, [props]);

    const columns = [
        {
            title: 'Loc',
            dataIndex: 'location',
            key: 'location'
        },
        {
            title: 'Avail',
            dataIndex: 'available',
            key: 'location',
            align: 'center',
            render: available => (
                <>
                    {available <= 3 ?
                    <Tag color='volcano'>
                        {available}
                    </Tag>
                    :
                    <Tag color='green'>
                        {available}
                    </Tag>
                    }
                </>
            )
        },
        {
            title: 'Res',
            dataIndex: 'reserved',
            key: 'location',
            align: 'center'
        },
        {
            title: 'Tran',
            dataIndex: 'transfer',
            key: 'location',
            align: 'center'
        }
    ]

    if (tableOnly) {
        return (
            <>
                <Table dataSource={skudata} columns={columns} pagination={false} />
            </>
        );
    }

    return (
        <Card bordered={true} title={title} bodyStyle={{ padding:0 }}>
            <Table dataSource={skudata} columns={columns} pagination={false} />
        </Card>
    );
}


export default SkuAvailTable;

