const customerLookup = (state = {
    results: [],
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    ////console.log(action.type, action.payload);
    switch (action.type) {
        case 'FETCH_RESULTS':
            return { ...state, 
                fetching: true,
                fetched: false,
                error: null,
            }
        case 'FETCH_RESULTS_FAIL':
            return { ...state, 
                fetching: false, 
                error: action.payload 
            }
        case 'FETCH_RESULTS_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                results: action.payload.itemlist, 
                error: null,
            }

        default:
            return state
    }
}

export default customerLookup