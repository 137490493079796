import React, { useState } from 'react'
import { connect } from 'react-redux'
import { frogLogin, updateUser } from './websocket/setup/actions'
import logo from './assets/FROG_Logo_Full-lg.png';
import { Form, Input, Button, Card, Typography, Popconfirm } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const { Title } = Typography;

const LoginForm = (props) => {
    const [usernameStatus, setUserStatus] = useState('');
    const [usernameHelp, setUserHelp] = useState(null);
    const [passwordStatus, setPasswordStatus] = useState('');
    const [passwordHelp, setPasswordHelp] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

   

    const onFinish = async values => {
        props.dispatch(frogLogin(values))
            .then(() => {
                if (props.errorMessage !== null) {
                    if (typeof (props.errorMessage) == "object") {
                        if (props.errorMessage.password !== undefined) {
                            setPasswordStatus('error');
                            setPasswordHelp(props.errorMessage.password);
                        } else {
                            setUserStatus('error');
                            setUserHelp(props.errorMessage.username);
                        }
                    } else {

                    }
                } else {
                    console.log('call updateuser', values);
                    props.dispatch(updateUser(values));
                }
            });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Card
            cover={<img alt="Keep it simple system" src={logo} style={{ maxWidth: '400px', marginTop: 20, marginLeft: 20, marginRight: 20 }} />}
            style={{ boxShadow: '0 0 5px #001529' }}
        >
            <Title level={4} style={{ textAlign: "center" }}>Enter your FROG credentials</Title>
            <br />
            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Username"
                    name="username"
                    validateStatus={usernameStatus}
                    help={usernameHelp}
                    rules={[
                        {
                            required: true,
                            message: "Username is required!",
                        },
                    ]}
                >
                    <Input allowClear prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    validateStatus={passwordStatus}
                    help={passwordHelp}
                    rules={[
                        {
                            required: true,
                            message: "Password is required!",
                        },
                    ]}
                >
                    <Input.Password allowClear prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    {props.fetchingLogin ?
                        <Button type="primary" htmlType="submit" loading>
                            Login
                        </Button>
                        :
                        <Button type="primary" htmlType="submit" >
                            Login
                        </Button>
                    }
                    &nbsp;&nbsp;&nbsp;
                    <Popconfirm
                        title="Are you sure you want to reset your App?"
                        onConfirm={props.onReset}
                        //onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="danger" onClick={() => setShowConfirm(true)} >Reset App</Button>
                    </Popconfirm>

                </Form.Item>
            </Form>
        </Card>
    );

}

const mapStateToProps = store => ({
    errorMessage: store.setupState.errorMessage,
    fetchingLogin: store.setupState.fetchingLogin,
});

export default connect(mapStateToProps)(LoginForm)