import React, { useRef, useState, useEffect } from 'react';
import { render } from 'react-dom'
import { BASE_URL, FROG_AUTH,  Base64, contentDiv } from '../../constants'
import {
    Input,
    Tooltip,
    Select,
    Row,
    Col,
    Checkbox,
    Form,
    Button,
    Card,
    AutoComplete,
    DatePicker,
    Steps,
    Radio,
    Descriptions,
    Table,
    Typography,
    Modal,
    Skeleton,
} from 'antd';
import EmailEditor from 'react-email-editor';

const { Option } = Select;

const MarketEmailSetup = (props) => {
    const emailEditorRef = useRef(null);
    const [keys, setKeys] = useState([]);
    const [design, setDesign] = useState('REACT');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let ignore = false;
        async function fetchKeys() {
            const result = await fetch(BASE_URL + 'market_email?key=ALL', {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
                })
            }).then(
                response => {
                    // if the response is a JSON object
                    return response.json();
                }).then(response => {
                    //console.log(response);
                    //setKeys(response);
                    return response;
                })
                .catch(error => {
                });
            //console.log(result);
            setKeys(result);
        }
        fetchKeys();
        return () => { ignore = true; }
    }, [keys]);

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            console.log('exportHtml', design);
            var formData = new FormData();
            formData.append('key', 'REACT');
            formData.append('design', JSON.stringify(design));
            formData.append('html', html);
            fetch(BASE_URL + 'market_email?key=REACT', {
                method: 'POST',
                headers: new Headers({
                    "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
                }),
                body: formData
            }).then(
                response => {
                    // if the response is a JSON object
                    return response.json();
                }).then(response => {
                    console.log(response);
                    /*
                    if (response.errors !== undefined && response.errors !== '') {
                        // dispatch(fetchTruckFailed(response.errors));
                        //dispatch({ type: 'FETCH_CALLS_FAIL', payload: response.errors });
                    } else {
                        if (this.props.hasOwnProperty('includeall')) {
                            response.push('ALL');
                        }
                        this.setState({ options: response });
                    }
*/
                })
                .catch(error => {
                    //throw(error);
                    console.log('MARKET catch', error);
                    ////console.log(error);
                    //dispatch(fetchTruckFailed(error));
                    //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
                });
        });
    };

    const fetchTemplate = async (key) => {
        console.log('start template fetch');
        var formData = new FormData();
        formData.append('key', 'REACT');
        return await fetch(BASE_URL + 'market_email', {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            })
            .catch(error => {
                //throw(error);
                console.log('getcalls catch', error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
            });
    }

    const onLoad = () => {
        // you can load your template here;
        // const templateJson = {};

        //let templateJson = fetchTemplate('REACT');
        // emailEditorRef.current.editor.loadDesign(templateJson);


        fetch(BASE_URL + 'market_email?key=REACT', {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
            //body: formData
        }).then(
            response => {
                // if the response is a JSON object
                console.log(response);
                return response.json();
            }).then(response => {
                console.log(response);
                emailEditorRef.current.editor.loadDesign(response);

            })
            .catch(error => {
                //throw(error);
                //console.log('getcalls catch', error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
            });

    };

    const saveDesign = () => {
        emailEditorRef.current.editor.saveDesign(design => {
            console.log('saveDesign', design);

        })
    };

    return (
        <div style={contentDiv}>
            <Row>
                <Col span={24}>

                    <Card title="Market Email Setup">
                        <Select style={{ width: 120 }}>
                            {keys.map(key => (
                                <Option key={key} value={key}>{key}</Option>
                            ))}
                        </Select>
                        <Button type="primary" onClick={exportHtml}>
                            Save Design
                        </Button>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {design == '' ?
                        <Skeleton active />
                        :
                        <EmailEditor
                            ref={emailEditorRef}
                            onLoad={onLoad}
                        />
                    }

                </Col>
            </Row>

        </div>
    );
};

export default MarketEmailSetup;