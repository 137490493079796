import React from 'react';
import { NavLink } from 'react-router-dom'
//import { Button } from 'antd'
import Messenger from './messenger'
import ManagementDashboard from './dashboard/ManagementDashboard'

//import logo from './logo.svg';
import './App.css';

function Home() {
    return (
        <>
        <ManagementDashboard />
        </>
    );
}

export default Home;
