
import { Base64, BASE_URL, FROG_AUTH } from './../../constants';
const apiurl = BASE_URL + 'recap_performance';


// Action creators store.dispatch actions
export const addCart = (item,qty) => {
    return (dispatch, getState) => {
        let saorec = getState().salesEntry.saorec;
        saorec.sku_number.push(item.sku);
        saorec.qty.push(qty);
        saorec.mfg_stock_number.push(item.inventoryrec.description.join(' '));
        saorec.image.push(item.inventoryrec.thumbnails[0]);
        saorec.location.push('OVR');
        saorec.dept.push(item.inventoryrec.department);
        saorec.retail.push(item.inventoryrec.retail);
        //console.log(saorec);

        dispatch({ type: 'ADD_CART', payload: saorec });
    }

}

export const getSalesRecord = (invoiceNumber) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_INVOICE' });
        //var formData = new FormData();
        //formData.append('searchkey', searchkey);
        //formData.append('apikey', apikey);
        //let headers = new Headers();
        //headers.append({"Authorization": 'Basic '+Base64.btoa(FROG_AUTH)});
        fetch(apiurl + '?invoiceNumber=99298954', {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
           // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_INVOICE_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_INVOICE_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                //console.log('getsalesrecord catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_INVOICE_FAIL', payload: error });
            });
    };
};