const customer = (state = {
    cusrec: [],
    customernumber: '',
    email: '',
    fetching: false,
    fetched: false,
    adding: false,
    added: false,
    error: null,
    customer_number: null,
    name: null,
    add1: null,
    city: null,
    state: null,
    zip: null,
    del_to: null,
    del_address: null,
    del_city: null,
    del_state: null,
    del_zip: null,
    lot_name: null,
    subdivision: null,
    salutation: null,
    spouse_phone: null,
    special_instructions: null,
    send_no_mail: null,
    send_no_email: null,
    bus_phone: null,
    ssn: null,
    account_number: null,
    price: null,
    last_slm: null,
    credit_limit: null,
    bui1_tax_id: null,
    xstreet: null,
    cus_comment: null,
    map_code: null,
    modem_type: null,
    acct_type: null,
    password: null,
    last_sale_date: null,
}, action) => {
    //console.log(action.type, action.payload);
    switch (action.type) {
        case 'SAVE_FIELDS':
            return { ...state, ...action.payload }
        case 'FETCH_CUSTOMER':
            return { ...state, fetching: true }
        case 'FETCH_CUSTOMER_FAIL':
            return { ...state, fetching: false, error: action.payload }
        case 'FETCH_CUSTOMER_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                cusrec: action.payload,
                name: action.payload.name.value,
                add1: action.payload.add1.value,
                city: action.payload.city.value,
                state: action.payload.state.value,
                zip: action.payload.zip.value,
                del_to: action.payload.del_to.value,
                del_address: action.payload.del_address.value,
                del_city: action.payload.del_city.value,
                del_state: action.payload.del_state.value,
                del_zip: action.payload.del_zip.value,
                lot_name: action.payload.lot_name.value,
                subdivision: action.payload.subdivision.value,
                salutation: action.payload.salutation.value,
                spouse_phone: action.payload.spouse_phone.value,
                special_instructions: action.payload.special_instructions.value,
                send_no_mail: action.payload.send_no_mail.value,
                send_no_email: action.payload.send_no_email.value,
                bus_phone: action.payload.bus_phone.value,
                email: action.payload.email.value,
                ssn: action.payload.ssn.value,
                account_number: action.payload.account_number.value,
                price: action.payload.price.value,
                last_slm: action.payload.last_slm.value,
                credit_limit: action.payload.credit_limit.value,
                bui1_tax_id: action.payload.bui1_tax_id.value,
                xstreet: action.payload.xstreet.value,
                cus_comment: action.payload.cus_comment.value,
                map_code: action.payload.map_code.value,
                modem_type: action.payload.modem_type.value,
                acct_type: action.payload.acct_type.value,
                password: action.payload.password.value,
                last_sale_date: action.payload.last_sale_date.value,
            }

        default:
            return state
    }
}

export default customer