import React, { useContext, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import CustomerEntry from './../../customer'
import MFGL from './../../find/manufacturer_lookup'
import SkuTable from './SkuTable'
import FavoritesIcon from './../../components/FavoritesIcon'
import { contentDiv } from './../../constants'
//import { searchProduct } from './actions'

import { Descriptions, Input, Result, Spin, Table, Button, Popconfirm, Form, Avatar, Steps, Tabs, Modal } from 'antd';
import { defaultTableCellDataGetter } from 'react-virtualized';

const { Search } = Input;
const { TabPane } = Tabs;
const { Step } = Steps;

const SalesEntry = (props) => {
    const [dataSource, setDataSource] = useState([])
    const [count, setCount] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [tabList, setTabList] = useState(['itemstab', 'customertab', 'paymenttab'])
    const [activeTab, setActiveTab] = useState("itemstab")
    const [currentStep, setCurrentStep] = useState(0)

    useEffect(() => {
        
    }, []);

    const handleModalCancel = () => {
        setModalVisible(false)
    };

    const stepChange = (current) => {
        setCurrentStep(current)
        setActiveTab(tabList[current])
    }

    const onFinish = values => {
        setModalVisible(false)
        setConfirmLoading(false)
        
    };

    if (props.adding) {
        return (
            <div>
                <Spin />
            </div>
        )
    } else if (props.error !== null) {
        return (
            <div>
                <Result
                    status="warning"
                    title={props.error + '. Please try again.'}

                />
            </div>
        );
    } else {
        return (
            <div style={contentDiv}>
                <Modal
                    title={modalTitle}
                    visible={modalVisible}
                    // onOk={handleModalOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleModalCancel}
                    width="90%"
                    footer={null}
                >
                    <MFGL onFinish={onFinish} />
                </Modal>

                <Descriptions
                    title={<div><FavoritesIcon name="Sales Entry" path="/sales/sales_entry" /> Sales Entry</div>}
                //bordered
                />

                <Steps current={currentStep} onChange={stepChange}>
                    <Step title="Edit Items" description="" />
                    <Step title="Edit Customer" description="" />
                    <Step title="Payment & Delivery" description="" />
                </Steps>

                <Tabs activeKey={activeTab} size="large">
                    <TabPane  key="itemstab">
                        {props.saorec.sku_number.length > 0 ?
                            <SkuTable />
                            :
                            <Button type="primary" size="large" block onClick={() => setModalVisible(true)}>Add items to Sale</Button>
                        }
                    </TabPane>
                    <TabPane  key="customertab">
                        <CustomerEntry />
                    </TabPane>
                    <TabPane  key="paymenttab">
                        Content of tab 3
                    </TabPane>
                </Tabs>

            </div>
        );
    }

}


const mapStateToProps = state => ({
    saorec: state.salesEntry.saorec,
    error: state.salesEntry.error,
    adding: state.salesEntry.adding,
});

const mapDispatchToProps = dispatch => {
    return {
        // searchProduct: (searchkey) => {
        //    dispatch(searchProduct(searchkey));
        //}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesEntry);

/*
constructor(props) {
    super(props);
    columns = [
        {
            title: '',
            width: 100,
            dataIndex: 'image',
            key: 'image',
            render: (text, record, index) => <Avatar shape="square" size={75} src={record.image} />,
        },
        {
            title: 'Qty',
            width: 75,
            dataIndex: 'qty',
            key: 'qty',
            editable: 'true',
        },
        {
            title: 'Sku Number',
            dataIndex: 'sku',
            key: 'sku',
            width: 200,
            editable: 'true',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 350,
            editable: 'true',
        },
        {
            title: 'Loc',
            dataIndex: 'location',
            key: 'location',
            width: 75,
        },
        {
            title: 'Retail',
            dataIndex: 'retail',
            key: 'retail',
            width: 125,
            editable: true,
        },
        {
            title: 'Dept',
            dataIndex: 'dept',
            key: 'dept',
            width: 75,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            fixed: 'right',
            width: 75,
            render: (text, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null,
        },
    ];
    state = {
        dataSource: [],
        count: 0,
        modalVisible: false,
        modalTitle: '',
        confirmLoading: false
    };
}
*/