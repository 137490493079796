import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { Base64, BASE_URL, FROG_AUTH } from '../constants';

const { Option } = AutoComplete;

const CustomerSearch = () => {
    const [result, setResult] = useState([]);

    const handleSearch = async (value) => {
        if (value.length >= 3) {
            return await fetch(BASE_URL + 'customer?search=' + value, {
                method: 'get',
                headers: new Headers({
                    "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
                }),
            }).then(
                response => {
                    // if the response is a JSON object
                    console.log('employees', response);
                    return response.json();
                }).then(response => {

                    setResult(response);

                })
                .catch(error => {
                    console.log('getcalls catch', error);
                });
        }
    };

    return (
        <AutoComplete
            //style={{
            //    width: 200,
            //}}
            onSearch={handleSearch}
            allowClear={true}
            children={<Input />}
        //placeholder="input here"
        >
            {result.map((cusobj) => (
                <Option key={cusobj.number} value={cusobj.number}>
                    {cusobj.number + ' ' + cusobj.name}
                </Option>
            ))}
        </AutoComplete>
    );
};

export default CustomerSearch;