
import { Base64, BASE_URL, FROG_AUTH } from './../../constants';
const apiurl = BASE_URL + 'calendar';


// Action creators store.dispatch actions
export const getMonthData = async (value) => {
        //if (values.type === undefined) {values.type='written'}
        //dispatch({ type: 'FETCH_MONTH', payload: values });
        /*
        const startdate = values.daterange[0].format('MM-DD-YY');
        const enddate = values.daterange[1].format('MM-DD-YY');
        const type = values.type;
        */
        let queryparams = '?date='+value; //+enddate;
        return await fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
           // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                //console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    //dispatch({ type: 'FETCH_MONTH_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                   // dispatch({ type: 'FETCH_MONTH_SUCCESS', payload: response });
                }
                return response;
            })
            .catch(error => {
                //throw(error);
                console.log('recapbydept catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
               // dispatch({ type: 'FETCH_MONTH_FAIL', payload: error });
               return error
            });
};
/*
export const getMonthData = (values) => {
    return (dispatch, getState) => {
        if (values.type === undefined) {values.type='written'}
        dispatch({ type: 'FETCH_MONTH', payload: values });
        
        const startdate = values.daterange[0].format('MM-DD-YY');
        const enddate = values.daterange[1].format('MM-DD-YY');
        const type = values.type;
        
        let queryparams = '?date=08-27-21'; //+enddate;
        return fetch(apiurl + queryparams, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
            }),
           // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            }).then(response => {
                console.log(response);
                if (response.errors !== undefined && response.errors !== '') {
                    // dispatch(fetchTruckFailed(response.errors));
                    dispatch({ type: 'FETCH_MONTH_FAIL', payload: response.errors });
                } else {
                    //AsyncStorage.setItem('apiUrl', response.data.apiurl);
                    //dispatch(fetchTruckSuccess(response));
                    dispatch({ type: 'FETCH_MONTH_SUCCESS', payload: response });
                }

            })
            .catch(error => {
                //throw(error);
                console.log('recapbydept catch',error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                dispatch({ type: 'FETCH_MONTH_FAIL', payload: error });
            });
    };
};
*/
