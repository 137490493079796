import React from 'react'
import { connect } from 'react-redux'
import { contentDiv } from './../../constants'

import Messenger from './../../messenger'
import FavoritesIcon from '../../components/FavoritesIcon'
import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Descriptions,
} from 'antd';

class ReturnToVendor extends React.Component {
    state = {

    };

    componentDidMount() {

    }
    componentDidUpdate(prevProps) {

    }

    onFinish = values => {

    };

    render() {
        //test
        return (
            <div style={contentDiv}>
                <Row type="flex">
                    <Col xs={24}>
                        <Descriptions
                            title={<div><FavoritesIcon name="Return to Vendor" path="/service/return_to_vendor" /> Return to Vendor </div>}
                        >
                        </Descriptions>

                        <Messenger />
                    </Col>
                </Row >
            </div>
        );
    }

}

//const CallLookup = Form.create({ name: 'CallLookup' })(CallLookupForm);

const mapStateToProps = store => ({
    calls: store.frogCall.calls,
    
});
/*
const mapDispatchToProps = dispatch => {
    return {
        getCalls: (params) => {
            dispatch(getCalls(params));
        },
        //editCall: (callno) => {
        //    dispatch(editCall(callno));
        //},
        setCallNumber: (callno) => {
            dispatch(setCallNumber(callno));
        },
    }
};
*/
//export default connect(mapStateToProps, mapDispatchToProps)(CallLookup)
export default connect(mapStateToProps)(ReturnToVendor)