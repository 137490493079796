import React, { useState, useEffect } from 'react'
import { BASE_URL, FROG_AUTH, Base64 } from '../../constants'
import useSWR from 'swr';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts'
import {
    Skeleton,
    Typography
} from 'antd';
//import { EditOutlined } from '@ant-design/icons';

const dashboardAPI = BASE_URL + 'dashboard?type=dailyfigures';

const fetchSales = async () => {
    ////console.log('start employee fetch');
    return await fetch(dashboardAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            //console.log('getcalls catch', error);
            //////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}


const onPieClick = (data) => {
    //console.log(data);
}

const DailySales = props => {
    const [data, setData] = useState(props.data);
    //const [data, setData] = useState([]);
    //  const { data: salesData } = useSWR(dashboardAPI, fetchSales);


    useEffect(() => {


        setData(props.data);
        //if (data.length === 0) {fetchEmployees();}
        //console.log(props);
    }, [props]);

    let graphData = [];
    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let qty = parseInt(data[i].qty);
            let retail = parseFloat(data[i].retail);
            let saledate = data[i].saledate;
            let find = graphData.findIndex(x => x.name === saledate);
            if (find === -1) {
                let obj = {
                    "name": saledate,
                    "qty": qty,
                    "retail": retail
                }
                graphData.push(obj);
            } else {
                graphData[find].qty += qty;
                let r = graphData[find].retail;
                r += retail;
                graphData[find].retail = r;//.toFixed(2);
            }
        }
        graphData.sort((a, b) => {
            return a.toString().localeCompare(b); //a.name < b.name;
        });
        //console.log('graphdata',graphData);
        /*
        let thisline = {
            "id": "Last 30 Days",
            "color": "hsl(45, 70%, 50%)",
            "data": []
        };
        
        graphData.push(thisline);
        */
    }
    /**
    const graphData = [
        {
          "name": "8AM",
          "pv": 2400
        },
        {
          "name": "9AM",
          "pv": 1398
        },
        {
          "name": "10AM",
          "pv": 9800
        },
        {
          "name": "11AM",
          "pv": 3908
        },
        {
          "name": "12AM",
          "pv": 4800
        },
        {
          "name": "1PM",
          "pv": 3800
        },
        {
          "name": "2PM",
          "pv": 4300
        }
      ]
    */
    ////console.log('DailySales',props);
    //const graphData = data;
    console.log('graphdata', graphData);
    if (graphData === undefined || graphData.length == 0) {
        return (
            <ResponsiveContainer width="100%" height={400}>
                <div >
                    <Skeleton active />
                </div>
            </ResponsiveContainer>
        )
    }
    if (graphData !== undefined && graphData.length == 1) {
        return (
            <ResponsiveContainer width="100%" height={400}>
                <div >
                    <Typography>Total Qty: {graphData[0].qty}</Typography>
                    <Typography>Total Retail: {graphData[0].retail}</Typography>
                </div>
            </ResponsiveContainer>
        )
    }
    return (
        <ResponsiveContainer width="100%" height={400}>

            <LineChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="retail" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>

        </ResponsiveContainer>
    )
}


export default DailySales;
