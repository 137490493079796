import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Router from './layout/Router'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
//import WebSocket from './WebSocket';
import { HomeOutlined, ShoppingCartOutlined, ReconciliationOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import logoFooter from './assets/FROG_Logo_Abbr-sm.png';
import { Layout, Menu } from 'antd';
//import BasicLayout, { SettingDrawer } from '@ant-design/pro-layout';
import GlobalHeaderRight from './components/GlobalHeaderRight';


import './App.css';
import MainMenuSearch from './components/MainMenuSearch';
const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;


//const DashboardLayout = ({ children }) => (
class DashboardLayout extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        console.log(this.props);
        // service
        let serviceTasks = this.props.employeeTasks.filter(task => task.startsWith('service.') && !task.startsWith('service.reports.'));
        serviceTasks = serviceTasks.map(x => x.replace('.', '/'));
        // const serviceReportTasks = this.props.employeeTasks.filter(task => task.startsWith('service.reports.'));
        //console.log('Tasks',serviceTasks);
        // purchasing
        let purchasingTasks = this.props.employeeTasks.filter(task => task.startsWith('purchasing.') && !task.startsWith('purchasing.reports.'));
        purchasingTasks = purchasingTasks.map(x => x.replace('.', '/'));
        // inventory
        let inventoryTasks = this.props.employeeTasks.filter(task => task.startsWith('inventory.') && !task.startsWith('inventory.reports.'));
        inventoryTasks = inventoryTasks.map(x => x.replace('.', '/'));
        // delivery/transfer
        let deliveryTasks = this.props.employeeTasks.filter(task => task.startsWith('delivery/transfer.') && !task.startsWith('delivery/transfer.reports.'));
        deliveryTasks = deliveryTasks.map(x => x.replace('.', '/'));
        // receivables
        let receivablesTasks = this.props.employeeTasks.filter(task => task.startsWith('receivable.') && !task.startsWith('receivable.reports.'));
        receivablesTasks = receivablesTasks.map(x => x.replace('.', '/'));
        // payables
        let payablesTasks = this.props.employeeTasks.filter(task => task.startsWith('payables.') && !task.startsWith('payables.reports.'));
        payablesTasks = payablesTasks.map(x => x.replace('.', '/'));
        // find
        let findTasks = this.props.employeeTasks.filter(task => task.startsWith('find.') && !task.startsWith('find.reports.'));
        findTasks = findTasks.map(x => x.replace('.', '/'));
        // managment
        let managementTasks = this.props.employeeTasks.filter(task => task.startsWith('management.') && !task.startsWith('management.reports.'));
        managementTasks = managementTasks.map(x => x.replace('.', '/'));
        // general ledger
        let glTasks = this.props.employeeTasks.filter(task => task.startsWith('gl.') && !task.startsWith('gl.reports.'));
        glTasks = glTasks.map(x => x.replace('.', '/'));
        // reports
        let reportsTasks = this.props.employeeTasks.filter(task => task.startsWith('reports.'));
        reportsTasks = reportsTasks.map(x => x.replace('.', '/'));
        // utilities
        let utilitiesTasks = this.props.employeeTasks.filter(task => task.startsWith('utilities.'));
        utilitiesTasks = utilitiesTasks.map(x => x.replace('.', '/'));
        // setup
        let setupTasks = this.props.employeeTasks.filter(task => task.startsWith('setup.'));
        setupTasks = setupTasks.map(x => x.replace('.', '/'));


        //.map((task) =>
        //                           <Menu.Item key={task}>{task}</Menu.Item>
        //                       )}
        // let filtcalls = this.props.calls.filter(call => call.date_entered == dayjs('MM-DD-YY'));
        //console.log(this.props.employeeName);
        return (
            <Layout>
                <Helmet
                    title="FROG Kiss Dashboard"
                    meta={[
                        { name: 'description', content: 'FROG Kiss' },
                        { name: 'keywords', content: 'kiss frog dashboard' },
                    ]}

                />
                <Header style={header} >
                    {/*
            <div style={logoParent}>
                <img src={logo} style={{ maxHeight: 40, marginTop: 0, marginLeft: 0 }} />
            </div>
            */}
                    <GlobalHeaderRight />
                </Header>
                <Layout>
                    <Sider
                        breakpoint="xl"
                        collapsedWidth="0"
                        onBreakpoint={broken => {
                            //console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            //console.log(collapsed, type);
                        }}
                        style={{
                            marginTop: 64,
                            minHeight: 768,
                        }}
                    >

                        <Menu
                            theme="dark"
                            mode="vertical"
                            defaultSelectedKeys={['1']}
                            triggerSubMenuAction="click"
                        >

                            <Menu.Item key="home_dashboard">
                                <NavLink to="/">
                                    <HomeOutlined />
                                    <span className="nav-text">Home</span>
                                </NavLink>
                            </Menu.Item>

                            <SubMenu
                                key="sub1"
                                title={
                                    <span>
                                        <ShoppingCartOutlined />
                                        <span>Sales</span>
                                    </span>
                                }
                            >
                                <Menu.Item key="tar">
                                    <NavLink
                                        to="/sales/reports/transaction_report"
                                    >
                                        <span className="nav-text">Transaction Report</span>
                                    </NavLink>
                                </Menu.Item>

                                <Menu.Item key="5">
                                    <NavLink
                                        to="/sales/reports/recap_performance/recap_performance_by_brand"
                                    >
                                        <span className="nav-text">Recap by Brand</span>
                                    </NavLink>
                                </Menu.Item>

                                <Menu.Item key="6">
                                    <NavLink
                                        to="/sales/reports/recap_performance/recap_performance_by_dept"
                                    >
                                        <span className="nav-text">Recap by Dept</span>
                                    </NavLink>
                                </Menu.Item>
                                {/*
                                    <Menu.Item key="7">
                                        <NavLink
                                            to="/sales/reports/recap_performance/recap_performance_by_item"
                                        >
                                            <span className="nav-text">Recap by Item</span>
                                        </NavLink>
                                    </Menu.Item>
*/}
                                
                            </SubMenu>
                            {/* <ReconciliationOutlined /> */}
                        </Menu>
                    </Sider>
                    <Layout>


                        <Content style={{ margin: '80px 16px 0' }}>
                            <div >
                                {/*<WebSocket>*/}
                                <Router />
                                {/*</WebSocket>*/}
                                {/* {children()} */}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}><img src={logoFooter} style={{ maxHeight: 80, marginTop: 0, marginLeft: 0 }} alt="FROG, Inc @2020" /></Footer>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}

DashboardLayout.propTypes = {
    children: PropTypes.func,
}

const mapStateToProps = store => ({
    employeeTasks: store.setupState.employeeTasks,
});

export default connect(mapStateToProps)(DashboardLayout)
//export default DashboardLayout

const logoParent = {
    height: '32px',
    background: 'rgba(255, 255, 255, 0.2)',
    margin: '16px',
};

const header = {
    background: '#ffffff',
    padding: 0,
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    boxShadow: '0 3px 3px #001529'
    //borderBottom: '1px solid #001529'
};

const contentDiv = {
    padding: 24,
    background: '#fff',
    minHeight: 360
};