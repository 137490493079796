import React, { useState } from 'react'
import { BASE_URL, FROG_AUTH,  Base64 } from '../../constants'
import useSWR from 'swr';
import { ResponsiveLine } from '@nivo/line'
import {
    Row,
    Col
} from 'antd';
import { EditOutlined } from '@ant-design/icons';

const dashboardAPI = BASE_URL + 'dashboard?type=dailyfigures';

const fetchSales = async () => {
    //console.log('start employee fetch');
    return await fetch(dashboardAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}


const onPieClick = (data) => {
    console.log(data);
}

const DailyFigures = props => {
    const [profileState, setProfileState] = useState(props);
    //const [data, setData] = useState([]);
    const { data: salesData } = useSWR(dashboardAPI, fetchSales);

    /*
    useEffect(() => {
        
        
        setData(props.employeeList);
        //if (data.length === 0) {fetchEmployees();}
        console.log(props);
    }, [data])
    *
   onPieClick = (params) => {
    console.log('onPieClick',params);
};*/

    let lineData = [];
    if (salesData !== undefined) {
        let thisline = {
            "id":"Last 30 Days",
            "color": "hsl(45, 70%, 50%)",
            "data": []
        };
        for (let i = 0; i < salesData.length; i++) {
            let dollars = salesData[i].subtotal;
            if (dollars === '') { dollars = 0; }
            dollars = parseFloat(dollars);
            if (dollars > 0) {
                let point = {
                    "x": salesData[i].date,
                    "y": dollars
                };
                thisline.data.push(point);
            }
        }
        lineData.push(thisline);
    }




    return (
        <div style={{ height: 400 }}>
            {lineData.length > 0 ?
                <ResponsiveLine
                    data={lineData}
                    margin={{ top: 20, right: 20, bottom: 50, left: 80 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Dates',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Subtotal',
                        legendOffset: -60,
                        legendPosition: 'middle'
                    }}
                    colors={{ scheme: 'spectral' }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[]}
                />

                :
                <div>loading</div>
            }

        </div>
    )
}


export default DailyFigures;
