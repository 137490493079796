import React, {useState, useEffect } from 'react'

import {
    Card,
    Table,
    Tag,
    Button
} from 'antd';



const POAvailTable = (props) => {
    const [podata, setData] = useState(props.data);
    const [sku, setSku] = useState(props.sku);
    const [title, setTitle] = useState(props.title);

    const tableOnly = (props.tableOnly !== undefined) ? props.tableOnly : false;

    useEffect(() => {
        setData(props.data);
        setSku(props.sku);
        setTitle(props.title);
    }, [props]);

    const columns = [
        {
            title: 'PO',
            dataIndex: 'ponumber',
            key: 'ponumber'
        },
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            key: 'Order Date',
            responsive: ['md'],
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'Qty',
            align: 'center',
            responsive: ['md'],
        },
        {
            title: 'Available',
            dataIndex: 'avail',
            key: 'Available',
            align: 'center',
            render: avail => (
                <>
                    {avail <= 3 ?
                    <Tag color='volcano'>
                        {avail}
                    </Tag>
                    :
                    <Tag color='green'>
                        {avail}
                    </Tag>
                    }
                </>
            )
        },
        {
            title: 'ETA',
            dataIndex: 'eta_date',
            key: 'ETA'
        }
    ]

    if (tableOnly) {
        return (
            <>
                <Table dataSource={podata} columns={columns} pagination={false} />
            </>
        );
    }

    return (
        <Card bordered={true} title="Purchase Orders" bodyStyle={{ padding:0 }}>
            <Table dataSource={podata} columns={columns} pagination={false} />
        </Card>
    );
}


export default POAvailTable;

