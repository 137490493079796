import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { contentDiv } from './../../constants'
import { saveCall, setCallNumber } from './actions'
import '@ant-design/compatible/assets/index.css';
import {
    Popconfirm,
    Skeleton,
} from 'antd';
import FrogCallForm from './FrogCallForm';

const FrogCall = (props) => {
    const [fields, setFields] = useState({})
   /* 
        email: {
            value: 'benjycui'
        })*/
    const [popupVisible, setPopupVisible] = useState(false)

    useEffect(() => {
        if (props.callnumber !== '') {
            setPopupVisible(true)
        }
    }, []);

    /*
    const saveCallLocal = () => {
        props.dispatch(saveCall())
            .then(() => {
                if (props.newrecord === null) {
                    setState({
                        modalVisible: false,
                        confirmLoading: false,
                    });
                } else {
                    setState({
                        confirmLoading: false,
                    });
                }
            });
    }
    */  

    const confirm = (e) => {
        props.dispatch(setCallNumber(''));
        setPopupVisible(false);
    }

    const cancel = (e) => {
        setPopupVisible(false);
    }
/*
    if (props.fetching === true) {
        return (
            <div style={contentDiv}>
                <Skeleton active />
            </div>
        );
    } else {
        return (
            <div style={contentDiv}>
                <Popconfirm
                    title="Continue with this call or create new?"
                    visible={popupVisible}
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="Create New"
                    cancelText="Continue"
                />
*/
return (
    <div style={contentDiv}>
                <FrogCallForm callnumber='' />
            </div>
        );
    //}

}


const mapStateToProps = store => ({
    calls: store.frogCall.calls,
    fetching: store.frogCall.fetching,
    fetched: store.frogCall.fetched,
    callnumber: store.frogCall.callnumber,
    call_record: store.frogCall.call_record,
});
/*
const mapDispatchToProps = dispatch => {
    return {
        getCalls: (params) => {
            dispatch(getCalls(params));
        },
    }
};
*/
export default connect(mapStateToProps)(FrogCall)
//export default connect(mapStateToProps, mapDispatchToProps)(FrogCall)