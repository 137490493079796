import React, { useState, useEffect } from 'react'
import { BASE_URL, FROG_AUTH,  Base64 } from '../../constants'
import useSWR from 'swr';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts'
import { PropTypes } from 'prop-types';
import {
    Skeleton
} from 'antd';
//import { EditOutlined } from '@ant-design/icons';

const dashboardAPI = BASE_URL + 'dashboard?type=dailyfigures';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const PieGraph = props => {
    const [title, setTitle] = useState(props.title);
    const [data, setData] = useState(props.data);
    //const [data, setData] = useState([]);
    //  const { data: salesData } = useSWR(dashboardAPI, fetchSales);


    useEffect(() => {


        setData(props.data);
        //if (data.length === 0) {fetchEmployees();}
        //console.log(props);
    }, [props]);

    let graphData = [];
    if (data.length > 0) {
        // logic to pre-build time array
        console.log('pie data', data);
        for (let i = 0; i < data.length; i++) {
            let qty = parseInt(data[i].qty);
            let retail = parseFloat(data[i].retail);
            let dept = data[i].dept.toString();
            //console.log('Hour - ',hour);
            let find = graphData.findIndex(x => x.name === dept);
            if (find === -1) {
                let obj = {
                    "name": dept,
                    "value": retail
                }
                graphData.push(obj);
            } else {
                //graphData[find].qty += qty;
                let r = graphData[find].retail;
                r += retail;
                graphData[find].retail = r;//.toFixed(2);
            }
        }
        /*graphData.sort((a,b) => {
            return a.toString().localeCompare(b); //a.name < b.name;
        });
        //console.log('graphdata',graphData);
        /*
        let thisline = {
            "id": "Last 30 Days",
            "color": "hsl(45, 70%, 50%)",
            "data": []
        };
        
        graphData.push(thisline);
        */
    }



    return (
        <ResponsiveContainer width="100%" height={400}>
            {graphData.length > 0 ?
                <PieChart >
                    <Tooltip />
                    <Legend />
                    <Pie
                        isAnimationActive={false}
                        data={graphData}
                        cx={375}
                        cy={175}
                        outerRadius={140}
                        fill="#8884d8"
                        label
                    >
                        {
                            data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                </PieChart>

                :
                <div >
                    <Skeleton active />
                </div>
            }

        </ResponsiveContainer>
    )
}

PieGraph.propeTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
};

export default PieGraph;
