const recapPerformanceByItem = (state = {
    results: [],
    daterange: [],
    type: '',
    stores: [],
    last_updated: null,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
   // //console.log(action.type,action.payload);
   switch (action.type) {
    case 'FETCH_ITEM':
        return { 
            ...state, 
            fetching: true,
            stores: action.payload.stores, 
            daterange: action.payload.daterange, 
            type: action.payload.type 
            
        }
    case 'FETCH_ITEM_FAIL':
        return { ...state, fetching: false, error: action.payload }
    case 'FETCH_ITEM_SUCCESS':
        return {
            ...state,
            fetching: false,
            fetched: true,
            results: action.payload.results,
            last_updated: action.payload.last_updated
        }

    default:
        return state
}
}

export default recapPerformanceByItem