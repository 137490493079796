import React, { useState } from 'react'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom'
import {
    AutoComplete,
} from 'antd';
//import { PropertySafetyOutlined } from '@ant-design/icons';
//import { EditOutlined } from '@ant-design/icons';

const { Option } = AutoComplete;

const MainMenuSearch = props => {
    //const [profileState, setProfileState] = useState(props);
    const [result, setResult] = useState([]);

    const handleSearch = value => {
        let res = [];
        //console.log(value);

        if (!value) {
            res = [];
        } else {
            res = props.employeeTasks.filter(task => task.toUpperCase().indexOf(value.toUpperCase().replace(/ /g, '_')) !== -1);
        }
        res = res.map(task => {
            let taskpath = '/' + task.replace(/\./g, '/');
            let taskname = task.split('.').slice(-1);
            console.log(taskname);
            taskname = taskname[0].replace(/_/g, ' ');
            taskname = taskname[0].toUpperCase() + taskname.slice(1);
            let taskobj = {
                name: taskname,
                path: taskpath,
                value: taskname
            }
            return taskobj;
        });
        //console.log(res);
        setResult(res);

    };

    const onSelect = data => {
        console.log('onSelect', data);
        props.history.push(data);
        setResult([]);
    };

    return (
        <AutoComplete
            style={{
                width: '100%',
            }}
            allowClear={true}
            backfill={false}
            onSearch={handleSearch}
            onSelect={onSelect}
            placeholder="Search Menu"
        >
            {result.map(task => (
                <Option key={task.path} value={task.path} >
                    {task.name}
                </Option>
            ))}

        </AutoComplete>
    );
};

const mapStateToProps = store => ({
    employeeTasks: store.setupState.employeeTasks,
});

export default withRouter(connect(mapStateToProps)(MainMenuSearch));
