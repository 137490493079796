import React, { useState, useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import useSWR from 'swr';
import { BASE_URL, FROG_AUTH,  Base64 } from '../../constants'
import { addCart } from '../../sales/sales_entry/actions'
import POAvailTable from '../../components/POAvailTable'
import SkuAvailTable from '../../components/SkuAvailTable'
import {
    Image,
    Select,
    Row,
    Col,
    Carousel,
    Card,
    Descriptions,
    PageHeader,
    Button
} from 'antd';
import {
    ShoppingCartOutlined, EditOutlined, SearchOutlined
} from '@ant-design/icons';
import { contentDiv } from './../../constants'

const { Option } = Select;

const skuAPI = BASE_URL + 'mfgl?type=dlgres';
//const skuAPI = BASE_URL + 'employee_file?key=ALL';
const fetchSku = async (sku,skuIndex) => {
    //console.log('start SKU fetch');
    return await fetch(skuAPI + '&searchkey=' + sku, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            //console.log('employees', response);
            return response.json();
        })
        .catch(error => {
            //throw(error);
            //console.log('getcalls catch', error);
            ////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}

const Reservations = (props) => {
    const [skustate, setState] = useState({sku:props.location.skunumber, skuIndex:props.location.skuindex});
   // const [sku, setSku] = useState(props.location.skunumber);
    //const [skuIndex, setIndex] = useState(props.location.skuindex);
    const [username, setUser] = useState(props.username);
    const [results, setResults] = useState(props.results);
    const { data: skudata } = useSWR([skustate.sku], (sku) => fetchSku(sku));

    /*
    useEffect(() => {
        
        
        //setData(props.data);
        //if (data.length === 0) {fetchEmployees();}
        //console.log(props);
    }, [skudata]);
    */


    //console.log(error,isValidating);
    //console.log(sku, skuIndex);
    return (
        <div style={contentDiv}>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={skustate.sku}
                //subTitle="This is a subtitle"
                extra={[
                    <Button key="b2" onClick={() => setState({...skustate, sku:results[skustate.skuIndex-1].sku,skuIndex:skustate.skuIndex-1})}>Previous Item</Button>,
                    <Button key="b1" onClick={() => setState({...skustate, sku:results[skustate.skuIndex+1].sku,skuIndex:skustate.skuIndex+1})}>Next Item</Button>,
                ]}
            ></PageHeader>
            {skudata === undefined ?
                <Row>
                    <Col xs={24} >
                        <Card bordered={true}  loading={true}>

                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card bordered={true} title="Stock" loading={true}>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12} >
                        <Card bordered={true} title="Purchase Orders" loading={true}>
                        </Card>
                    </Col>
                </Row>
                :
                <Row>
                    <Col xs={24} >
                        <Card bordered={true} bodyStyle={{ padding: 0 }}>
                            <Row>
                                <Col xs={24} lg={12}>
                                    <Descriptions
                                        layout="vertical"
                                        bordered
                                        column={{ xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
                                    >
                                        <Descriptions.Item label="Vendor">
                                            {skudata.inventoryrec.vendor.code}
                                            <br />
                                            {skudata.inventoryrec.vendor.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Department">
                                            {skudata.inventoryrec.dept.code}
                                            <br />
                                            {skudata.inventoryrec.dept.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Status">
                                            {skudata.inventoryrec.status.code}
                                            <br />
                                            {skudata.inventoryrec.status.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Description">{skudata.inventoryrec.description}</Descriptions.Item>
                                    </Descriptions>
                                    <Button type="primary" icon={<ShoppingCartOutlined />} onClick={() => props.addCart(skudata, 1)}>Add to Cart</Button>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Carousel >
                                        {skudata.inventoryrec.images.map((imagesrc) => {
                                            return (
                                                <div key={imagesrc}>
                                                    <img
                                                        width="100%"
                                                        src={'https://www.furnguy.com/images/' + imagesrc}
                                                    />
                                                </div>

                                            )
                                        })}

                                    </Carousel>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={7} xl={6}>
                        <SkuAvailTable data={skudata.serial_qtys} sku={skustate.sku} title="Serial Qtys" />
                    </Col>
                    <Col xs={24} md={12} lg={7} xl={6}>
                        <SkuAvailTable data={skudata.bulk_qtys} sku={skustate.sku} title="Bulk Qtys" />
                    </Col>
                    <Col xs={24} md={24} lg={10} xl={12}>
                        <POAvailTable data={skudata.purchase_orders} sku={skustate.sku} />
                    </Col>
                </Row>
            }

        </div>
    );


}

const mapStateToProps = state => ({
    username: state.setupState.username,
    results: state.mfgl.results,
});

const mapDispatchToProps = dispatch => {
    return {
        addCart: (item, qty) => {
            dispatch(addCart(item, qty));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);