import React, { useState, useEffect } from 'react'
import { BASE_URL, FROG_AUTH,  Base64 } from '../../constants'
import useSWR from 'swr';
import { ResponsiveContainer, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Bar } from 'recharts'
import {
    Skeleton
} from 'antd';
//import { EditOutlined } from '@ant-design/icons';

const dashboardAPI = BASE_URL + 'dashboard?type=dailyfigures';

const fetchSales = async () => {
    ////console.log('start employee fetch');
    return await fetch(dashboardAPI, {
        method: 'get',
        headers: new Headers({
            "Authorization": "Basic " + Base64.btoa(FROG_AUTH)
        }),
        // body: formData
    }).then(
        response => {
            // if the response is a JSON object
            return response.json();
        })
        .catch(error => {
            //throw(error);
            //console.log('getcalls catch', error);
            //////console.log(error);
            //dispatch(fetchTruckFailed(error));
            //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
        });
}


const onPieClick = (data) => {
    //console.log(data);
}

const SalesByVendor = props => {
    const [data, setData] = useState(props.data);
    //const [data, setData] = useState([]);
  //  const { data: salesData } = useSWR(dashboardAPI, fetchSales);

    
    useEffect(() => {
        
        
        setData(props.data);
        //if (data.length === 0) {fetchEmployees();}
        //console.log(props);
    }, [props]);
    
    let graphData = [];
    
    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let qty = parseInt(data[i].qty);
            let retail = parseFloat(data[i].retail);
            let vendor = data[i].vendor;
            //console.log('Dept - ',vendor);
            let find = graphData.findIndex(x => x.name === vendor);
            if (find === -1) {
                let obj = {
                    "name":vendor,
                    "qty":qty,
                    "retail":retail
                }
                graphData.push(obj);
            } else {
                let q = parseFloat(graphData[find].qty);
                q += parseFloat(qty);
                graphData[find].qty = q;
                let r = parseFloat(graphData[find].retail);
                r += parseFloat(retail);
                graphData[find].retail = parseFloat(r).toFixed(2);
            }
        }
        /*graphData.sort((a,b) => {
            return a.toString().localeCompare(b); //a.name < b.name;
        });*/
       
    }

    return (
        <ResponsiveContainer width="100%" height={400}>
            {graphData.length > 0 ?
                <BarChart data={graphData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/*<Bar dataKey="qty" fill="#82ca9d" />*/}
                    <Bar dataKey="retail" fill="#800000" />
                </BarChart>

                :
                <div >
                    <Skeleton active />
                </div>
            }

        </ResponsiveContainer>
    )
}


export default SalesByVendor;
