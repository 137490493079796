import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom'
import { searchProduct } from './actions'
import { saveMfglColumns } from '../../setup/employee/actions';
import { addCart } from '../../sales/sales_entry/actions'
import FavoritesIcon from './../../components/FavoritesIcon'
import StatusSelect from './../../components/StatusSelect'
import POAvailTable from './../../components/POAvailTable'
import SkuAvailTable from './../../components/SkuAvailTable'

import { MenuOutlined, ShoppingCartOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';

import { Avatar, Input, Result, Popover, Card, Col, Row, Tag, Select, Form, Radio, Modal, Descriptions, Button, AutoComplete, Skeleton, Table, Typography } from 'antd';
import { contentDiv, IMG_BASE } from '../../constants';

const { Text, Title, Paragraph } = Typography;
const { Search } = Input;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
        md: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
        md: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const MFGLForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title="Search Skus"
            okText="Search"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                {...formItemLayout}
                initialValues={{
                    includeGroups: 'no',
                    inStockOnly: 'yes',
                }}
            // onFinish={props.onFinish} 
            >
                <Form.Item label="Include Groups" name="includeGroups" >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="yes">Yes</Radio.Button>
                        <Radio.Button value="no">No</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="In Stock Only" name="inStockOnly" >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="yes">Yes</Radio.Button>
                        <Radio.Button value="no">No</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <StatusSelect
                    name="statusCodes"
                    label="Status Codes"
                    mode="multiple"
                    includeall={true}
                    rules={[
                        {
                            required: true,
                            message: 'Please select a status code!',
                        },
                    ]}
                />

                <Form.Item
                    label="Search"
                    name="searchkey"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your search terms!',
                        },
                    ]}
                >
                    <Input placeholder="Enter search terms" allowClear />

                </Form.Item>


            </Form >
        </Modal>
    );
};

const Number = ({ number }) => {
    if (number === undefined || number === null || number === '') { number = 0; }
    if (number === 0) {
        return (
            <Tag color='volcano'>
                {number}
            </Tag>
        )
    } else if (number <= 3) {
        return (
            <Tag color='gold'>
                {number}
            </Tag>
        )
    } else {
        return (
            <Tag color='green'>
                {number}
            </Tag>
        )
    }
}

const GridView = (props) => {
    const Actions = ( item, index ) => {
        let actionlist = [<ShoppingCartOutlined key="add" onClick={() => props.addCart(item, 1)} />];
        if (window.location.pathname.includes('manufacturer_lookup')) {
            actionlist.push(<NavLink
                to={{
                    pathname: "/find/reservations",
                    skunumber: item.sku,
                    skuindex: index
                }}
            >
                <MenuOutlined key="edit" />
            </NavLink>)
        }
        return actionlist
    }

    return (
        <Row
            gutter={{ xs: 4, sm: 6, md: 8, lg: 12, xl: 16 }, 16}
        >
            {props.results.map((item, index) =>
                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6} key={index}>
                    <Card
                        //bodyStyle={{height: "100%",marginBottom: "auto"}}
                        hoverable
                        cover={
                            item.inventoryrec.thumbnails.length > 0 ?
                                <img alt="example" src={"https://www.furnguy.com/images/" + item.inventoryrec.thumbnails[0]} />
                                :
                                <img alt="example" src={"https://www.furnguy.com/images/noimage.gif"} />
                        }
                        actions={Actions(item,index)}
                    >

                        <Row gutter={[10, 10]}>
                            <Col xs={24} style={{ textAlign: 'center' }}><Title level={4}>{item.sku}</Title></Col>
                            {props.selectedColumns.indexOf('description') !== -1 && <Col xs={24}><Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>{item.inventoryrec.description}</Paragraph></Col>}
                            {props.selectedColumns.indexOf('retail') !== -1 && <><Col xs={13} md={6}><Text strong={true}>Retail:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}>{item.inventoryrec.retail}</Col></>}
                            {props.selectedColumns.indexOf('sku_serial') !== -1 && <><Col xs={13} md={6}><Text strong={true}>Sku Serial:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}>{item.skuserial}</Col></>}
                            {props.selectedColumns.indexOf('status') !== -1 && <><Col xs={13} md={6}><Text strong={true}>Status:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}>{item.inventoryrec.status.code}</Col></>}
                            {props.selectedColumns.indexOf('dept') !== -1 && <><Col xs={13} md={6}><Text strong={true}>Dept:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}>{item.inventoryrec.dept.code}</Col></>}
                            {props.selectedColumns.indexOf('total_avail') !== -1 &&
                                <>
                                    <Col xs={13} md={6}><Text strong={true}>Total Avail:</Text></Col>
                                    <Col xs={11} md={6} style={{ textAlign: 'center' }}><Popover content={<SkuAvailTable data={item.serial_qtys} sku={item.sku} tableOnly={true} />} title="Serial Qtys" trigger="click"><span><Number number={item.total_avail} /></span></Popover></Col>
                                </>
                            }
                            {props.selectedColumns.indexOf('po_avail') !== -1 &&
                                <>
                                    <Col xs={13} md={6}><Text strong={true}>PO Avail:</Text></Col>
                                    <Col xs={11} md={6} style={{ textAlign: 'center' }}><Popover content={<POAvailTable data={item.purchase_orders} sku={item.sku} tableOnly={true} />} title="Purchase Orders" trigger="click"><span><Number number={item.po_avail} /></span></Popover></Col>
                                </>
                            }
                            {props.selectedColumns.indexOf('whs_avail') !== -1 && <><Col xs={13} md={6}><Text strong={true}>WHS Avail:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}><Number number={item.whs_avail} /></Col></>}
                            {props.selectedColumns.indexOf('flr_avail') !== -1 && <><Col xs={13} md={6}><Text strong={true}>Floor Avail:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}><Number number={item.flr_avail} /></Col></>}
                            {props.selectedColumns.indexOf('reserved') !== -1 && <><Col xs={13} md={6}><Text strong={true}>Reserved:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}>{item.reserved}</Col></>}
                            {props.selectedColumns.indexOf('total_qty') !== -1 && <><Col xs={13} md={6}><Text strong={true}>Total Qty:</Text></Col><Col xs={11} md={6} style={{ textAlign: 'center' }}><Number number={item.total_qty} /></Col></>}
                        </Row>

                    </Card>
                </Col>
            )}
        </Row>
    )
}

const defaultColumns = [
    {
        title: '',
        dataIndex: 'thumbnails',
        key: 'thumbnails',
        width: 100,
        render: (text, record, index) => <Avatar shape="square" size={75} src={IMG_BASE + record.thumbnail} />,
    },
    {
        title: 'Sku',
        dataIndex: 'sku',
        key: 'sku',
        width: 175,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 300
    },
    {
        title: 'Retail',
        dataIndex: 'retail',
        key: 'retail',
        width: 120,
        align: 'right',
        sorter: (a, b) => a.retail - b.retail,
    }
    ,
    {
        title: 'Stat',
        dataIndex: 'status',
        key: 'status',
        width: 75,
        align: 'center'
    },
    {
        title: 'Dept',
        dataIndex: 'dept',
        key: 'dept',
        width: 75,
        align: 'center'
    },
    {
        title: 'Tot Avail',
        dataIndex: 'total_avail',
        key: 'total_avail',
        width: 100,
        align: 'right',
        sorter: (a, b) => a.total_avail - b.total_avail,
        render: (avail, record, index) => (
            <>
                <Popover content={<SkuAvailTable data={record.serial_qtys} sku={record.sku} tableOnly={true} />} title="Serial Qtys" trigger="click">
                    <span><Number number={avail} /></span>
                </Popover>
            </>
        )
    },
    {
        title: 'PO Avail',
        dataIndex: 'po_avail',
        key: 'po_avail',
        width: 100,
        align: 'right',
        sorter: (a, b) => a.po_avail - b.po_avail,
        render: (avail, record, index) => (
            <>
                <Popover content={<POAvailTable data={record.purchase_orders} sku={record.sku} tableOnly={true} />} title="Purchase Orders" trigger="click">
                    <span><Number number={avail} /></span>
                </Popover>
            </>
        )
    },
    {
        title: 'WHS Avail',
        dataIndex: 'whs_avail',
        key: 'whs_avail',
        width: 100,
        align: 'right'
    },
    {
        title: 'FLR Avail',
        dataIndex: 'flr_avail',
        key: 'flr_avail',
        width: 100,
        align: 'right'
    },
    {
        title: 'Resvd',
        dataIndex: 'reserved',
        key: 'reserved',
        width: 75,
        align: 'right'
    },
    {
        title: 'Sku Serial',
        dataIndex: 'sku_serial',
        key: 'sku_serial',
        width: 100,
        align: 'center'
    },
    {
        title: '30',
        dataIndex: '30_day',
        key: '30_day',
        width: 75,
        align: 'right'
    },
    {
        title: '60',
        dataIndex: '60_day',
        key: '60_day',
        width: 75,
        align: 'right'
    },
    {
        title: '90',
        dataIndex: '90_day',
        key: '90_day',
        width: 75,
        align: 'right'
    },
    {
        title: '12mo',
        dataIndex: '12_month',
        key: '12_month',
        width: 75,
        align: 'right'
    },
    {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        width: 75,
        align: 'right'
    },
    {
        title: 'LCost',
        dataIndex: 'lcost',
        key: 'lcost',
        width: 75,
        align: 'right'
    },
    {
        title: 'GM',
        dataIndex: 'gross_margin',
        key: 'gross_margin',
        width: 75,
        align: 'right'
    },
    {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        width: 75,
        align: 'right'
    },
    {
        title: 'GMROI',
        dataIndex: 'gm_roi',
        key: 'gm_roi',
        width: 85,
        align: 'right'
    },
    {
        title: 'Turns',
        dataIndex: 'turns',
        key: 'turns',
        width: 75,
        align: 'right'
    },
    {
        title: 'Qty OnHand',
        dataIndex: 'qty_on_hand',
        key: 'total_qty',
        width: 85,
        align: 'right'
    },
    {
        title: 'AsIs Qty',
        dataIndex: 'asis_qty',
        key: 'asis_qty',
        width: 75,
        align: 'right'
    }
];

const testcolumns = ['sku', 'description', 'retail', 'status'];

const ListView = (props) => {
    const [tableData, setTableData] = useState(props.results)
    const [columns, setColumns] = useState(props.columns)
    //const [selectedColumns,setSelectedColumns] = useState([])

    useEffect(() => {
        console.log('listview useEffect', props.columns)
        let newData = [];
        for (let i = 0; i < props.results.length; i++) {
            let thisline = {
                key: props.results[i].sku,
                thumbnail: props.results[i].inventoryrec.thumbnails[0],
                sku: props.results[i].sku,
                description: props.results[i].inventoryrec.description.join("/n"),
                retail: props.results[i].inventoryrec.retail,
                status: props.results[i].inventoryrec.status.code,
                dept: props.results[i].inventoryrec.dept.code,
                po_avail: props.results[i].po_avail,
                total_avail: props.results[i].total_avail,
                total_qty: props.results[i].total_qty,
                sku_serial: props.results[i].skuserial,
                purchase_orders: props.results[i].purchase_orders,
                serial_qtys: props.results[i].serial_qtys,
            }
            newData.push(thisline);
        }
        setTableData(newData);
    }, []);

    useEffect(() => {
        setColumns(props.columns)
    }, [props.columns]);

    if (tableData.length > 0) {
        return (

            <Table
                columns={props.columns}
                dataSource={tableData}
                scroll={{ x: 1500, y: 600 }}
            //expandable={{expandedRowRender}}
            //footer={() => this.renderTableFooter(tabledata, csvHeaders)}
            />
        )
    } else {
        return (
            <div>list view</div>
        )
    }
}

const viewOptions = [
    { label: 'List', value: false },
    { label: 'Grid', value: true }
];

//class MFGL extends React.Component {
const MFGL = (props) => {
    const [searchkey, setSearchKey] = useState(null)
    const [modalVisible, setModalVisible] = useState(false)
    const [gridView, setGridView] = useState(false)
    const [columns, setColumns] = useState(defaultColumns)
    const [selectedColumns, setSelectedColumns] = useState([])

    const actionColumn = {
        title: '',
        key: 'action',
        fixed: 'right',
        align: 'center',
        width: 50,
        render: (_, record, index) => <div>
            <ShoppingCartOutlined key="add" onClick={() => props.addCart(record, 1)} />
            {window.location.pathname.includes('manufacturer_lookup') &&
                <>
                    <br />
                    <NavLink
                        to={{
                            pathname: "/find/reservations",
                            skunumber: record.sku,
                            skuindex: index
                        }}
                    >
                        <MenuOutlined key="edit" />
                    </NavLink>
                </>
            }
        </div>,
    };

    useEffect(() => {
        let viewFlag = localStorage.getItem('gridview');
        if (viewFlag === 'true') {
            setGridView(true);
        }
        let newlySelected = [];
        if (props.mfglColumns !== undefined && props.mfglColumns.length > 0) {
            let newColumns = defaultColumns.filter(column => (props.mfglColumns.indexOf(column.key) !== -1 || column.hasOwnProperty('fixed')));
            newColumns.push(actionColumn);
            setColumns(newColumns);
            newlySelected = props.mfglColumns;
        } else {
            newlySelected = defaultColumns.map(column => column.key);
        }

        setSelectedColumns(newlySelected);

        if (props.results.length === 0) {
            setModalVisible(true);
        }
    }, []);

    const onFinish = values => {
        props.searchProduct(values);
        setModalVisible(false);
    };

    const onRefresh = () => {
        if (props.last_updated !== null) {
            const values = {
                statusCodes: props.statusCodes,
                includeGroups: props.includeGroups,
                inStockOnly: props.inStockOnly,
                searchkey: props.searchkey,
            };
            props.searchProduct(values);
        }
    };

    const hideModal = () => {
        setModalVisible(false);
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleModalOk = () => {

    };

    const handleColumnChange = (value) => {
        console.log('column change', value);
        let newColumns = defaultColumns.filter(column => value.indexOf(column.key) !== -1);
        setColumns(newColumns);
        setSelectedColumns(value);
        props.saveMfglColumns(value);
    };

    const onViewChange = (e) => {
        const viewFlag = !gridView;
        setGridView(viewFlag);
        localStorage.setItem('gridview', viewFlag);
    }

    if (props.fetching) {
        return (
            <div>
                <Skeleton />
            </div>
        )
    } else if (props.error !== null) {
        return (
            <div>
                <Search placeholder="input search text" onSearch={value => props.searchProduct(value)} enterButton />
                <br />
                <Result
                    status="warning"
                    title={props.error + '. Please try again.'}

                />
            </div>
        );
    } else {
        const columnList = [];
        for (let i = 1; i < defaultColumns.length; i++) {
            columnList.push(<Option key={defaultColumns[i].key + i} value={defaultColumns[i].key}>{defaultColumns[i].title}</Option>)
        }

        return (
            <div style={contentDiv}>
                {/*
                    <Modal
                        title={modalTitle}
                        visible={modalVisible}
                        confirmLoading={confirmLoading}
                        onCancel={handleModalCancel}
                        width="90%"
                        footer={null}
                    >
                        <MFGLForm onFinish={onFinish} />
                    </Modal>
                    */}
                <MFGLForm
                    visible={modalVisible}
                    onCreate={onFinish}
                    onCancel={hideModal}
                />
                <Row type="flex">
                    <Col xs={24}>

                        <Descriptions
                            title={
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={8}>
                                        <FavoritesIcon name="Manufacturer Lookup" path="/find/manufacturer_lookup" /> Manufacturer Lookup <EditOutlined onClick={() => setModalVisible(true)} />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Radio.Group
                                            options={viewOptions}
                                            onChange={() => { onViewChange() }}
                                            value={gridView}
                                            optionType="button"
                                        />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Select
                                            style={{ width: '100%' }}
                                            mode="tags"
                                            placeholder="Columns Selected"
                                            defaultValue={selectedColumns}
                                            onChange={handleColumnChange}>
                                            {columnList}
                                        </Select>
                                    </Col>
                                </Row>
                            }
                            //bordered
                            column={{ xxl: 5, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Include Groups">
                                <div>{props.includeGroups}</div>

                            </Descriptions.Item>
                            <Descriptions.Item label="In Stock Only">{props.inStockOnly}</Descriptions.Item>
                            <Descriptions.Item label="Status Codes">{props.statusCodes.join(',')}</Descriptions.Item>
                            <Descriptions.Item label="Search">{props.searchkey}</Descriptions.Item>
                            <Descriptions.Item label="Last Update">
                                {props.last_updated !== null &&
                                    <div>
                                        {props.last_updated.toString()}
                                    &nbsp;&nbsp;<Button shape="circle" size="small" icon={<SyncOutlined />} onClick={() => onRefresh()} />
                                    </div>
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>

                    <Col xs={24}>
                        {gridView === true ?
                            <GridView results={props.results} columns={columns} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
                            :
                            <ListView results={props.results} columns={columns} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />

                            /*
                            <List
                                header={<div>Search Results ({props.results.length + ' items'})</div>}
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 1,
                                    md: 2,
                                    lg: 2,
                                    xl: 3,
                                    xxl: 4
                                }}
                                dataSource={props.results}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <Card

                                            hoverable
                                            cover={
                                                item.inventoryrec.thumbnails.length > 0 ?
                                                    <img alt="example" src={"https://www.furnguy.com/images/" + item.inventoryrec.thumbnails[0]} />
                                                    :
                                                    <img alt="example" src={"https://www.furnguy.com/images/noimage.gif"} />
                                            }
                                            actions={[
                                                <ShoppingCartOutlined key="add" onClick={() => props.addCart(item, 1)} />,
                                                <NavLink
                                                    to={{
                                                        pathname: "/find/reservations",
                                                        skunumber: item.sku,
                                                        skuindex: index
                                                    }}
                                                >
                                                    <MenuOutlined key="edit" />
                                                </NavLink>,
                                            ]}
                                        >
                                            {item.sku}
                                            <br />
                                            Retail ${item.inventoryrec.retail}
                                        </Card>
                                    </List.Item>
                                    
                                )}
                            />*/
                        }
                    </Col>
                </Row>
            </div>
        );

    }
}

const mapStateToProps = state => ({
    results: state.mfgl.results,
    error: state.mfgl.error,
    fetching: state.mfgl.fetching,
    adding: state.salesEntry.adding,
    statusCodes: state.mfgl.statusCodes,
    includeGroups: state.mfgl.includeGroups,
    inStockOnly: state.mfgl.inStockOnly,
    last_updated: state.mfgl.last_updated,
    searchkey: state.mfgl.searchkey,
    mfglColumns: state.setupState.mfglColumns,
});

const mapDispatchToProps = dispatch => {
    return {
        searchProduct: (searchkey) => {
            dispatch(searchProduct(searchkey));
        },
        addCart: (item, qty) => {
            dispatch(addCart(item, qty));
        },
        saveMfglColumns: (data) => {
            dispatch(saveMfglColumns(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MFGL);