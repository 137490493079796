import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { contentDiv } from './../../constants'
import StoreSelect from './../../components/StoreSelect'
//import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import Joyride from 'react-joyride';
import FavoritesIcon from './../../components/FavoritesIcon'
import { ConsoleSqlOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ExportToCSV from './../../components/ExportToCSV'
import Pivot from './../../components/webdatarocks.react'
// Import Graphs //
import SalesByHour from './../../components/dashboard/SalesByHour'
import SalesByDepartment from './../../components/dashboard/SalesByDepartment'
import SalesByVendor from './../../components/dashboard/SalesByVendor'
import DailySales from './../../components/dashboard/DailySales'
import BarGraph from './../../components/dashboard/BarGraph'
import PieGraph from './../../components/dashboard/PieGraph'
import FrogHeatMap from '../../components/maps/FrogHeatMap'
import TableFilterSearch from '../../components/TableFilterSearch'
import { reportTar } from './actions'
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import Highlighter from 'react-highlight-words'
import {
    Select,
    Row,
    Col,
    Form,
    Button,
    AutoComplete,
    DatePicker,
    Steps,
    Radio,
    Descriptions,
    Table,
    Typography,
    Card,
    Modal,
    Space,
    Input,
    Result
} from 'antd';
import {
    EditOutlined, SearchOutlined
} from '@ant-design/icons';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Step } = Steps;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


const helpSteps = [{
    target: '.edit-variables',
    disableBeacon: true,
    content: 'Click on the edit icon to change your initial report parameters.'
},
{
    target: '.save-favorites',
    disableBeacon: true,
    content: 'Click on the star icon to add to your favorites.',
    placement: 'bottom'
},
{
    target: '.report-variables',
    disableBeacon: true,
    content: 'Variables entered for last run.'
},
{
    target: '.ant-table-thead',
    disableBeacon: true,
    content: 'Column headers including their sort and filtering options. Changing filters will dynamically update the graphs below as well.'
},
{
    target: '.heat-map',
    disableBeacon: true,
    content: 'Displays where your purchases are located.'
},
{
    target: '.sales-by-day',
    disableBeacon: true,
    content: 'Displays sales by day.'
},
{
    target: '.sales-by-hour',
    disableBeacon: true,
    content: 'Displays sales by hour.'
},
{
    target: '.sales-by-dept',
    disableBeacon: true,
    content: 'Displays sales by department.'
},
{
    target: '.sales-by-vendor',
    disableBeacon: true,
    content: 'Displays sales by vendor.'
}];


const TarForm = (props) => {
    const [confirmDirty, setConfirmDirty] = useState(false);
    const [autoCompleteResult, setAutoComplete] = useState([]);
    const [mode, setMode] = useState(['month', 'month']);
    const [monthvalue, setMonthValue] = useState([]);
    const [typeflag, setTypeFlag] = useState('w');
    const [stores, setStores] = useState([]);
    const [editMode, setEditMode] = useState(true);
    const [daterange, setDateRange] = useState([]);

    const handleDateChange = value => {
        setDateRange(value);
    };

    const { results, fetching, fetched } = props;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
            md: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const initialState = {
        confirmDirty: false,
        autoCompleteResult: [],
        mode: ['month', 'month'],
        monthvalue: [],
        typeflag: 'w',
        stores: [],
        editMode: true,
        daterange: []
    };

    return (

        <Form {...formItemLayout}
            onFinish={props.onFinish}
            initialValues={initialState}
        >
            {/*
            <StoreSelect
                label="Select Store(s)"
                name="stores"
                multiple
                includeall
                rules={[{ required: true, message: 'Please select stores!', type: 'array' }]}
            />
            */}
            <Form.Item hidden={true} name="stores">
                <Input value="ALL" />
            </Form.Item>

            <Form.Item label="Report Range" name="daterange" rules={[{ type: 'array', required: true, message: 'Start and End values required!' }]}>
                <RangePicker
                    //placeholder={['Start month', 'End month']}
                    //defaultValue={daterange}
                    format="MMDDYY"
                    onChange={handleDateChange}
                //onPanelChange={handlePanelChange}
                />
            </Form.Item>

            <Form.Item label="Type" name="type">
                <Radio.Group >
                    <Radio.Button value="written">Written</Radio.Button>
                    <Radio.Button value="delivered">Delivered</Radio.Button>
                </Radio.Group>
            </Form.Item>



            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                    Continue
                </Button>
            </Form.Item>
        </Form>
    );


}


const skutablecolumns = [
    {
        title: 'Sku',
        dataIndex: 'sku',
        key: 'sku'
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty'
    },
    {
        title: 'Dept',
        dataIndex: 'dept',
        key: 'dept'
    },
    {
        title: 'Retail',
        dataIndex: 'retail',
        key: 'retail'
    },
];

const Tar = (props) => {
    const [modalTitle, setModalTitle] = useState('Enter Variables');
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [filterData, setFilterResults] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [graphData, setGraphData] = useState([]);
    const [geoData, setGeoData] = useState([]);
    const [storeFilter, setStoreFilter] = useState([]);
    const [marketFilter, setMarketFilter] = useState([]);
    const [deptFilter, setDeptFilter] = useState([]);
    const [mopFilter, setMopFilter] = useState([]);
    const [reportType, setReportType] = useState('written');
    const [retailFilter, setRetailFilter] = useState('less');
    const [collectionFilter, setCollectionFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [runHelp, setRunHelp] = useState(false);

    useEffect(() => {
        if (props.results.length === 0) {
            //setModalVisible(true);
            let idate = dayjs(new Date());
            let values = {
                daterange: [dayjs(), dayjs()],
                stores: ['ALL'],
                type: "written"
            };
            props.dispatch(reportTar(values))
        } else {
            updateChart(props.results);
        }
        let storeRecord = JSON.parse(localStorage.getItem('sales_record'));
        let sFilt = [];
        if (storeRecord !== null) {
            storeRecord.map((val) => {
                sFilt.push({ "text": val, "value": val });
            });
            setStoreFilter(sFilt);
        }
    }, []);
    useEffect(() => {
        setFilterResults(props.results);
        updateChart(props.results);
        setCsvData(props.results);
        let mFilt = [];
        let dFilt = [];
        let mopFilt = [];
        let collFilt = [];
        let catFilt = [];
        props.results.map((rec) => {
            if (mFilt.filter(m => m.value === rec.market).length === 0) {
                mFilt.push({ "text": rec.market, "value": rec.market });
            }
            if (dFilt.filter(d => d.value === rec.dept).length === 0) {
                dFilt.push({ "text": rec.dept, "value": rec.dept });
            }
            if (mopFilt.filter(m => m.value === rec.mop).length === 0) {
                mopFilt.push({ "text": rec.mop, "value": rec.mop });
            }
            if (collFilt.filter(c => c.value === rec.collection).length === 0) {
                collFilt.push({ "text": rec.collection, "value": rec.collection });
            }
            if (catFilt.filter(c => c.value === rec.category).length === 0) {
                catFilt.push({ "text": rec.category, "value": rec.category });
            }
        });
        setMarketFilter(mFilt);
        setDeptFilter(dFilt);
        setMopFilter(mopFilt);
        setCollectionFilter(collFilt);
        setCategoryFilter(catFilt);
    }, [props.results]);


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        let value = selectedKeys[0];
        let filterResults = props.results.filter(record =>
            record[dataIndex] !== undefined ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '');
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setCsvData(props.results);

        updateChart(filterResults);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
        updateChart(props.results);
        setCsvData(props.results);
        setRetailFilter('less');
    };

    const onFinish = values => {
        //console.log(values);
        props.dispatch(reportTar(values));
        //.then(() => {
        //if (props.results.length > 0) {
        setModalVisible(false);
        setConfirmLoading(false);
        setReportType(values.type);
    };


    const showModal = () => {
        setModalVisible(true);
    };

    const handleModalOk = () => {
        setModalTitle('The modal will be closed after two seconds');
        setConfirmLoading(true);


    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const updateChart = (rawData) => {
        let graphdata = [];
        let geodata = [];
        for (let i = 0; i < rawData.length; i++) {
            let qty = rawData[i].qty;
            let retail = rawData[i].retail;
            let time = rawData[i].time;
            let saledate = rawData[i].saledate;
            if (time !== '' && parseFloat(retail) !== 0) {
                let graphobj = {
                    "saledate": saledate,
                    "time": time,
                    "qty": qty,
                    "retail": retail,
                    "vendor": rawData[i].vendor,
                    "dept": rawData[i].dept,
                    //"geo_location":rawData[i].geo_location
                }
                graphdata.push(graphobj);

                if (rawData[i].geo_location !== undefined && rawData[i].geo_location.lat !== '' && rawData[i].geo_location.lng !== '') {
                    let geoobj = {
                        "lat": parseFloat(rawData[i].geo_location.lat),
                        "lng": parseFloat(rawData[i].geo_location.lng)
                    }
                    geodata.push(geoobj);
                }
            }
        }

        setGraphData(graphdata);
        setGeoData(geodata);
    }

    const handleTableChange = (pagination, filters, sorter) => {

        let newResults = props.results;
        if (filters.storecode !== null) {
            newResults = newResults.filter(record => filters.storecode.includes(record.storecode));
        }
        if (filters.dept !== null) {
            newResults = newResults.filter(record => filters.dept.includes(record.dept));
        }
        if (filters.market !== null) {
            newResults = newResults.filter(record => filters.market.includes(record.market));
        }
        if (filters.mop !== null) {
            newResults = newResults.filter(record => filters.mop.includes(record.mop));
        }
        if (filters.collection !== null) {
            newResults = newResults.filter(record => filters.collection.includes(record.collection));
        }
        if (filters.category !== null) {
            newResults = newResults.filter(record => filters.category.includes(record.category));
        }
        if (filters.sku !== null) {
            newResults = newResults.filter(record => record.sku.indexOf(filters.sku[0].toString().toUpperCase()) !== -1);
        }
        if (filters.retail !== null) {
            switch (retailFilter) {
                case 'less':
                    newResults = newResults.filter(record => parseFloat(record.retail) <= parseFloat(filters.retail[0]));
                    break;
                case 'greater':
                    newResults = newResults.filter(record => parseFloat(record.retail) >= parseFloat(filters.retail[0]));
                    break;
                default:
                    newResults = newResults.filter(record => parseFloat(record.retail) == parseFloat(filters.retail[0]));
            }

        }

        updateChart(newResults);
        setCsvData(newResults);
    };

    const getColumnSearchOnly = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',

        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleRetailFilterChange = (value) => {
        setRetailFilter(value);
    };

    const selectBefore = (
        <Select defaultValue="less" value={retailFilter} onChange={handleRetailFilterChange} style={{ width: 80 }}>
            {/*<Option value="na"></Option>*/}
            <Option value="less">{`<=`}</Option>
            <Option value="greater">{`>=`}</Option>
            <Option value="equal">{`=`}</Option>
        </Select>
    );

    const getRetailSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>

                <Input
                    addonBefore={selectBefore}
                    // ref={node => {
                    //     this.searchInput = node;
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, width: 188, display: 'block' }}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

            if (retailFilter === 'less') {
                return (record[dataIndex] ? parseFloat(record[dataIndex]) <= parseFloat(value) : '')
            } else if (retailFilter === 'greater') {
                return (record[dataIndex] ? parseFloat(record[dataIndex]) >= parseFloat(value) : '')
            } else {
                return (record[dataIndex] ? parseFloat(record[dataIndex]) == parseFloat(value) : '')
            }
        }

    });

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //     this.searchInput = node;
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />


                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            //if (visible) {
            //    setTimeout(() => this.searchInput.select(), 100);
            //}
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const filterSelect = (record, column, value) => {

        let newresult = record;
        return record;
    };

    const { results, fetching, fetched } = props;


    const columns = [
        {
            title: 'Invoice',
            width: 10,
            dataIndex: 'invoice',
            key: 'invoice',
            fixed: 'left',
        },
        {
            title: 'Store',
            dataIndex: 'storecode',
            key: 'storecode',
            width: 10,
            filters: storeFilter,
            onFilter: (value, record) => record.storecode === value, //filterSelect(record,'storecode',value), //
            //...getColumnSearchProps('customernumber'),
        },
        {
            title: 'Customer #',
            dataIndex: 'customernumber',
            key: 'customernumber',
            width: 15,
            //...getColumnSearchProps('customernumber'),
        },
        {
            title: 'Sale Date',
            dataIndex: 'saledate',
            key: 'saledate',
            width: 10,
            sorter: (a, b) => {
                if (dayjs(a.saledate).isBefore(dayjs(b.saledate))) {
                    return -1;
                } else if (dayjs(a.saledate).isAfter(dayjs(b.saledate))) {
                    return 1;
                } else {
                    return 0;
                }
            },
            //...getColumnSearchProps('saledate'),
        },
        {
            title: 'Sale Time',
            dataIndex: 'time',
            key: 'time',
            width: 10,
        },
        {
            title: 'Del Date',
            dataIndex: 'deldate',
            key: 'deldate',
            width: 10,
            sorter: (a, b) => {
                if (dayjs(a.saledate).isBefore(dayjs(b.saledate))) {
                    return -1;
                } else if (dayjs(a.saledate).isAfter(dayjs(b.saledate))) {
                    return 1;
                } else {
                    return 0;
                }
            },
        },
        {
            title: 'SLP',
            width: 10,
            dataIndex: 'slm1',
            key: 'slm1',
            align: 'center',
            sorter: (a, b) => a.qty - b.qty,
            sortDirections: ['descend'],
            //...getColumnSearchProps('slm1'),
        },
        {
            title: 'Sku',
            dataIndex: 'sku',
            key: 'sku',
            width: 20,
            ...getColumnSearchOnly('sku'),
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            width: 10,
            align: 'right',
            sorter: (a, b) => a.qty - b.qty,
            //sortDirections: ['descend'],
        },
        {
            title: 'Retail',
            dataIndex: 'retail',
            key: 'retail',
            width: 10,
            align: 'right',
            sorter: (a, b) => a.retail - b.retail,
            // sortDirections: ['descend'],
            ...getRetailSearchProps('retail'),
        },
        {
            title: 'MOP',
            dataIndex: 'mop',
            key: 'mop',
            width: 10,
            filters: mopFilter,
            onFilter: (value, record) => record.mop === value,
        },
        {
            title: 'Market',
            dataIndex: 'market',
            key: 'market',
            width: 10,
            filters: marketFilter,
            onFilter: (value, record) => record.market === value,
        },
        {
            title: 'Dept',
            dataIndex: 'dept',
            key: 'dept',
            width: 10,
            filters: deptFilter,
            onFilter: (value, record) => record.dept === value,
        },
        {
            title: 'Collection',
            dataIndex: 'collection',
            key: 'collection',
            width: 15,
            filters: collectionFilter,
            ...getColumnSearchOnly('collection'),
            //onFilter: (value, record) => record.collection === value,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 15,
            filters: categoryFilter,
            onFilter: (value, record) => record.category === value,
        }
    ];


    const expandedRowRender = (invoicedata) => {

        const data = [];
        for (let i = 0; i < invoicedata.skudata.length; ++i) {
            let thisline = Object.assign({}, invoicedata.skudata[i]);
            thisline.key = i;
            data.push(thisline);
        }
        return <Table columns={skutablecolumns} dataSource={data} pagination={false} />;
    };


    const tabledata = [];
    const renderTableFooter = (csvData, csvHeaders) => {
        if (csvData.length > 0) {
            return (<ExportToCSV csvData={csvData} fileName="tar.csv" headers={csvHeaders} />);
        } else if (props.fetching) {
            //return (<CSVLink data={tabledata} headers={csvHeaders} filename='rpbb.csv'>Export</CSVLink>);
            return (<Button type="primary" loading>Loading</Button>);
        } else {
            return (<div />);
        }
    };

    const helpCallback = (cbprops) => {
        if (cbprops.action === 'reset') { setRunHelp(false) }
    };


    const csvHeaders = [];

    for (let i = 0; i < columns.length; i++) {
        let hobj = {
            label: columns[i].title,
            key: columns[i].key
        };
        csvHeaders.push(hobj);
    }
    for (let i = 0; i < skutablecolumns.length; i++) {
        let hobj = {
            label: skutablecolumns[i].title,
            key: skutablecolumns[i].key
        };
        csvHeaders.push(hobj);
    }


    return (
        <div style={contentDiv}>
            <Joyride
                callback={helpCallback}
                continuous={true}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                run={runHelp}
                steps={helpSteps}
                styles={{
                    options: {
                        zIndex: 1000,
                    },
                }}
            />
            <Modal
                title={modalTitle}
                visible={modalVisible}
                // onOk={handleModalOk}
                confirmLoading={confirmLoading}
                onCancel={handleModalCancel}
                width="90%"
                footer={null}
            >
                <TarForm onFinish={onFinish} />
            </Modal>
            <Row type="flex">
                <Col xs={24}>

                    <Descriptions
                        title={<div><FavoritesIcon name="Transaction Report" /> Transaction Report <EditOutlined onClick={() => setModalVisible(true)} className="edit-variables" /><div style={{ float: 'right' }}><QuestionCircleOutlined onClick={() => setRunHelp(true)} style={{ fontSize: '20px' }} /></div></div>}
                        //bordered
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        className="report-variables"
                    >

                        <Descriptions.Item label="Report Range">
                            {props.daterange.length == 2 &&
                                <div>{props.daterange[0].format('MM-DD-YY') + ' - ' + props.daterange[1].format('MM-DD-YY')}</div>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">{props.type.toString()}</Descriptions.Item>
                        <Descriptions.Item label="Stores">{props.stores.join(',')}</Descriptions.Item>

                    </Descriptions>
                </Col>
                {props !== undefined && props.results !== undefined && props.results.length === 0 ?

                    <Col xs={24}>
                        <Result title="No sales found for your date range." />
                    </Col>

                    :
                    <Col xs={24}>
                        <Row>

                            <Col xs={24}>

                                {results.length > 0 &&
                                    <Table
                                        columns={columns}
                                        dataSource={results}
                                        loading={props.fetching}
                                        scroll={{ x: 2000, y: 1000 }}
                                        onChange={handleTableChange}
                                        //expandable={{expandedRowRender}}
                                        footer={() => renderTableFooter(csvData, csvHeaders)}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row type="flex">
                            <Col xs={24} xl={12} >
                                <Card bordered={true} title="Heat Map" bodyStyle={{ padding: 0 }} className="heat-map">
                                    {geoData.length > 0 &&
                                        <FrogHeatMap center={geoData[0]} zoom={8} positions={geoData} />
                                    }
                                </Card>
                            </Col>
                        </Row>
                        <Row type="flex">
                            <Col xs={24} xl={12}>
                                <Card bordered={true} title="Sales by Day" className="sales-by-day">
                                    <DailySales data={graphData} />
                                </Card>
                            </Col>
                            <Col xs={24} xl={12}>
                                <Card bordered={true} title="Sales by Hour" className="sales-by-hour">
                                    <SalesByHour data={graphData} />
                                    {/*<BarGraph data={graphData} />*/}
                                </Card>
                            </Col>
                            <Col xs={24} xl={12}>
                                <Card bordered={true} title="Sales by Dept" className="sales-by-dept">
                                    <SalesByDepartment data={graphData} />
                                </Card>
                            </Col>
                            <Col xs={24} xl={12}>
                                <Card bordered={true} title="Sales by Vendor" className="sales-by-vendor">
                                    <SalesByVendor data={graphData} />
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                }
            </Row>
        </div>
    );


}

const mapStateToProps = store => ({
    results: store.tar.results,
    fetching: store.tar.fetching,
    fetched: store.tar.fetched,
    stores: store.tar.stores,
    daterange: store.tar.daterange,
    type: store.tar.type
});

const mapDispatchToProps = dispatch => {
    return {
        reportTar: (data) => {
            dispatch(reportTar(data));
        },
    }
};

export default connect(mapStateToProps)(Tar)