const tar = (state = {
    stores: [],
    daterange: [],
    type: '',
    brand_results: [],
    results: [],
    last_updated: null,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    //console.log(action.type,action.payload);
    switch (action.type) {
        case 'FETCH_TAR':
            return { 
                ...state, 
                fetching: true, 
                stores: action.payload.stores, 
                daterange: action.payload.daterange, 
                type: action.payload.type 
            }
        case 'FETCH_TAR_FAIL':
            return { ...state, fetching: false, error: action.payload }
        case 'FETCH_TAR_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                //last_updated: new Date(),
                results: action.payload
            }
       

        default:
            return state
    }
}

export default tar