import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';

const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    "rgba(255, 0, 0, 1)"
];

const apiKey = "AIzaSyCl5I-rV5KtcTlAIWSWwSCu2Kp_1_f3ypw";

class FrogHeatMap extends React.Component {
    render() {
        let heatMapData = {
            "gradient": gradient,
            "positions": this.props.positions,
            "options": {
                "radius": 20,
                "opacity": 1
            }
        }
        //console.log('heat props',this.props);
        return (
            <div style={{ height: 600 }}>
                <GoogleMapReact
                    ref={(el) => this._googleMap = el}
                    bootstrapURLKeys={{ "key": apiKey }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    heatmapLibrary={true}
                    heatmap={heatMapData}
                    //onClick={this.onMapClick.bind(this)}
                >

                </GoogleMapReact>
            </div>
        );
    }
}

export default FrogHeatMap;

/*
class FrogHeatMap extends React.Component {

    render() {
        console.log('Heat Map',this.props.positions);
        return (
            <div className="map-container" style={{ height: 400 }}>
                <Map
                    google={this.props.google}
                    className={"map"}
                    zoom={this.props.zoom}
                    initialCenter={this.props.center}
                    onReady={this.handleMapReady}
                    containerStyle={{ height: 400 }}
                >
                    <HeatMap
                        gradient={gradient}
                        positions={this.props.positions}
                        opacity={1}
                        radius={20}
                    />
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCl5I-rV5KtcTlAIWSWwSCu2Kp_1_f3ypw",
    libraries: ["visualization"]
})(FrogHeatMap);

*/