import React, { useEffect, useState } from 'react'
import { BASE_URL, FROG_AUTH, Base64 } from './../../constants'
import useSWR from 'swr';
import { connect } from 'react-redux'
import Joyride from 'react-joyride';
import { ResponsivePie, ResponsivePieCanvas } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import {
    Result,
    Tabs,
    Row,
    Col,
    Typography,
    Card,
    Skeleton,
    Divider,
    Radio,
    Form,
    Button
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import FrogHeatMap from '../maps/FrogHeatMap'
import { Pie } from '@ant-design/charts';
import StoreSelect from '../StoreSelect';
import TodayAllStores from './TodayAllStores';
import numberWithCommas from '../../hooks/numberWithCommas';

const { TabPane } = Tabs;
const { Title } = Typography;

const viewOptions = [
    { label: 'Today', value: 'today' },
    { label: 'This Week', value: 'week' },
    { label: 'This Month', value: 'month' },
    { label: 'Last 30 Days', value: '30days' },
];

const formItemLayout = {
    /*labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
        md: { span: 8 },
    },
    */
    wrapperCol: {
        xs: { span: 12 },
    },
};

const helpSteps = [{
    target: '.dashboard-options',
    disableBeacon: true,
    content: 'Click on the range you want view and/or select a specific store.'
},
{
    target: '.current-sold',
    disableBeacon: true,
    content: 'This represents total retail for today.',
    placement: 'top'
},
{
    target: '.gross-profit',
    disableBeacon: true,
    content: 'This represents total gross profit of line item retail less line item cost.'
},
{
    target: '.total-revenue',
    disableBeacon: true,
    content: 'Includes line item retails, delivery charges, and tax.'
},
{
    target: '.gross-margin',
    disableBeacon: true,
    content: 'Includes line item retails, delivery charges, and tax.'
},
{
    target: '.receivables',
    disableBeacon: true,
    content: 'Includes line item retails, delivery charges, and tax.'
}
,
{
    target: '.retail-by-store',
    disableBeacon: true,
    content: 'Breaks out total retail by store.'
},
{
    target: '.gm-by-store',
    disableBeacon: true,
    content: 'Breaks out Gross Margin by store.'
},
{
    target: '.deposit-by-store',
    disableBeacon: true,
    content: 'Breaks sales deposits by store.',
    placement: 'top'
},
{
    target: '.profit-by-store',
    disableBeacon: true,
    content: 'Breaks out profit by store. Line item retails less item costs.',
    placement: 'top'
}];

const TodaysDetails = props => {
    //const [action] = useState(props);
    const [salesData, setData] = useState(null);
    const [storeData, setStoreData] = useState(null);
    const [viewOption, setView] = useState('today');
    const [storeOption, setStore] = useState('ALL');
    //const [allStoreOption, setAllStoreOption] = useState(true);
    const [rangeLabel, setRangeLabel] = useState('today');
    const [storeLabel, setStoreLabel] = useState('your stores');
    //const [helpSteps, setHelpSteps] = useState(helpSteps);
    const [runHelp, setRunHelp] = useState(false);
    const [form] = Form.useForm();
    const [geoflag, setGeoFlag] = useState(false);
    let setupobj = JSON.parse(localStorage.getItem('setup'));
    let baseurl = setupobj.url;
    let frogauth = setupobj.username + ':' + setupobj.password;
    //const dashboardAPI = baseurl + '/dashboard?type=todaysales';
    //const dashboardAPI = BASE_URL + 'dashboard?type=todaysales';
    const fetchData = async (range, store) => {
        //console.log('start employee fetch');
        let dashboardAPI = baseurl + '/dashboard?type=todaysales&range=' + range; // + '&store=' + store;
        //return await fetch(dashboardAPI, {
        fetch(dashboardAPI, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Basic " + Base64.btoa(frogauth)
            }),
            // body: formData
        }).then(
            response => {
                // if the response is a JSON object
                return response.json();
            })
            .then(
                response => {
                    let tempdata = null;
                    let storekey = 'totals';
                    if (store !== 'ALL') { storekey = store; }
                    tempdata = response.retailbystore.filter((sobj) => { return sobj.store === storekey });
                    console.log(storekey, tempdata);
                    setStoreData(tempdata);
                    setData(response);
                })
            .catch(error => {
                //throw(error);
                console.log('dashboard catch', error);
                ////console.log(error);
                //dispatch(fetchTruckFailed(error));
                //dispatch({ type: 'FETCH_CALLS_FAIL', payload: error });
            })
    }
    //const { data: salesData } = useSWR(dashboardAPI, fetchData);


    useEffect(() => {
        let initialStore = 'ALL';
        const storelist = JSON.parse(localStorage.getItem('sales_record'));
        if (storelist.length === 1) {
            setStore(storelist[0]);
            initialStore = storelist[0];
        }
        fetchData('today', initialStore);
        
        //setRunHelp(true);
    }, [])
    /*
   onPieClick = (params) => {
    console.log('onPieClick',params);
};*/



    const changeView = (event) => {
        //  console.log(event.target.value);
        // setView(event.target.value);
    }

    const onValuesChange = (values) => {
        let objkey = Object.keys(values)[0]
        let objval = values[objkey];
        console.log(objkey, objval);
        let range = viewOption;
        let store = storeOption;
        if (objkey === 'stores') {
            store = objval;
            setStore(objval);
            if (objval === 'ALL') {
                objval = 'totals';
                setStoreLabel('your stores');
            } else {
                setStoreLabel('store ' + objval);
            }
            let newData = salesData.retailbystore.filter((sobj) => { return sobj.store === objval });
            setStoreData(newData);

        } else {
            range = objval
            setView(objval);
            switch (objval) {
                case 'week':
                    setRangeLabel('this week');
                    break;
                case 'month':
                    setRangeLabel('this month');
                    break;
                case '30days':
                    setRangeLabel('in the last 30 days');
                    break;
                default:
                    setRangeLabel('today');
            }
            setData(null);
            fetchData(range, store);
        }

    }

    const calculateGM = (retail, cost) => {
        let gm = 0;
        if (parseFloat(retail) != 0) {
            gm = ((parseFloat(retail) - parseFloat(cost)) / parseFloat(retail)) * 100;
        }
        return gm.toFixed(2);
    }

    const helpCallback = (cbprops) => {
        console.log(cbprops);
        if (cbprops.action === 'reset') {setRunHelp(false)}
    };
    
    let pieData = [];
    if (salesData !== undefined && salesData !== null) {

        for (let i = 0; i < salesData.length; i++) {
            let dollars = salesData[i].sales_dollars;
            if (dollars === '') { dollars = 0; }
            dollars = parseFloat(dollars);
            if (dollars > 0) {
                let store = {
                    "id": salesData[i].store,
                    "value": dollars,
                    "geoData": salesData[i].sale_geo_codes.map(({ lat, lng }) => ({ lat: parseFloat(lat), lng: parseFloat(lng) }))//salesData[i].sale_geo_codes
                };
                pieData.push(store);
            }
        }
    }
    //console.log(pieData);

    /*
    if (geoflag) {
        return (
            <div style={{ height: 400 }}>
                <FrogHeatMap center={pieData[0].geoData[0]} zoom={11} positions={pieData[0].geoData} />
            </div>
        )
    }
    */


    //console.log('salesdata', salesData.retailbyhour.allstores);

    if (salesData !== undefined && salesData !== null && salesData.totalretail == "0") {
        return (
            <>
                <Result title="No sales today." />
            </>
        )
    }
    let grossprofit = 0;
    let totalgm = 0;
    let storeSalesData = [];
    if (salesData !== undefined && salesData !== null && storeData !== null) {
        //grossprofit = parseFloat(salesData.totalretail.replace(/[^\d\.\-]/g, "")) - parseFloat(salesData.totalcost.replace(/[^\d\.\-]/g, ""));
        grossprofit = parseFloat(storeData[0].retail) - parseFloat(storeData[0].cost);
        //totalgm = calculateGM(storeData[0].retail, storeData[0].cost);
        if (storeOption == 'ALL') {
            storeSalesData = salesData.retailbystore.filter((sobj) => { return sobj.store !== 'totals' });
            console.log(storeData, storeSalesData);
        }
        console.log('gm', storeData);
        totalgm = storeData[0].margin;
    }
    /*
    console.log('show help', runHelp);
    console.log('storedata', storeData);
    console.log('salesdata', storeSalesData );
    */
    return (
        <div>
            <Joyride
                callback={helpCallback}
                continuous={true}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                run={runHelp}
                steps={helpSteps}
                styles={{
                    options: {
                        zIndex: 1000,
                    },
                }}
            />


            <Row gutter={8} wrap={true} >
                <Col xs={24} style={{ height: 600 }}>
                    <Card>

                        <Row gutter={[{ xs: 2, md: 4, xl: 8 }, { xs: 2, md: 4, xl: 8 }]} wrap={true}>
                            <Col xs={{span: 2, order: 2}} md={{span: 1, order: 3}} ><QuestionCircleOutlined onClick={() => setRunHelp(true)} style={{ fontSize: '20px' }} /></Col>
                            <Col xs={{span: 22, order: 1}} md={{span: 11, order: 1}} >
                                <Title level={3}>{`Hi ${props.employeeName}, here's what's happening in ${storeLabel}.`}</Title>
                                {salesData !== undefined && salesData !== null &&
                                    <Title level={3} className="current-sold">
                                        {storeOption === 'ALL' ?
                                            <div>{`Your stores have sold $${numberWithCommas(storeData[0].total)} ${rangeLabel}.`}</div>
                                            :
                                            <div>{`${storeOption} has sold $${numberWithCommas(storeData[0].total)} ${rangeLabel}.`}</div>
                                        }
                                    </Title>
                                }
                            </Col>
                            <Col xs={{span: 24, order: 3}} md={{span: 12, order: 2}} style={{ textAlign: 'right' }}>
                                <Form
                                    form={form}
                                    layout="inline"
                                    initialValues={{ viewSelect: "today", stores: storeOption }}
                                    onValuesChange={onValuesChange}
                                    className="dashboard-options"
                                >
                                    <Form.Item name="viewSelect">
                                        <Radio.Group
                                            options={viewOptions}
                                            //onChange={changeView}
                                            //value={viewOption}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>

                                    <StoreSelect
                                        //label="Select Store"
                                        name="stores"
                                        // multiple
                                        includeall
                                        //{...(allStoreOption && 'includeall')}
                                        //location
                                        // rules={[{ required: true, message: 'Please select stores!', type: 'array' }]}
                                    />

                                </Form>
                            </Col>
                            <Col xs={{span: 24, order: 4}} >
                                <Divider />
                            </Col>
                        </Row>
                        {salesData === undefined || salesData === null ?
                            <div >
                                <Skeleton active />
                            </div>
                            :
                            <div>
                                <Row gutter={[{ xs: 2, md: 4, xl: 8 }, { xs: 2, md: 4, xl: 8 }]} wrap={true}>
                                    <Col xs={24} md={12} xl={6} >
                                        <Card title="Gross Profit (Retail)" className='fullblock gross-profit'>
                                            <Title level={1} style={{ textAlign: "center" }}> ${numberWithCommas(grossprofit)}</Title>
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={12} xl={6} >
                                        <Card title="Total Revenue" className='fullblock total-revenue'>
                                            <Row gutter={0} wrap={true} justify="center">
                                                <Col xs={24} style={{ textAlign: "center" }}>
                                                    <Title level={2}>${numberWithCommas(storeData[0].total)}</Title>
                                                </Col>
                                                <Col xs={24} >
                                                    <Row gutter={0}>
                                                        <Col xs={12}>
                                                            <Title level={5}>Retail</Title>
                                                        </Col>
                                                        <Col xs={12} style={{ textAlign: "right" }}>
                                                            <Title level={5}>${numberWithCommas(storeData[0].retail)}</Title>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} >
                                                    <Row gutter={0}>
                                                        <Col xs={12}>
                                                            <Title level={5}>Delivery Charge</Title>
                                                        </Col>
                                                        <Col xs={12} style={{ textAlign: "right" }}>
                                                            <Title level={5}>${numberWithCommas(storeData[0].delcharge)}</Title>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} >
                                                    <Row gutter={0}>
                                                        <Col xs={12}>
                                                            <Title level={5}>Tax</Title>
                                                        </Col>
                                                        <Col xs={12} style={{ textAlign: "right" }}>
                                                            <Title level={5}>${numberWithCommas(storeData[0].tax)}</Title>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={12} xl={6} >
                                        <Card title="Gross Margin" className='fullblock gross-margin'>
                                            <Title level={1} style={{ textAlign: "center" }}>{totalgm}</Title>
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={12} xl={6} >
                                        <Card title="Receivables" className='fullblock receivables'>
                                            <Title level={3} style={{ textAlign: "center" }}>Deposit ${numberWithCommas(storeData[0].deposit)}</Title>
                                        </Card>
                                    </Col>
                                </Row>
                                <Divider />
                                {storeOption === 'ALL' ?
                                    <TodayAllStores storeData={storeSalesData} />
                                    :
                                    <></>
                                }
                            </div>
                        }
                    </Card>
                    {/*<Pie {...config} />
                
                <ResponsivePie
                    data={pieData}
                    margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
                    innerRadius={0.25}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{ scheme: 'red_yellow_green' }}
                    enableRadialLabels={false}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor={{ from: 'color' }}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#333333"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    onClick={(data) => onPieClick(data)}

                />
                */}
                </Col>
                <Col xs={24} lg={12} style={{ height: 600 }}>
                    <div style={{ height: 400 }}>

                        {/*<FrogHeatMap center={pieData[0].geoData[0]} zoom={11} positions={pieData[0].geoData} />*/}
                    </div>
                </Col>
            </Row >
        </div>
    )
    /*
    return (
        <Tabs defaultActiveKey="1" >
            <TabPane tab="Graph" key="1">
                <div style={{ height: 400 }}>
                    <ResponsivePie
                        data={pieData}
                        margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
                        innerRadius={0.25}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{ scheme: 'red_yellow_green' }}
                        enableRadialLabels={false}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={0}
                        radialLabelsLinkDiagonalLength={16}
                        radialLabelsLinkHorizontalLength={24}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{ from: 'color' }}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor="#333333"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        onClick={(data) => onPieClick(data)}

                    />


                </div>
            </TabPane>
            <TabPane tab="Heat Map" key="2">
                <div style={{ height: 400 }}>
                    <FrogHeatMap center={pieData[0].geoData[0]} zoom={11} positions={pieData[0].geoData} />
                </div>
            </TabPane>
        </Tabs>

    )
    */
}


//export default TodaysDetails;

const mapStateToProps = store => ({
    employeeName: store.setupState.employeeName,
});

export default connect(mapStateToProps)(TodaysDetails)
