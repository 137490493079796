import React from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import {
    StarOutlined,
    StarFilled,
} from '@ant-design/icons';
import { saveFavorites } from '../setup/employee/actions';


class FavoritesIcon extends React.Component {
    state = {

    };

    componentDidMount() {

    }

    onClick = () => {
        //console.log('good click ' + this.props.location);
        let newlist = [...this.props.employeeFavorites];
        if (newlist.filter(item => item.path === this.props.location.pathname).length > 0) {
            newlist = newlist.filter(item => item.path !== this.props.location.pathname);
        } else {
            let newfav = {
                name: this.props.name,
                path: this.props.location.pathname
            };
            newlist.push(newfav);
        }
        // dispatch save //
        this.props.saveFavorites(newlist);
    }

    render() {
        //console.log('favorites icon',this.props.location);
        return (
            <>
                {this.props.employeeFavorites.filter(item => item.path === this.props.location.pathname).length > 0 ?
                    <StarFilled style={{ color: '#52c41a' }} onClick={this.onClick} className="save-favorites"  />
                    :
                    <StarOutlined onClick={this.onClick} className="save-favorites"  />
                }

            </>
        )
    }
}

const mapStateToProps = store => ({
    employeeFavorites: store.setupState.employeeFavorites,
});

const mapDispatchToProps = dispatch => {
    return {
        saveFavorites: (data) => {
            dispatch(saveFavorites(data));
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoritesIcon))